import React from "react";
import PropTypes from "prop-types";

import { css } from "styled-components";
import { Route, Switch } from "react-router-dom";
import Markdown from "components/Editors/Markdown";

class Text extends React.Component {
  onChange = value => {
    const { setTranscriptValue } = this.props;

    setTranscriptValue({ transcriptValue: value });
  };

  render() {
    const { transcriptValue, match, readOnly } = this.props;

    const markdownProps = {
      ref: instance => {
        this.markdown = instance;
      },

      value: transcriptValue,
      placeholder: "Write something down...",
      onChange: this.onChange
    };

    return (
      <div>
        <Switch>
          <Route
            exact
            path={`${match.url}/edit`}
            render={() => (
              <Markdown
                {...markdownProps}
                readOnly={false}
                customstyles={css`
                  .ql-editor {
                    height: calc(100vh - 190px);
                    max-height: calc(100vh - 190px);
                  }
                `}
              />
            )}
          />
          <Route
            render={() => (
              <Markdown
                {...markdownProps}
                readOnly={readOnly}
                customstyles={css`
                  .ql-editor {
                    height: calc(100vh - 148px);
                    max-height: calc(100vh - 148px);
                  }
                `}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

Text.propTypes = {
  editMode: PropTypes.bool,
  setTranscriptValue: PropTypes.func,
  transcriptValue: PropTypes.string,
  match: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool
};

Text.defaultProps = {
  transcriptValue: "",
  editMode: false,
  match: {},
  setTranscriptValue: () => null,
  readOnly: true
};

export default Text;
