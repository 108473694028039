import React from "react";
import PropTypes from "prop-types";

import File from "../File";
import Directory from "../Directory";

class ModuleList extends React.PureComponent {
  render() {
    const {
      depth = 0,
      openedPath,
      selectFile,
      prefixedPath,
      files,
      createFile,
      deleteFile
    } = this.props;

    const filesToShow = [];
    const directoriesToShow = new Set();
    const pathParts = prefixedPath.split("/");

    Object.keys(files).forEach(path => {
      if (path.startsWith(prefixedPath)) {
        const filePathParts = path.split("/");

        if (filePathParts.length === pathParts.length) {
          if (path.endsWith("/")) {
            directoriesToShow.add(path);
          } else {
            filesToShow.push({ path });
          }
        } else if (filePathParts.length === pathParts.length + 1) {
          filePathParts.pop();
          directoriesToShow.add(`${filePathParts.join("/")}/`);
        }
      }
    });

    return (
      <div>
        {Array.from(directoriesToShow).map(dir => (
          <Directory
            key={dir}
            prefixedPath={dir}
            files={files}
            selectFile={selectFile}
            openedPath={openedPath}
            depth={depth + 1}
            createFile={createFile}
            deleteFile={deleteFile}
          />
        ))}

        {filesToShow.map(file => (
          <File
            depth={depth + 1}
            key={file.path}
            selectFile={selectFile}
            path={file.path}
            active={openedPath === file.path}
            deleteFile={deleteFile}
          />
        ))}
      </div>
    );
  }
}

ModuleList.propTypes = {
  depth: PropTypes.number,
  openedPath: PropTypes.string.isRequired,
  selectFile: PropTypes.func.isRequired,
  prefixedPath: PropTypes.string.isRequired,
  files: PropTypes.instanceOf(Object).isRequired,
  createFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired
};

ModuleList.defaultProps = {
  depth: 0
};

export default ModuleList;
