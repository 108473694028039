/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { getCareerConstant } from "utils/course";

import { ImageAsset } from "./Asset";
import Text from "./Text";
import PromotionalRow from "./PromotionalRow";

const htmlCssImg = require("assets/images/courses/HTMLCSS.png");
const nodeImg = require("assets/images/courses/node.png");
const pythonImg = require("assets/images/courses/Python.ico");
const jsImg = require("assets/images/courses/javascript.png");
const uxImg = require("assets/images/courses/uxui.png");
const reactImg = require("assets/images/courses/React.png");
const devopsImg = require("assets/images/courses/devops.png");
const numpyImg = require("assets/images/courses/numpy.png");
const scikitImg = require("assets/images/courses/scikit.svg");
const pytorchImg = require("assets/images/courses/pytorch.png");
const ciImg = require("assets/images/courses/ciAuto.png");
const dockerImg = require("assets/images/courses/docker.png");
const goImg = require("assets/images/courses/golang.svg");

export default {

  // SOFTWARE

  software: [
    <PromotionalRow
      careerKey="software"
      gradient={getCareerConstant("software").gradient}
    >
      <h2>Software Development Course</h2>
      <p>Learn how to design and build modern applications and systems using in-demand 
      tools and concepts used by the biggest companies around the world.
      </p>
    </PromotionalRow>,
    {
      reverse: false,
      mainSection: true,
      icon: "file-code",
      mainComponent: ({ isMobile }) => (
        <Text bounceLeft={!isMobile} bounceRight={isMobile}>
          <h2>Learn HTML &amp; CSS</h2>
          <p>
            Start with <NavLink to="user/dashboard/course/1/">HTML</NavLink> and{" "}
            <NavLink to="user/dashboard/course/10/">CSS</NavLink> to give you an
            appreciation for how websites work.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceRight src={htmlCssImg} />
    },
    {
      reverse: true,
      mainSection: true,
      icon: "object-group",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Learn UI/UX Design</h2>
          <p>
            Study design patterns to help users enjoy the experience out of your
            product that you expect for them.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={uxImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Build Your Portfolio</h4>
          <p>
            Your resume isn't enough. We will be helping you build a portfolio of projects 
            and exercises to show off what you've learned to interviewers and peers.
          </p>
        </Text>
      )
    },
    {
      reverse: false,
      mainSection: true,
      icon: "terminal",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Learn JavaScript</h2>
          <p>
            JavaScript will be one of the most important skills to master 
            one of the most versatile language for front-end and server-side development.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={jsImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Participate in Communities</h4>
          <p>
            We run some of the largest tech communities in the world, and we 
            support open-source initiatives like many of the top developers in the world.
            You will be prepared to give public talks or demos and contribute 
            to open-source projects to demonstrate your skills and share with others.
          </p>
        </Text>
      )
    },
    {
      reverse: true,
      mainSection: true,
      icon: "server",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Learn Node.js</h2>
          <p>
            One of the most popular server languages used by startups and
            enterprises around around the world. Build software with async,
            streams, and events built in.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={nodeImg} />
    },
    {
      reverse: true,
      mainSection: true,
      icon: "server",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Learn Golang</h2>
          <p>
            Go is a fast, high performance language growing in popularity 
            due to its focus on concurrency, safety, and simplicity. 
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={goImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Use Modern Databases</h4>
          <p>
            We use the popular{" "}
            <a href="https://www.postgresql.org">
              PostgreSQL
            </a>{" "}and{" "}
            <a href="https://www.mongodb.com/">
              MongoDB
            </a>{" "}
            databases and their advanced features to store data for our applications.
          </p>
        </Text>
      )
    },
    {
      reverse: false,
      mainSection: true,
      icon: "laptop",
      mainComponent: ({ isMobile }) => (
        <Text bounceLeft={!isMobile} bounceRight={isMobile}>
          <h2>Learn the React Ecosystem</h2>
          <p>
            <NavLink to="user/dashboard/course/4/">React</NavLink>, Redux, and
            related libraries have become the most popular framework of tools
            for front-end developers.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceRight src={reactImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Prepare for Your New Career</h4>
          <p>
            We have partners throughout NYC (and the world) who need smart people 
            with these skills. Not just resume writing like other schools - we  
            actually work with you and hiring managers to get you a great career. 
            We mean it.
          </p>
        </Text>
      )
    },
  ],

  // DEVOPS

  devops: [
    <PromotionalRow
      careerKey="devops"
      gradient={getCareerConstant("devops").gradient}
    >
      <h2>DevOps Engineering Course</h2>
      <p>Create the tools and future-proof infrastructure that allow teams to scale 
      efficiently, quickly, and securely. </p>
    </PromotionalRow>,
    {
      reverse: false,
      mainSection: true,
      icon: "server",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Learn Node.js</h2>
          <p>
            One of the most popular server languages used by startups and
            enterprises around around the world. Build software with async,
            streams, and events built in.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={nodeImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Interact with the Shell</h4>
          <p>
            You'll become comfortable using the command line and BASH to build and use 
            commands and scripts to quickly interact with your systems and OS.
          </p>
        </Text>
      )
    },
    {
      reverse: true,
      mainSection: true,
      icon: "project-diagram",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Containers & Orchestration</h2>
          <p>
            To efficiently manage the environments of services and architectures, 
            tools like Docker have become standard. You will learn to use containers and 
            to orchestrate them to interact with each other.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={dockerImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>DevOps Utilities</h4>
          <p>
            Learn necessary and useful tools and concepts related to  
            service discovery, SSH, security, performance, and more. 
          </p>
        </Text>
      )
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Use Modern Databases</h4>
          <p>
            We use the popular{" "}
            <a href="https://www.postgresql.org">
              PostgreSQL
            </a>{" "}and{" "}
            <a href="https://www.mongodb.com/">
              MongoDB
            </a>{" "}
            databases and their advanced features to store data for our applications.
          </p>
        </Text>
      )
    },
    {
      reverse: false,
      mainSection: true,
      icon: "forward",
      mainComponent: ({ isMobile }) => (
        <Text bounceLeft={!isMobile} bounceRight={isMobile}>
          <h2>CI/CD & Automation</h2>
          <p>
            Agile is a core part of the DevOps ethos. You will learn to automate 
            tasks and pipelines to help your team optimize their workflows.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceRight src={ciImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Participate in Communities</h4>
          <p>
            We run some of the largest tech communities in the world, and we 
            support open-source initiatives like many of the top developers in the world.
            You will be prepared to give public talks or demos and contribute 
            to open-source projects to demonstrate your skills and share with others.
          </p>
        </Text>
      )
    },
    {
      reverse: true,
      mainSection: true,
      icon: "cloud",
      mainComponent: ({ isMobile }) => (
        <Text bounceLeft={!isMobile} bounceRight={isMobile}>
          <h2>Build in the Cloud</h2>
          <p>
            DevOps Engineers are expected to build and deploy all types of systems and 
            applications to the cloud. We will learn how to do so using some of the 
            most popular Cloud Service Providers.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceRight src={devopsImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Prepare for Your New Career</h4>
          <p>
            We have partners throughout NYC (and the world) who need smart people 
            with these skills. Not just resume writing like other schools - we  
            actually work with you and hiring managers to get you a great career. 
            We mean it.
          </p>
        </Text>
      )
    },
  ],

  // DATA SCIENCE

  "data-science": [
    <PromotionalRow
      careerKey="data-science"
      gradient={getCareerConstant("data-science").gradient}
    >
      <h2>Data Science Course</h2>
      <p>Discover patterns by analyzing complex data, create insightful visualizations, 
      and build informative models to overcome challenges across industries.</p>
    </PromotionalRow>,
    {
      reverse: false,
      mainSection: true,
      icon: "terminal",
      mainComponent: ({ isMobile }) => (
        <Text bounceLeft={!isMobile} bounceRight={isMobile}>
          <h2>Learn Python</h2>
          <p>
            This popular language was build for versatility and ease of use. 
            It's used by most of the top data-centric organizations around the world today.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceRight src={pythonImg} />
    },
    {
      reverse: true,
      mainSection: true,
      icon: "table",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Learn NumPy, Pandas, and Dask</h2>
          <p>
            You&apos;ll spend the majority of your time as a data analyst/scientist 
            finding, cleaning, and preparing data before you can do anything with it. 
            NumPy, Pandas, and Dask are tools experts use to make this much easier.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={numpyImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Improve Your Math Skills</h4>
          <p>
            We'll be (re)building your quantitative and statistical skills to industry 
            standard. These are crucial to validate, transform, and manipulate data to 
            make better decisions in the the real world.
          </p>
        </Text>
      )
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Build Your Portfolio</h4>
          <p>
            We'll be working with real-world data problems and datasets. Proving your 
            intuition in preparing your data, and showing how you develop your 
            findings are critical to landing a job as a Data Scientist or Analyst.
          </p>
        </Text>
      )
    },
    {
      reverse: false,
      mainSection: true,
      icon: "th",
      mainComponent: ({ isMobile }) => (
        <Text bounceLeft={!isMobile} bounceRight={isMobile}>
          <h2>Learn Machine Learning</h2>
          <p>
            You will learn how to derive your models and their parameters to construct 
            sophisticated means to predict or classify cross-sectional and longitudinal 
            data.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceRight src={scikitImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Access Data Anywhere</h4>
          <p>
            Data comes in many forms and from many sources. You will learn to access 
            data in worksheets, databases, websites, and API's.
          </p>
        </Text>
      )
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Participate in Communities</h4>
          <p>
            We run some of the largest tech communities in the world, and we 
            support open-source initiatives like many of the top developers in the world.
            You will be prepared to give public talks or demos and contribute 
            to open-source projects to demonstrate your skills and share with others.
          </p>
        </Text>
      )
    },
    {
      reverse: true,
      mainSection: true,
      icon: "share-alt",
      mainComponent: () => (
        <Text bounceRight>
          <h2>Learn Deep Learning</h2>
          <p>
            Using neural networks, dive deeper into more abstract data problems
            and learn to use models that construct their own insights of the data 
            they're given.
          </p>
        </Text>
      ),
      assetComponent: () => <ImageAsset bounceLeft src={pytorchImg} />
    },
    {
      mainSection: false,
      mainComponent: () => (
        <Text bounceRight noBackground>
          <h4>Prepare for Your New Career</h4>
          <p>
            We have partners throughout NYC (and the world) who need smart people 
            with these skills. Not just resume writing like other schools - we  
            actually work with you and hiring managers to get you a great career. 
            We mean it.
          </p>
        </Text>
      )
    },
  ]
};

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
