/* eslint import/prefer-default-export : off */
import styled from "styled-components";

const getContainerHeight = screen => {
  switch (screen) {
    case "xl":
    case "lg":
    case "sm":
    case "xs":
      return "100vh";
    case "md":
      return "75vh";
    default:
      return "100vh";
  }
};

export const OnlineCoursesContainer = styled.div.attrs({
  id: "courses"
})`
  position: relative;
  width: 100%;
  height: ${props => getContainerHeight(props.screen)};
  overflow-x: hidden;
`;

export const LinkButtonsContainer = styled.div`
  margin-top: 4%;
  margin-left: ${props => (["xl", "lg"].includes(props.screen) ? "5%" : "5%")};
`;
