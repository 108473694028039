import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Text } from "./styledElements";

const NpmLoading = ({ isLoading }) => (
  <Container isLoading={isLoading}>
    <FontAwesomeIcon icon="spinner" spin />
    <Text>Fetching dependencies...</Text>
  </Container>
);

NpmLoading.propTypes = {
  isLoading: PropTypes.bool
};

NpmLoading.defaultProps = {
  isLoading: false
};

export default NpmLoading;
