import styled from "styled-components";
import { Row } from "react-grid-system";

import DetailLabel from "../DetailLabel";

export const Container = styled(Row).attrs({
  align: "start",
  justify: "start"
})`
  margin: 20px 0 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Value = styled(DetailLabel)`
  color: rgb(51, 51, 51);
`;
