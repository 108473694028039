/* eslint import/prefer-default-export : off */

/**
 * Source: https://api-docs.npms.io/
 * Searches npm libraries from source
 *
 * @param {String} query search query
 * @param {Number} from the offset of search
 *
 * @return {Object} { results: [], total: 0 }
 */

export const searchLibraries = async ({ query, from = 0 } = {}) =>
  fetch(`https://api.npms.io/v2/search?q=${query}&from=${from}`)
    .then(res => res.json())
    .then(result => [null, result])
    .catch(err => [err]);
