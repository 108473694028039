import React from "react";
import Notifications from "react-notify-toast";
import { Provider } from "react-redux";
import { ApolloProvider } from "react-apollo";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { setConfiguration } from "react-grid-system";
import Observer from "fontfaceobserver";
import { Helmet } from "react-helmet";

import "react-sortable-tree/style.css";
import "react-splitter-layout/lib/index.css";

import { setupIcons } from "./utils/general/icons";
import { setupClient } from "./utils/apollo/client";

import Routes from "./pages/routes";
import store from "./store";

import "./theme/theme.css";

import("./assets/fonts/futura/stylesheet.css");
import("./assets/fonts/enhanced/Enhanced.css");

const Futura = new Observer("Futura PT");

Futura.load().then(() => {
  document.documentElement.classList.add("futura-ready");
  document.body.style.maxHeight = "unset";
});

setConfiguration({ defaultScreenClass: "md", gutterWidth: 20 });
setupIcons();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY);
const client = setupClient();

export default () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://www.schema.org",
              "@type": "EducationalOrganization",
              additionalType:
                "https://en.wikipedia.org/wiki/Online_learning_in_higher_education",
              "@id": "https://amagiacademy.com",
              name:
                "Amagi Academy | Live, online courses for Software, Data Science, and DevOps",
              url: "https://amagiacademy.com",
              logo: "https://amagi.io/assets/images/AmagiAcademyOG.png",
              image: "https://amagi.io/assets/images/AmagiAcademyOG.png",
              description: `Learn the skills for a new career in tech from anywhere in just 3 months and get connected to companies looking for people like you.
            
            Attend live, online, part-time classes focused on Software Development, Data Science, or DevOps Engineering with personal attention and help whenever you need it. Build competency from fundamentals and a portfolio of work that you'll be able to proudly show off to employers.
            
            Pay either upfront or pay nothing at all until you land a high-paying job. Our focus is on your career success.`,
              contactPoint: {
                "@type": "ContactPoint",
                email: "academy@amagi.io",
                contactType: "admissions"
              },
              sameAs: [
                "https://www.facebook.com/amagiacademy/",
                "https://www.instagram.com/amagiacademy/",
                "https://twitter.com/amagiacademy",
                "https://www.linkedin.com/company/amagiacademy/"
              ]
            })}
          </script>
        </Helmet>
        <Routes />
        <Notifications options={{ zIndex: 99999999999 }} />
      </Elements>
    </ApolloProvider>
  </Provider>
);
