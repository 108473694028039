import React from "react";
import PropTypes from "prop-types";
import Colors from "theme/colors";

import { notify } from "react-notify-toast";
import { css } from "styled-components";
import { withRouter } from "react-router-dom";
import { Mutation } from "react-apollo";

import { DELETE_COURSE } from "api/mutations/course";

import Button from "../../../Button";
import DeleteConfirmation from "../DeleteConfirmation";

class DeleteCourse extends React.PureComponent {
  state = {
    showConfirmation: false
  };

  showConfirmation = () =>
    this.setState({
      showConfirmation: true
    });

  hideConfirmation = () =>
    this.setState({
      showConfirmation: false
    });

  onCompleted = () => {
    const { history } = this.props;

    notify.show("Successfully deleted course.", "success");
    history.push("/user/dashboard");
  };

  onError = error => {
    notify.show(error.message, "error");
  };

  render() {
    const { showConfirmation } = this.state;
    const { id, type } = this.props;

    return (
      <Mutation
        mutation={DELETE_COURSE}
        variables={{
          id,
          type
        }}
        errorPolicy="all"
        onCompleted={this.onCompleted}
        onError={this.onError}
      >
        {(deleteCourse, { loading: isDeleting }) => {
          return (
            <>
              <Button
                size="large"
                title="Delete Course"
                loadingText="Deleting..."
                loading={isDeleting}
                onClick={this.showConfirmation}
                customstyles={css`
                  box-shadow: none;
                  border: none;
                  color: ${Colors.error};
                `}
              />
              <DeleteConfirmation
                open={showConfirmation}
                onDelete={deleteCourse}
                isDeleting={isDeleting}
                onClose={this.hideConfirmation}
              />
            </>
          );
        }}
      </Mutation>
    );
  }
}

DeleteCourse.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  type: PropTypes.string.isRequired
};

export default withRouter(DeleteCourse);
