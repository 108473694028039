import React from "react";
import PropTypes from "prop-types";

import { Sandbox } from "./components";

class HTMLSandbox extends React.Component {
  getValue = () => {
    return this.sandbox.getValue();
  };

  render() {
    const { value } = this.props;

    return (
      <Sandbox
        value={value}
        template="parcel"
        ref={instance => {
          this.sandbox = instance;
        }}
      />
    );
  }
}

HTMLSandbox.propTypes = {
  value: PropTypes.string
};

HTMLSandbox.defaultProps = {
  value: null
};

export default HTMLSandbox;
