import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Row, ScreenClassRender, Visible } from "react-grid-system";
import { withRouter, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthClearIdToken, isLoggedin } from "api/auth";
import { logoutUser } from "store/user";
import { explode } from "utils/general/ui";
import {
  Avatar,
  Popover,
  Logo,
  Brand,
  PopoverContainer,
  PopoverLogoutButton,
  PopoverUser,
  PopoverItem,
  PopoverUserShort,
  PopoverImage
} from "components";
import Menu from "./Menu";
import {
  MenuContainer,
  HeaderContainer,
  MobileContainer,
  HeaderRow,
  LinksContainer,
  Link,
  StyledNavLink,
  LoginLink,
  QuickLinksContainer,
  AngleDown
} from "./styledElements";

class Header extends React.PureComponent {
  onMouseOver = e => explode(e.pageX, e.pageY);

  defaultHandler = () => null;

  render() {
    const { avatar, name, location, logout, history } = this.props;
    const isLanding = location.pathname === "/";

    return (
      <ScreenClassRender
        render={screen => (
          <HeaderContainer className="hidden-font">
            <MobileContainer screen={screen}>
              <Menu />
              <NavLink to="/">
                <Logo />
              </NavLink>
              <div>
                {isLoggedin() && (
                  <QuickLinksContainer screen={screen}>
                    <Avatar image={avatar} />
                    <Popover
                      preferPlace="below"
                      body={
                        /* eslint-disable-next-line */
                        <PopoverContainer>
                          <Row nogutter align="start">
                            <PopoverImage src={avatar} />
                            <div>
                              <PopoverUser>{name}</PopoverUser>
                              <PopoverUserShort>
                                <em>Amagi Academy</em>
                                <PopoverItem>
                                  <NavLink to="/user/dashboard">
                                    My Courses
                                  </NavLink>
                                  <NavLink to="/account/settings">
                                    Account
                                  </NavLink>
                                </PopoverItem>
                              </PopoverUserShort>
                            </div>
                          </Row>
                          <PopoverLogoutButton
                            onClick={() => {
                              AuthClearIdToken();
                              logout();
                              history.replace("/login");
                            }}
                          >
                            Logout
                          </PopoverLogoutButton>
                        </PopoverContainer>
                      }
                    >
                      <AngleDown />
                    </Popover>
                  </QuickLinksContainer>
                )}
                {!isLoggedin() && <LoginLink to="/login">Sign In</LoginLink>}
              </div>
            </MobileContainer>
            <MenuContainer screen={screen}>
              <HeaderRow align="center">
                <Visible xl lg md>
                  <NavLink to="/">
                    <Brand noLogo={isLanding} />
                  </NavLink>
                </Visible>
                {/* <BrandRow>
                  <Logo />
                  <Brand screen={screen} noLogo />
                </BrandRow> */}
                <LinksContainer screen={screen}>
                  <StyledNavLink color="#08f7fe" to="/catalog" screen={screen}>
                    Practice
                  </StyledNavLink>
                  <Link color="#f5d300" href="/blog">
                    Blog
                  </Link>
                  <StyledNavLink color="#b76cfd" to="/terms" screen={screen}>
                    FAQ
                  </StyledNavLink>
                  <Link
                    color="#fe53bb"
                    target="_blank"
                    href="https://techpool.io"
                  >
                    Jobs
                  </Link>
                  <Link color="#09fbd3" target="_blank" href="https://amagi.io">
                    Hire Us
                  </Link>
                  <Visible xl lg md>
                    {!isLanding && isLoggedin() && (
                      <QuickLinksContainer screen={screen}>
                        <Avatar image={avatar} />
                        <Popover
                          preferPlace="below"
                          body={
                            /* eslint-disable-next-line */
                            <PopoverContainer>
                              <Row nogutter align="start">
                                <PopoverImage src={avatar} />
                                <div>
                                  <PopoverUser>{name}</PopoverUser>
                                  <PopoverUserShort>
                                    <em>Amagi Academy</em>
                                    <PopoverItem>
                                      <NavLink to="/user/dashboard">
                                        My Courses
                                      </NavLink>
                                      <NavLink to="/account/settings">
                                        Account
                                      </NavLink>
                                    </PopoverItem>
                                  </PopoverUserShort>
                                </div>
                              </Row>
                              <PopoverLogoutButton
                                onClick={() => {
                                  AuthClearIdToken();
                                  logout();
                                  history.replace("/login");
                                }}
                              >
                                Logout
                              </PopoverLogoutButton>
                            </PopoverContainer>
                          }
                        >
                          <AngleDown />
                        </Popover>
                      </QuickLinksContainer>
                    )}
                    {!isLanding && !isLoggedin() && (
                      <LoginLink to="/login">
                        <FontAwesomeIcon icon="user" /> Sign In
                      </LoginLink>
                    )}
                  </Visible>
                </LinksContainer>
              </HeaderRow>
            </MenuContainer>
          </HeaderContainer>
        )}
      />
    );
  }
}

Header.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  logout: PropTypes.func.isRequired
};

Header.defaultProps = {
  avatar: null,
  name: null
};

export default connect(
  state => ({
    avatar: state.user.picture,
    name: state.user.name
  }),
  {
    logout: logoutUser
  }
)(withRouter(Header));
