import React from "react";
import PropTypes from "prop-types";
import Sizes from "theme/sizes";
import styled from "styled-components";

import { ScreenClassRender } from "react-grid-system";

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;

  ${props =>
    !props.wide &&
    `
      max-width: ${Sizes.contentWidth};
    `};
  ${props =>
    props.wide &&
    `
      padding: 0 ${
        ["xl", "lg", "md"].includes(props.screen)
          ? Sizes.contentGutter
          : Sizes.contentGutterSmall
      };
    `};
  margin: 0 auto;
`;

const ContentWrapper = ({ wide, children, ...props }) => (
  <ScreenClassRender
    render={screen => (
      <Wrapper wide={wide} screen={screen} {...props}>
        {children}
      </Wrapper>
    )}
  />
);

ContentWrapper.propTypes = {
  wide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ContentWrapper.defaultProps = {
  wide: false,
  children: null
};

export default ContentWrapper;
