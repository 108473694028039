import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const VERIFY_PROMO_CODE = gql`
  query VerifyPromoCode($id: String!) {
    promo_code(id: $id) {
      name
      id
      valid
      percent_off
    }
  }
`;
