import React from "react";
import PropTypes from "prop-types";

import { FILE_ICONS } from "constants/sandbox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileContainer } from "./styledElements";
import { FileActions } from "./components";

class File extends React.PureComponent {
  selectFile = () => {
    const { selectFile, path } = this.props;

    if (selectFile) {
      selectFile(path);
    }
  };

  getFilename = path =>
    path
      .split("/")
      .filter(Boolean)
      .pop();

  getFileExtension = fileName =>
    fileName === "package.json" ? fileName : fileName.split(".").pop();

  renderIcon = () => {
    const { isDirectory, isOpen, path } = this.props;

    if (isDirectory) {
      return (
        <FontAwesomeIcon
          icon={
            !isOpen ? FILE_ICONS.folder.icon : FILE_ICONS["folder-open"].icon
          }
          color={FILE_ICONS.folder.color}
        />
      );
    }

    const fileName = this.getFilename(path);
    const extension = this.getFileExtension(fileName);

    return (
      <FontAwesomeIcon
        icon={
          (FILE_ICONS[extension] && ["fab", FILE_ICONS[extension].icon]) ||
          "file-code"
        }
        color={
          (FILE_ICONS[extension] && FILE_ICONS[extension].color) ||
          FILE_ICONS.folder.color
        }
      />
    );
  };

  handleKeyPress = () => null;

  render() {
    const {
      path,
      selectFile,
      onClick,
      depth,
      active,
      isDirectory,
      showFileCreator,
      deleteFile
    } = this.props;

    const fileName = this.getFilename(path);
    const extension = this.getFileExtension(fileName);

    return (
      <FileContainer
        tabIndex="0"
        role="button"
        onClick={selectFile ? this.selectFile : onClick}
        onKeyPress={this.handleKeyPress}
        depth={depth}
        active={active}
        color={
          (FILE_ICONS[extension] && FILE_ICONS[extension].color) ||
          FILE_ICONS.folder.color
        }
      >
        <div>
          {this.renderIcon()}
          <span>{fileName}</span>
        </div>
        <FileActions
          isDirectory={isDirectory}
          showFileCreator={showFileCreator}
          deleteFile={deleteFile(path)}
          path={path}
        />
      </FileContainer>
    );
  }
}

File.propTypes = {
  selectFile: PropTypes.func,
  path: PropTypes.string,
  onClick: PropTypes.func,
  depth: PropTypes.number,
  active: PropTypes.bool,
  isDirectory: PropTypes.bool,
  isOpen: PropTypes.bool,
  showFileCreator: PropTypes.func,
  deleteFile: PropTypes.func
};

File.defaultProps = {
  selectFile: null,
  onClick: () => null,
  path: "",
  depth: 0,
  active: false,
  isDirectory: false,
  isOpen: false,
  showFileCreator: () => null,
  deleteFile: () => null
};

export default File;
