import FontFamily from "theme/fontfamily";
import FontSizes from "theme/fontsizes";
import Colors from "theme/colors";

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  min-height: 20px;
  display: ${props => (!props.editing ? "inline-flex" : "block")};
  flex-direction: row;
  align-items: ${props => (props.centerItems ? "center" : "flex-start")};
  width: 100%;
  cursor: pointer;

  .edit-button {
    visibility: ${props => (props.showEditAlways ? "visible" : "hidden")};
  }

  &:hover {
    ${props =>
      !props.editing &&
      props.allowEdit &&
      `
    .display-text {
      background-color: #f0f0f0;
      transition: background-color 275ms ease-in;
    }

    .edit-button {
      visibility: visible;
    }
    `};
  }
`;

export const Bullet = styled.span`
  margin-right: 10px;
`;

export const ErrorContainer = styled.div.attrs({
  className: "error-container"
})`
  margin-top: 10px;
  font-size: 0.9rem;
  color: ${Colors.error};
`;

export const styles = {
  text: {
    fontFamily: FontFamily.base,
    color: Colors.onBackgroundSecondary,
    fontSize: FontSizes.base,
    display: "block",
    width: "100%",
    resize: "none",
    margin: 0,
    padding: 5,
    outline: "none",
    boxSizing: "border-box"
  },
  dropdown: {
    padding: "5px 0"
  },
  displayText: {
    padding: 0
  },
  input: {
    outlineColor: Colors.primary,
    outlineWidth: 1,
    borderRadius: 0,
    minHeight: 20
  }
};
