import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { createBrowserHistory as createHistory } from "history";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ScrollRestore } from "components";
import { isLoggedin } from "../api/auth";

import {
  Blog,
  Class,
  Login,
  NotFound,
  Terms,
  CourseCatalog,
  Account,
  Mentorship,
  Certification,
  OnsiteCourses,
  Bootcamp,
  BootcampApplication,
  BootcampPayment,
  LiveCourseSubscription
} from "./index";

import LandingPage from "./LandingPage";

// Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_ID, {
  debug: process.env.NODE_ENV === "development",
  testMode: process.env.NODE_ENV === "test"
});
// Triggered when loading page not from React router history change
ReactGA.pageview(window.location.pathname + window.location.search);

const history = createHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);

  window.fbq("track", "PageView");
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    location: { pathname }
  } = rest;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedin() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                error: true,
                redirectUrl: pathname,
                message: "Please sign in to continue."
              }
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]).isRequired
};

export default () => (
  <Router history={history}>
    <ScrollRestore>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/mentorship" component={Mentorship} />
        <Route path="/certification" component={Certification} />
        <Route path="/terms" component={Terms} />
        <Route path="/catalog" component={CourseCatalog} />
        <Route
          path="/live-courses/subscribe"
          component={LiveCourseSubscription}
        />
        <PrivateRoute
          path="/live-courses/application"
          component={BootcampApplication}
        />
        <PrivateRoute
          path="/live-courses/payment"
          component={BootcampPayment}
        />
        <Route path="/live-courses/:course" component={OnsiteCourses} />
        <Route path="/live-courses" component={Bootcamp} />
        <Route path={["/signup", "/login"]} component={Login} />
        <Route
          path="/blog"
          // eslint-disable-next-line no-shadow
          render={({ history, match }) => (
            <Blog history={history} basename={match.url} />
          )}
          // render={({ history, match }) => (
          //   <NaviRouter
          //     routes={blogRoutes}
          //     history={history}
          //     basename={match.url}
          //   >
          //     <Suspense fallback={null}>
          //       <Blog />
          //     </Suspense>
          //   </NaviRouter>
          // )}
        />

        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/user/dashboard" component={CourseCatalog} />
        <PrivateRoute path="/class/:id(\d+)" component={Class} />
        <Route component={NotFound} />
      </Switch>
    </ScrollRestore>
  </Router>
);
