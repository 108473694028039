import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";
import { ScreenClassRender } from "react-grid-system";

import { Button } from "components";
import { PromotionRow, PromotionContent } from "./styledElements";

const PromotionalRow = ({ careerKey, gradient, children }) => (
  <ScreenClassRender
    render={screen => (
      <>
        <svg viewBox="0 0 0 0" height="0" width="0">
          <defs id="defs2" />
          <g id="layer1">
            <clipPath
              id="wave"
              d="M 129.99737,-5.6130525 C 153.23764,103.74976 76.762311,87.504755 66.816704,136.07761 c -7.86731,38.42277 25.334508,92.195 25.334508,92.195 l 233.646828,-6.24058 0.43916,-240.411607 z"
              style={{
                fill: "#000000",
                stroke: "#000000",
                strokeWidth: "0.264583px",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeOpacity: 1,
                fillOpacity: 1
              }}
            />
          </g>
        </svg>
        <PromotionRow>
          <PromotionContent>
            {children}
            <Button
              title="Learn More"
              isExternal
              to={`/live-courses/${careerKey}`}
              customstyles={css`
                background: linear-gradient(
                  45deg,
                  ${gradient[0]},
                  ${gradient[1]}
                );
                box-shadow: 0px 9px 30px 0 rgba(40, 78, 136, 0.3);
                width: fit-content;
                padding: 0.5rem 1.4rem;
                color: white !important;
              `}
            />
          </PromotionContent>
        </PromotionRow>
      </>
    )}
  />
);

PromotionalRow.propTypes = {
  careerKey: PropTypes.string,
  gradient: PropTypes.instanceOf(Array),
  children: PropTypes.instanceOf(Array)
};

PromotionalRow.defaultProps = {
  careerKey: "software",
  gradient: [],
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default PromotionalRow;
