import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-grid-system";
import { Wrapper, SectionContent, Header } from "./LayoutComponents";

class Layout extends React.PureComponent {
  static defaultProps = {
    selected: false,
    leftContent: {
      icon: null,
      text: null
    },
    rightContent: {
      icon: null,
      text: null
    },
    onSelect: () => {}
  };

  render() {
    const { leftContent, rightContent, selected, onSelect } = this.props;
    return (
      <Wrapper onClick={onSelect}>
        <Header selected={selected}>AMAGI ACADEMY</Header>
        <Row nogutter>
          <Col xl={6}>
            <SectionContent selected={selected}>
              {leftContent.icon}
              {leftContent.text}
            </SectionContent>
          </Col>
          <Col xl={6}>
            <SectionContent className="right" selected={selected}>
              {rightContent.icon}
              {rightContent.text}
            </SectionContent>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

Layout.propTypes = {
  leftContent: PropTypes.instanceOf(Object),
  rightContent: PropTypes.instanceOf(Object),
  selected: PropTypes.bool,
  onSelect: PropTypes.func
};

export default Layout;
