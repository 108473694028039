/* eslint import/prefer-default-export : off */
import styled from "styled-components";

export const FileContainer = styled.div`
  ${props => `
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  outline: none;
  border-left: 2px solid ${props.active ? props.color : "transparent"};
  color: ${
    props.active ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"
  };
  font-size: 14px;
  padding: 0.5rem 0.8rem;
  background-color: ${
    props.active ? "rgba(255, 255, 255, 0.05)" : "transparent"
  };

  padding-left: ${props.depth ? props.depth * 0.8 : 0.8}rem;


  svg {
    font-size: 14px;
    margin-right: 8px;
  }

  .file-actions {
    display: none;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    cursor: pointer;

    border-color: ${props.color};

    .file-actions {
      display: block;
    }
  }

  `}
`;
