import styled from "styled-components";

import Colors, { GRADIENTS } from "theme/colors";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem 10%;

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
      padding: 1%;
    `}
`;

export const SectionHeadline = styled.h2`
  margin: 0 2rem;
  margin-bottom: 1.5rem;
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
  font-family: "Open Sans", sans-serif;

  span {
    ${GRADIENTS.software};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const SectionBody = styled.div`
  color: ${Colors.text};
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  hr {
    margin: 1rem 0;
    width: 50%;
    height: 1px;
    border: none;
    background: #212121;
    opacity: 0.3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  a {
    font-size: 1rem;
  }

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       h2 {
         font-size: 2rem;   
         text-align: center;
       }

       hr {
         margin: 0 auto;
         margin-bottom: 1rem;
       }
    `}
`;

export const ReasonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem 2rem;
`;

export const ReasonCount = styled.div`
  font-size: 3rem;
  font-weight: 200;
  font-family: "Open Sans", sans-serif;
`;

export const ReasonHeadline = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
`;

export const ReasonBody = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;