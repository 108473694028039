import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  visibility: ${props => (props.isLoading ? "visible" : "hidden")};
  pointer-events: ${props => (props.isLoading ? "auto" : "none")};
`;

export const Text = styled.div`
  font-size: 0.8rem;
  margin-top: 10px;
  font-weight: 700;
`;
