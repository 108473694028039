import React from "react";
import { ScreenClassRender } from "react-grid-system";
// import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

// import { css } from "styled-components";
import {
  AdvantagesContainer,
  AdvantagesHeadline,
  AdvantagesSubline,
  AdvantagesCol,
  AdvantagesList,
  // ButtonRow,
  // ButtonCol
} from "./styledElements";

const Advantages = () => {
  // const onEnter = () => {
  //   history.push("/catalog");
  // };

  return (
    <ScreenClassRender
      render={screen => (
      <AdvantagesContainer screen={screen}>
        <AdvantagesHeadline>Get the Amagi Academy <span>advantage</span></AdvantagesHeadline>
        <AdvantagesCol>
          <AdvantagesSubline>
            Amagi Academy is one of the longest running tech academies 
            invested in improving tech talent around the world. Built 
            and run by the experts at <a href="https://amagi.io">AMAGI</a>, 
            we're the ones people go to when even the biggest brands need help. 
          </AdvantagesSubline>
          <AdvantagesList screen={screen}>
            <ul>
              <li>Affordable for people of all backgrounds</li>
              <li>Develop real world, in-demand skills</li>
              <li>Choose one of three career paths</li>
              <li>Courses taught by *real* professionals</li>
              <li>Learn from fundamentals by experts</li>
              <li>Break through the beginner barrier</li>
              <li>Create a portfolio and a solid resume</li>
              <li>Get connected to jobs around the world</li>
            </ul>
          </AdvantagesList>
        </AdvantagesCol>
        {/* <AdvantagesRow>
          <AdvantagesCol>
            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam in metus sollicitudin, tristique justo ac, ornare quam. Quisque ultrices et purus vehicula vehicula. Donec lacinia convallis erat, in fringilla massa convallis quis.</div>
            <AdvantagesList screen={screen}>
              <ul>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Lorem ipsum dolor sit amet</li>
              </ul>
            </AdvantagesList>
          </AdvantagesCol>
          {/* <AdvantagesRow>
            <AdvantagesCol>
              <h3>For Students</h3>
              <div>
                <ul>
                  <li>Intensive 12 week courses by experts</li>
                  <li>Free online practice courses</li>
                  <li>Online and offline communities</li>
                  <li>Support to grow your career</li>
                </ul>
              </div>
            </AdvantagesCol>
            <LogoCol>
              <Logo size="image" />
            </LogoCol>
            <AdvantagesCol>
              <h3>For Employers</h3>
              <div>
                <ul>
                  <li>Reach smart devs around the world</li>
                  <li>Certifications reflecting real skills</li>
                  <li>Guaranteed skill competencies</li>
                </ul>
              </div>
            </AdvantagesCol>
          </AdvantagesRow>
          */}
          {/* <ButtonRow>
            <ButtonCol>
              <Button
                onClick={onEnter}
                title="ENTER"
                customstyles={css`
                  max-width: 250px;
                  box-shadow: -10px 10px 15px 0px rgba(27, 243, 254, 0.75);
                  border: 1px solid rgba(51, 51, 51, 0.075);
                `}
              />
            </ButtonCol>
          </ButtonRow> */}
        </AdvantagesContainer>
      )}
    />
  );
};

// Advantages.propTypes = {
//   history: PropTypes.instanceOf(Object).isRequired
// };

export default withRouter(Advantages);
