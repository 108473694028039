import React from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

class ScrollRestore extends React.Component {
  componentDidUpdate(prevProps) {
    const { location, element } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      const content = element || window;

      content.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ScrollRestore.propTypes = {
  location: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  element: PropTypes.instanceOf(Element)
};

ScrollRestore.defaultProps = {
  location: {},
  children: null,
  element: null
};

export default withRouter(ScrollRestore);
