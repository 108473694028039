import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: "PageDataLayer",
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
serviceWorker.unregister();
