import styled, { keyframes } from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import Bounce from "react-reveal/Bounce";
import Colors, { GRADIENTS } from "theme/colors";

const yoyo = distance => keyframes`
  from {
    transform: translateY(${distance}px);
  } to {
    transform: translateY(-${distance}px);
  }
`;

const pulse = color => keyframes`
  0% {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1),
      0 0 8px 6px rgba(26, 255, 179, 0), 0 0 0px 0px transparent,
      0 0 0px 0px rgba(26, 255, 179, 0);
  }
  10% {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1),
      0 0 8px 6px ${color}66, 0 0 12px 10px transparent,
      0 0 12px 14px ${color}4D;
  }
  100% {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1),
      0 0 8px 6px rgba(26, 255, 179, 0), 0 0 0px 40px transparent,
      0 0 0px 40px rgba(26, 255, 179, 0);
  }
`;

const theme = {
  color: "rgb(27, 243, 254)"
};

export const TimelineContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 !important;
  padding: 0;
  padding-bottom: 5%;
  background: ${Colors.background};
  align-items: center;
  max-width: unset !important;
  width: 100% !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  ${props =>
    ["xs", "sm"].includes(props.screen) &&
    `
    box-shadow: none;
  `}
`;

TimelineContainer.defaultProps = {
  theme
};

export const TimelineRow = styled(Row)`
  margin: 2rem 10% !important;
  width: 80%;
  max-width: 80%;
  padding: 1rem !important;

  a {
    color: ${props => props.theme.color};
  }

  ${props =>
    ["xs", "sm"].includes(props.screen) &&
    `
    flex-direction: column-reverse !important;
    flex-wrap: nowrap !important;
  `}

  &:hover .card {
    transform: translatey(-5px);

    .small-circle {
      opacity: 1;
    }
  }
`;

export const PromotionRow = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 9px 30px rgba(0, 0, 0, 0.1);
  margin: 2rem 10% !important;
  margin-top: 5rem !important;
  width: 80%;
  max-width: 80%;
  height: 300px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    margin-top: 2rem !important;
    flex-direction: column-reverse;
    height: unset;
    justify-content: flex-start;
  }
`;

export const PromotionContent = styled.div`
  margin-right: 1rem;
  padding: 2rem 4rem !important;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    text-align: justify;
  }

  a {
    color: ${Colors.primary};
  }

  @media screen and (max-width: 750px) {
    text-align: center;
    margin-right: 0;
    width: 100%;
    position: relative;
    padding: 2rem !important;

    a {
      width: 100%;
    }
  }
`;

export const PromotionAsset = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  height: 100%;
  flex: 1;
  position: relative;
  ${GRADIENTS.blue};
  background-image: linear-gradient(
    45deg,
    ${props => `${props.theme.gradient[0]}, ${props.theme.gradient[1]}`}
  );
  clip-path: polygon(100% 0, 24% 0, 0 100%, 100% 100%);

  img {
    font-size: 12rem;
    width: 100%;
    transform: rotate(-20deg);
    opacity: 0.3;
  }

  @media screen and (max-width: 750px) {
    height: unset;
    width: 100%;
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 60%);

    margin-left: 0;

    img {
      height: 130%;
      width: unset;
      transform: rotate(20deg) translateY(0%) translateX(0%);
    }
  }
`;

export const SmallCircle = styled.div.attrs({ className: "small-circle" })`
  width: ${props => props.size || 10}px;
  height: ${props => props.size || 10}px;
  border-radius: ${props => props.size || 10}px;
  position: absolute;
  opacity: 0;
  transition: opacity 275ms ease-in;

  animation-duration: ${props => props.duration || 1000}ms;
  animation-delay: ${props => props.delay || 275}ms;
  animation-name: ${props => yoyo(props.distance)};
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  ${props => props.position};

  ${props => !props.borderOnly && `background-color: ${props.theme.color}`};
  ${props =>
    props.borderOnly &&
    `
  border: 1px solid ${props.theme.color};
  `};
`;

SmallCircle.defaultProps = {
  theme
};

export const TimelineRowReverse = styled(TimelineRow)`
  flex-direction: row-reverse;
`;

export const TimelineCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextColumn = styled(TimelineCol).attrs({
  md: 5
})`
  padding: 5%;
`;

export const TextBounce = styled(Bounce).attrs({
  fraction: 1
})`
  width: 100%;
`;

export const AssetBounce = styled(Bounce).attrs({
  delay: 100,
  fraction: 1
})`
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  ${props =>
    !props.noBackground &&
    `
    background-color: ${Colors.background};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: auto;
    border-radius: 10px;
  `}

  h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0 !important;
    font-size: 1rem;
  }
`;

TextContainer.defaultProps = {
  theme
};

export const PeriodColumn = styled(TimelineCol).attrs({
  md: 2,
  sm: 12,
  xs: 12,
  lg: 2,
  xl: 2
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AssetColumn = styled(TimelineCol).attrs({
  md: 5
})``;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
  }
`;

export const PeriodIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  background-color: inherit;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(51, 51, 51, 0.075);
  border-radius: 50px;
  z-index: 5;
  transition: 0.3s ease-in;
  margin: 1rem;

  svg {
    height: 40px;
    width: 40px !important;
    color: ${props => props.theme.color};
  }

  &.pulse {
    animation: ${props => pulse(props.theme.color)} 2s 0s ease-out infinite;
  }
`;

PeriodIndicator.defaultProps = {
  theme,
  ind: 0
};

export const SmallTimelineRow = styled(TimelineRow).attrs({
  justify: "end"
})`
  ${TextColumn} {
    padding: 0 !important;
  }

  ${TextContainer} {
    padding: 1rem !important;

    h4 {
      margin-bottom: 0.5rem;
      font-weight: bold;
      font-size: 0.9rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
`;

export const SmallPeriodIndicator = styled.div`
  height: 1rem;
  width: 1rem;
  background-color: inherit;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(51, 51, 51, 0.075);
  border-radius: 50px;
  z-index: 5;
  margin: 1rem;

  &.pulse {
    animation: ${props => pulse(props.theme.color)} 2s 0s ease-out infinite;
  }
`;

SmallPeriodIndicator.defaultProps = {
  theme
};
