import {
  GET_COURSES,
  GET_COURSES_BY_AUTHOR,
  SEARCH_COURSES,
  GET_ENROLLED_COURSES,
  GET_PUBLIC_COURSES
} from "api/queries/course";

export const getDashboardQuery = ({ pathname, searchQuery, userId } = {}) => {
  switch (pathname) {
    case "/user/dashboard": {
      if (searchQuery) {
        return {
          query: SEARCH_COURSES,
          variables: {
            title: searchQuery
          },
          fetchPolicy: "network-only",
          shouldInvalidatePreviousData: true
        };
      }
      return {
        query: GET_COURSES,
        fetchPolicy: "network-only"
      };
    }
    case "/user/dashboard/enrolled-courses": {
      return {
        query: GET_ENROLLED_COURSES,
        variables: {
          userId
        },
        fetchPolicy: "network-and-cache"
      };
    }
    case "/user/dashboard/published-courses": {
      return {
        query: GET_COURSES_BY_AUTHOR,
        variables: {
          author: userId
        },
        fetchPolicy: "network-only"
      };
    }
    default: {
      return {
        query: GET_PUBLIC_COURSES,
        fetchPolicy: "network-and-cache"
      };
    }
  }
};

export const getCoursesFromQueryResponse = ({
  pathname,
  data = {},
  searchQuery
} = {}) => {
  switch (pathname) {
    case "/user/dashboard": {
      if (searchQuery) {
        return data.searchCourses || [];
      }
      return data.courses || [];
    }
    case "/user/dashboard/enrolled-courses": {
      return (data.usercoursebyuser && data.usercoursebyuser.courses) || [];
    }
    case "/user/dashboard/all-courses": {
      if (searchQuery) {
        return data.searchCourses || [];
      }
      return data.courses || [];
    }
    case "/user/dashboard/published-courses": {
      return data.courseByAuthor || [];
    }
    default: {
      return data.publicCourses || [];
    }
  }
};
