import styled from "styled-components";
import { Row } from "react-grid-system";

export const Container = styled.div``;

export const ButtonsContainer = styled(Row).attrs({
  align: "center"
})`
  margin: 0 !important;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const Description = styled.p`
  margin-bottom: 36px;
  font-weight: normal;
`;
