import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-grid-system";

import { ClassReadingTitle } from "../../pages/Class/ClassComponents";
import { Text } from "./components";

class TextEditor extends React.Component {
  state = {
    transcriptValue: ""
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      transcriptValue: value
    });
  }

  getValue = () => {
    const { transcriptValue } = this.state;
    return transcriptValue;
  };

  setTranscriptValue = ({ transcriptValue } = {}) =>
    this.setState({
      transcriptValue
    });

  render() {
    const { value, match } = this.props;
    return (
      <Col xl={12}>
        <ClassReadingTitle>
          <FontAwesomeIcon icon="book-open" />
          <span className="text">Text</span>
        </ClassReadingTitle>
        <Text
          setTranscriptValue={this.setTranscriptValue}
          transcriptValue={value}
          match={match}
        />
      </Col>
    );
  }
}

TextEditor.propTypes = {
  value: PropTypes.string,
  match: PropTypes.instanceOf(Object)
};

TextEditor.defaultProps = {
  value: null,
  match: {}
};

export default TextEditor;
