import { ImageUploader, VideoUploader } from "components/Uploaders";

import { LAYOUT_CODES } from "constants/layout";

import HTML from "./HTML";
import Markdown from "./Markdown";
import ReactSandbox from "./ReactSandbox";
import TextEditor from "./TextEditor";

import { PythonSandbox, NodeSandbox, CommonSandbox } from "./components";

export {
  HTML,
  Markdown,
  NodeSandbox,
  PythonSandbox,
  ReactSandbox,
  TextEditor,
  CommonSandbox
};

export default {
  [LAYOUT_CODES.HTML]: HTML,
  [LAYOUT_CODES.NODE]: NodeSandbox,
  [LAYOUT_CODES.PYTHON]: PythonSandbox,
  [LAYOUT_CODES.IMAGE]: ImageUploader,
  [LAYOUT_CODES.VIDEO]: VideoUploader,
  [LAYOUT_CODES.REACT]: ReactSandbox,
  [LAYOUT_CODES.TEXT]: TextEditor,
  [LAYOUT_CODES.COMMON_SANDBOX]: CommonSandbox
};
