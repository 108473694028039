import styled from "styled-components";
import FontSizes from "theme/fontsizes";

export const LayoutWrapper = styled.div`
  display: flex;
  flexdirection: row;
  alignitems: flex-start;
`;

export const SplitterWrapper = styled.div`
  flex: 1.8;
  position: relative;
  height: calc(100vh - 108px);
`;

export const FilesWrapper = styled.div`
  min-height: calc(100vh - 108px);
  max-height: calc(100vh - 108px);
  max-width: 1200px;
  width: 200px;
  overflow-x: auto;
  background-color: #24282a;
`;

export const FilesTitle = styled.div.attrs({
  className: "files-title"
})`
  color: rgba(255, 255, 255, 0.6);
  padding: 0.875rem;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: ${FontSizes.smallBase};

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  svg {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
`;
