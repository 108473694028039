import React from "react";
import { notify } from "react-notify-toast";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Row } from "react-grid-system";

import { AuthClearIdToken } from "api/auth";
import { logoutUser } from "store/user";

import {
  PopoverContainer,
  PopoverImage,
  PopoverItem,
  PopoverLogoutButton,
  PopoverUser,
  PopoverUserShort
} from "../Popover";

export default connect(
  state => ({
    avatar: state.user.picture,
    name: state.user.name
  }),
  {
    logout: logoutUser
  }
)(
  withRouter(({ history, logout = () => {}, name, avatar }) => (
    <PopoverContainer>
      <Row nowrap nogutter align="start">
        <PopoverImage src={avatar} />
        <div>
          <PopoverUser>{name}</PopoverUser>
          <PopoverUserShort>
            <em>Amagi Academy</em>
            <PopoverItem>
              <Link to="/user/dashboard">My Courses</Link>
              <Link to="/account/settings">Account</Link>
            </PopoverItem>
          </PopoverUserShort>
        </div>
      </Row>
      <PopoverLogoutButton
        onClick={() => {
          AuthClearIdToken();
          logout();
          notify.show("You have successfully signed out.", "info");
          history.replace("/login");
        }}
      >
        Logout
      </PopoverLogoutButton>
    </PopoverContainer>
  ))
);
