/* eslint import/prefer-default-export : off */
import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    props.background &&
    `
    background: url(${props.background}) no-repeat center center;
  `}

  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : "cover"};
`;
