/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";

import { Mutation, withApollo } from "react-apollo";

import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { uploadMedia } from "api/upload";
import { getSyllabusUpdate } from "utils/course/syllabus";
import { getGraphqlError } from "utils/general/errors";

import { UPDATE_COURSE } from "api/mutations/course";
import { GET_UPLOAD_TOKEN } from "api/queries/tokens";
import {
  CREATE_LAYOUT,
  UPDATE_LAYOUT,
  DELETE_LAYOUT
} from "api/mutations/layout";

import { COURSE_TYPES } from "constants/course";

import Button from "../../../Button";

export class UpdateCourse extends React.Component {
  state = {
    isUploading: false
  };

  toggleUploading = () => {
    const { isUploading } = this.state;

    this.setState({
      isUploading: !isUploading
    });
  };

  onSave = ({
    updateCourse,
    createLayout,
    updateLayout,
    deleteLayout
  } = {}) => async () => {
    const {
      id,
      userId,
      formValues,
      image,
      client,
      deletedSyllabus,
      initialSyllabus,
      iterations,
      refetch
    } = this.props;

    const { syllabus } = formValues;

    const {
      title,
      short,
      about,
      price,
      tags,
      duration,
      max_attendees,
      hours_per_session,
      type,
      difficulty,
      language
    } = formValues;

    let imageURL = image;

    if (image && typeof image === "object") {
      this.toggleUploading();

      try {
        const { data: uploadData } = await client.query({
          query: GET_UPLOAD_TOKEN
        });

        const [uploadError, newImage] = await uploadMedia({
          media: image,
          token: uploadData.uploadToken
        });

        if (uploadError) {
          throw new Error(uploadError);
        }

        imageURL = newImage;

        this.toggleUploading();
      } catch (err) {
        this.onError(err);

        this.toggleUploading();
        return;
      }
    }

    let variables = {
      title,
      short,
      about,
      tags,
      difficulty,
      language,
      type,
      image: imageURL,
      author: userId
    };

    await this.updateSyllabus({
      createLayout,
      updateLayout,
      deleteLayout
    });

    if (type === COURSE_TYPES.SELF_STUDY) {
      variables = {
        ...variables,
        duration
      };
    } else if (type === COURSE_TYPES.GUIDED_LEARNING) {
      variables = {
        ...variables,
        price,
        hours_per_session,
        max_attendees,
        iterations: iterations.map(
          // eslint-disable-next-line camelcase
          ({ iterationId, session_starts, attendees }) => ({
            id: iterationId,
            session_starts,
            attendees
          })
        )
      };
    }

    await updateCourse({
      variables: {
        ...variables,
        id
      }
    });

    const syllabusUpdates = {
      ...getSyllabusUpdate({ syllabus, initialSyllabus }),
      deletes: deletedSyllabus
    };

    const { creates } = syllabusUpdates;

    if (creates && creates.length > 0) {
      refetch();
    }
  };

  updateSyllabus = async ({ createLayout, updateLayout, deleteLayout }) => {
    const {
      id,
      deletedSyllabus,
      initialSyllabus,
      formValues: { syllabus }
    } = this.props;

    const syllabusUpdates = {
      ...getSyllabusUpdate({ syllabus, initialSyllabus }),
      deletes: deletedSyllabus
    };

    const { creates, deletes, updates } = syllabusUpdates;

    if (creates && creates.length > 0) {
      await createLayout({
        variables: {
          layouts: creates.map(layout => {
            const layoutClone = { ...layout };
            delete layoutClone.orderIndex;

            return {
              ...layoutClone,
              order_index: layout.orderIndex,
              course_id: id
            };
          })
        }
      });
    }

    await Promise.all(
      updates.map(async layout => {
        await updateLayout({
          variables: {
            ...layout
          }
        });
      })
    );

    await Promise.all(
      deletes.map(async layout => {
        await deleteLayout({
          variables: {
            id: layout
          }
        });
      })
    );
  };

  onCompleted = () => {
    notify.show("Successfully updated the course!", "success");
  };

  onError = error => {
    notify.show(getGraphqlError(error), "error");
  };

  render() {
    const { isUploading } = this.state;
    const { id, isValidForm } = this.props;

    return (
      <Mutation mutation={CREATE_LAYOUT} onError={this.onError}>
        {(createLayout, { loading: isCreatingLayout }) => (
          <Mutation mutation={UPDATE_LAYOUT} onError={this.onError}>
            {(updateLayout, { loading: isUpdatingLayout }) => (
              <Mutation mutation={DELETE_LAYOUT} onError={this.onError}>
                {(deleteLayout, { loading: isDeletingLayout }) => (
                  <Mutation
                    mutation={UPDATE_COURSE}
                    onCompleted={this.onCompleted}
                    onError={this.onError}
                    variables={{
                      id
                    }}
                  >
                    {(updateCourse, { loading: isUpdatingCourse }) => (
                      <Button
                        size="large"
                        title="Save Course"
                        loadingText="Saving course..."
                        loading={
                          isUpdatingCourse ||
                          isUploading ||
                          isCreatingLayout ||
                          isUpdatingLayout ||
                          isDeletingLayout
                        }
                        disabled={
                          !isValidForm ||
                          isUpdatingCourse ||
                          isUploading ||
                          isCreatingLayout ||
                          isUpdatingLayout ||
                          isDeletingLayout
                        }
                        onClick={this.onSave({
                          updateCourse,
                          updateLayout,
                          createLayout,
                          deleteLayout
                        })}
                      />
                    )}
                  </Mutation>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

UpdateCourse.propTypes = {
  userId: PropTypes.string,
  id: PropTypes.string,
  formValues: PropTypes.instanceOf(Object),
  deletedSyllabus: PropTypes.instanceOf(Array),
  initialSyllabus: PropTypes.instanceOf(Array),
  image: PropTypes.string,
  isValidForm: PropTypes.bool,
  client: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func.isRequired,
  iterations: PropTypes.instanceOf(Array)
};

UpdateCourse.defaultProps = {
  id: null,
  userId: null,
  formValues: {},
  image: null,
  initialSyllabus: [],
  deletedSyllabus: [],
  iterations: [],
  isValidForm: false
};

export default connect(state => ({
  userId: state.user.id,
  formValues: formValueSelector("courseDetails")(
    state,
    ...[
      "title",
      "about",
      "price",
      "tags",
      "duration",
      "max_attendees",
      "hours_per_session",
      "iterations",
      "type",
      "difficulty",
      "language",
      "syllabus"
    ]
  )
}))(withApollo(UpdateCourse));
