const careers = [
  {
    key: "devops",
    label: "DevOps",
    description: "Manage secure software that scales",
    color: "#02C967",
    gradient: ["#89ee49", "#22bad2"]
  },
  {
    key: "software",
    label: "Software",
    description: "Create dazzling experiences for users",
    color: "#3BAAF0",
    gradient: ["rgb(33,212,253)", "rgb(183,33,255)"]
  },
  {
    key: "data-science",
    label: "Data Science",
    description: "Discover patterns and solutions using data",
    color: "#6111DE",
    gradient: ["#9E60FF", "#f00e83"]
  }
];

export default careers;
