import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "theme/colors";
import FullNodeDragTheme from "react-sortable-tree-theme-full-node-drag";

import { Field } from "redux-form";

import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll
} from "react-sortable-tree";

import { faColumns, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ErrorContainer } from "components/EditableInputs/common";

import { DEFAULT_CLASS_ENTRY } from "constants/course";

import IconButton from "./IconButton";
import Button from "../Button";
import LayoutSelector from "../LayoutSelector";
import { EditableText } from "../EditableInputs";

import "./style.css";

class SyllabusEditor extends React.Component {
  state = {
    hoverIndex: null,
    editIndex: null,
    deletes: [],
    activeNode: {}
  };

  componentDidMount() {
    const { syllabus } = this.props;
    const newSyllabus = toggleExpandedForAll({
      treeData: syllabus,
      expanded: true
    });
    this.onChange(newSyllabus);
  }

  getNodeKey = ({ treeIndex }) => treeIndex;

  setActiveNode = ({ node, path } = {}) =>
    this.setState({
      activeNode: {
        node,
        path
      }
    });

  addNode = () => {
    const { syllabus } = this.props;
    const newSyllabus = syllabus.concat(DEFAULT_CLASS_ENTRY);

    this.onChange(newSyllabus);
  };

  addChildNode = ({ path }) => () => {
    const { syllabus } = this.props;

    const newSyllabus = addNodeUnderParent({
      treeData: syllabus,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey: this.getNodeKey,
      newNode: DEFAULT_CLASS_ENTRY
    }).treeData;

    this.onChange(newSyllabus);
  };

  removeNode = ({ node, path }) => () => {
    if (node.id) {
      const { deletes } = this.state;

      deletes.push(node.id);

      if (node.children && Array.isArray(node.children)) {
        node.children.map(child => {
          if (child.id) {
            deletes.push(child.id);
          }

          return child;
        });
      }

      this.setState({
        deletes
      });
    }

    const { syllabus } = this.props;
    const newSyllabus = removeNodeAtPath({
      treeData: syllabus,
      path,
      getNodeKey: this.getNodeKey
    });

    this.onChange(newSyllabus);
  };

  onNodeTitleChange = ({ node, path }) => response => {
    const { syllabus } = this.props;
    const title =
      response.constructor === String ? response : response.target.value;

    const newSyllabus = changeNodeAtPath({
      treeData: syllabus,
      path,
      getNodeKey: this.getNodeKey,
      newNode: { ...node, title }
    });

    this.onChange(newSyllabus);
  };

  onNodeLayoutChange = ({ node, path, layoutCode }) => {
    const { syllabus } = this.props;
    const newSyllabus = changeNodeAtPath({
      treeData: syllabus,
      path,
      getNodeKey: this.getNodeKey,
      newNode: { ...node, layoutCode }
    });

    this.onChange(newSyllabus);
  };

  onChange = syllabus => {
    const { deletes } = this.state;
    const {
      containerOnChange,
      changeIndividualValues,
      input: { onChange }
    } = this.props;

    if (onChange) {
      onChange(syllabus);
      if (containerOnChange) {
        containerOnChange({ syllabus, deletes });
      }

      if (changeIndividualValues) {
        changeIndividualValues(syllabus);
      }
    }
  };

  setHoverIndex = hoverIndex => () => this.setState({ hoverIndex });

  setEditIndex = editIndex => () => {
    const {
      meta: { error }
    } = this.props;

    if (!error) {
      this.setState({ editIndex });
    }
  };

  renderButtons = ({ treeIndex, node, path } = {}) => {
    const { isNew, editMode } = this.props;
    const { hoverIndex } = this.state;

    if ((isNew || editMode) && treeIndex === hoverIndex) {
      return [
        <IconButton
          iconProps={{ icon: faColumns, fixedWidth: true }}
          onClick={() => {
            this.setActiveNode({
              node,
              path
            });
            this.layoutSelector.showLayoutSelector({
              currentLayout: node.layoutCode
            });
          }}
        />,
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          {path.length < 2 && (
            <IconButton
              onClick={this.addChildNode({ node, path })}
              iconProps={{ icon: faPlus, fixedWidth: true }}
            />
          )}
        </div>,
        <IconButton
          onClick={this.removeNode({ node, path })}
          iconProps={{ icon: faTimes, fixedWidth: true }}
        />
      ];
    }

    return [];
  };

  renderTitle = ({ treeIndex, node, path } = {}) => {
    const { editIndex } = this.state;
    const {
      isNew,
      editMode,
      onStopEdit,
      meta: { error }
    } = this.props;

    return (
      <Field
        name={`syllabus-field-${treeIndex}`}
        component={EditableText}
        singleLine
        centerItems
        enableInlineEdit
        hasParentError={!!error}
        showTextError={false}
        showEditAlways={isNew || editMode}
        allowEdit={isNew || editMode}
        showBullet={!isNew && !editMode}
        containerOnChange={this.onNodeTitleChange({ node, path })}
        type="p"
        textStyle={{
          fontSize: "inherit",
          fontWeight: "normal",
          color: Colors.onBackground
        }}
        startEdit={this.setEditIndex(treeIndex)}
        stopEdit={() => {
          this.setEditIndex(null)();
          onStopEdit({ data: node });
        }}
        editing={typeof editIndex === "number" && editIndex === treeIndex}
      />
    );
  };

  render() {
    const { activeNode } = this.state;
    const {
      syllabus,
      isNew,
      editMode,
      meta: { error }
    } = this.props;

    return (
      <div>
        <SyllabusWrapper isNew={isNew} viewOnly={!isNew && !editMode}>
          <LayoutSelector
            noTrigger
            onChange={layoutCode => {
              this.onNodeLayoutChange({
                layoutCode,
                node: activeNode.node,
                path: activeNode.path
              });
            }}
            ref={instance => {
              this.layoutSelector = instance;
            }}
          />
          <div className="display-text">
            <SortableTree
              className=""
              maxDepth={2}
              canDrag={isNew || editMode}
              isVirtualized={false}
              style={{ flex: 1 }}
              treeData={syllabus}
              theme={FullNodeDragTheme}
              onChange={this.onChange}
              generateNodeProps={({ node, path, treeIndex }) => ({
                buttons: this.renderButtons({ treeIndex, node, path }),
                title: this.renderTitle({ treeIndex, node, path }),
                onDrag: this.setEditIndex(null),
                onMouseEnter:
                  (isNew || editMode) && this.setHoverIndex(treeIndex),
                onMouseLeave: (isNew || editMode) && this.setHoverIndex(null),
                listIndex: 0,
                lowerSiblingCounts: []
              })}
            />
            {error && <ErrorContainer>{error}</ErrorContainer>}
          </div>
        </SyllabusWrapper>
        {(isNew || editMode) && (
          <Button
            bordered
            type="transparent"
            size="small"
            title="Add New Lesson"
            onClick={this.addNode}
            customstyles={{
              marginTop: "20px",
              fontSize: "0.9rem",
              marginLeft: "40px"
            }}
          />
        )}
      </div>
    );
  }
}

const SyllabusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  margin-left: ${props => (props.isNew ? "0px" : "-44px")};

  .rstcustom__rowContents {
    box-shadow: ${props =>
      props.viewOnly ? "none" : "0 0 10px rgba(0, 0, 0, 0.1)"};

    &:hover {
      box-shadow: ${props =>
        props.viewOnly ? "none" : Colors.primaryLowOpacity};
    }
  }

  .display-text {
    flex: 1;
  }

  &:hover {
    ${props =>
      props.isNew &&
      `
    .display-text {
      background-color: #f0f0f0;
      transition: background-color 275ms ease-in;
    }

    .edit-button {
      visibility: visible;
    }
    `};
  }
`;

SyllabusEditor.propTypes = {
  syllabus: PropTypes.instanceOf(Array),
  onStopEdit: PropTypes.func,
  containerOnChange: PropTypes.func,
  changeIndividualValues: PropTypes.func,
  isNew: PropTypes.bool,
  editMode: PropTypes.bool,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object)
};

SyllabusEditor.defaultProps = {
  onStopEdit: () => null,
  changeIndividualValues: null,
  containerOnChange: null,
  syllabus: [],
  isNew: false,
  editMode: false,
  input: {},
  meta: {}
};

export default SyllabusEditor;
