/* eslint import/prefer-default-export: off */
import styled from "styled-components";

export const Container = styled.div.attrs({
  className: "file-actions"
})`
  svg {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    margin-right: 0;
  }
`;
