import React from "react";
import { css } from "styled-components";
import { ScreenClassRender } from "react-grid-system";

import { Button } from "components";
import Colors from "theme/colors";

import {
    Section,
    SectionHeadline,
    SectionBody,
    CourseContainer,
    CourseHeadline,
    CourseBody
} from "./styledElements";

const Courses = () => (
    <ScreenClassRender
    render={screen => (
        <Section screen={screen} id="courses">
            <SectionHeadline>Explore the Courses</SectionHeadline>
            <SectionBody screen={screen}>
                <CourseContainer screen={screen} className="data">
                    <CourseHeadline>Data Science & Analytics</CourseHeadline>
                    <CourseBody>
                        Decipher insights from data using analytical, modeling, and
                        visualization methods.
                    </CourseBody>
                    <Button
                        title="Learn more"
                        isExternal
                        to="/live-courses/data-science"
                        customstyles={css`
                            color: ${Colors.pink};
                            background: ${Colors.white};

                            &:hover {
                                opacity: 0.7;
                                box-shadow: none;
                            }
                        }
                        `}
                    />
                </CourseContainer>
                <CourseContainer className="devops">
                    <CourseHeadline>DevOps Engineering</CourseHeadline>
                    <CourseBody>
                        Streamline the workflow of your teams and the performance of
                        your systems.
                    </CourseBody>
                    <Button
                        title="Learn more"
                        isExternal
                        to="/live-courses/devops"
                        customstyles={css`
                                color: ${Colors.teal};
                                background: ${Colors.white};

                                &:hover {
                                    opacity: 0.7;
                                    box-shadow: none;
                                }
                            }
                            `}
                        />
                </CourseContainer>
                <CourseContainer screen={screen} className="software">
                    <CourseHeadline>Software Development</CourseHeadline>
                    <CourseBody>
                        Learn the skills to create modern, full-stack web/mobile
                        applications.
                    </CourseBody>
                    <Button
                        title="Learn more"
                        isExternal
                        to="/live-courses/software"
                        customstyles={css`
                                color: ${Colors.blue};
                                background: ${Colors.white};

                                &:hover {
                                    opacity: 0.7;
                                    box-shadow: none;
                                }
                            }
                        `}
                    />
                </CourseContainer>
            </SectionBody>
        </Section>
      )}
    />
  );
  
  export default Courses;