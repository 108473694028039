/* eslint no-extra-boolean-cast : off */
import styled from "styled-components";

import FontSizes from "theme/fontsizes";

export const Section = styled.div`
  position: relative;
  width: 100%;
  padding: ${props => (props.top ? "220px 0 180px" : "150px 0")};
  background: ${props => (props.background ? props.background : "transparent")};
  overflow: hidden;
  ${props => props.customstyles};
`;

export const Title = styled.h2`
  font-size: ${props => props.size || "40px"};
  margin-bottom: 20px;
  text-align: ${props => (!!props.align ? props.align : "left")};
  color: ${props => (!!props.color ? props.color : "black")};
  ${props => props.customstyles};
`;

export const Paragraph = styled.p`
  font-size: ${props => props.size || FontSizes.description};
  line-height: ${props => props.lineheight || "30px"};
  margin: 0 0 20px;
  color: ${props => (!!props.color ? props.color : "black")};
  text-align: ${props => (!!props.align ? props.align : "left")};
`;

/**
 * Layout controls
 */

export const LimitWidth = styled.section`
  width: 100%;
  ${props => (!!props.max ? `max-width: ${props.max}px` : "")};
  ${props => (!!props.margin ? `margin: ${props.margin}` : "")};
`;
