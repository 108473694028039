import React from "react";
import PropTypes from "prop-types";
import FontSizes from "theme/fontsizes";
import Fontfamily from "theme/fontfamily";
import Colors, { GRADIENTS } from "theme/colors";
import styled, { css } from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LargeStyles = css`
  min-height: 54px;
  padding: 0 22px;
`;

const SmallStyles = css`
  min-height: 46px;
  font-size: 1rem;
`;

const VerySmallStyles = css`
  min-height: 32px;
  font-size: ${FontSizes.smallBase};
`;

const CancelColorStyles = ({ bordered } = {}) => css`
  background-color: transparent;
  color: ${Colors.onBackgroundSecondary};
  transition: all .15s ease-in;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    opacity: 0.7;
    box-shadow: none;
  }

  ${bordered &&
    `
    border: 1px solid ${Colors.onBackgroundSecondary};
  `}
`;

const DefaultColorStyles = ({ bordered } = {}) => css`
  ${GRADIENTS.blue};

  color: ${Colors.white};
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  transition: all .15s ease-in;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    box-shadow: 0px 7px 15px 0 rgba(83,150,253,0.3);
    transition: all .15s ease-in;
  }

  ${bordered &&
    `
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: ${Colors.white};
  `}
`;

const SimpleColorStyles = ({ bordered } = {}) => css`
  background-color: #c1c1c1;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  padding: 1rem;
  color: ${Colors.white};
  transition: all .15s ease-in;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    opacity: 0.7;
    box-shadow: none;
    transition: all .15s ease-in;
  }

  ${bordered &&
    `
    padding: 0 1rem;
    border: 1px solid rgba(51, 51, 51, 0.05);
  `}
`;

const DangerColorStyles = ({ bordered } = {}) => css`
  background-color: #d9534f;
  color: ${Colors.onPrimary};
  transition: all .15s ease-in;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    opacity: 0.7;
    box-shadow: none;
    transition: all .15s ease-in;
  }

  ${bordered &&
    `
      padding: 0 1rem;
      border: 1px solid #d9534f;
    `}
`;

const commonStyles = css`
  border: none;
  align-items: center;
  font-family: ${Fontfamily.base};
  border-radius: 5rem;
  padding: 0 1.4rem;
  font-size: ${FontSizes.button};
  font-weight: 400;
  cursor: pointer;
  opacity: 1;
  transition: opacity 275ms ease-out;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 38px;
  transition: all .15s ease-out;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    box-shadow: 0px 7px 15px 0 rgba(83,150,253,0.3);
    transition: all .15s ease-in;
  }

  &:disabled {
    opacity: 0.4;
    transition: opacity 275ms ease-in;
    cursor: not-allowed;
  }
`;

const StyledButton = styled.button`
  ${commonStyles};

  ${props => props.size === "extra-small" && VerySmallStyles};
  ${props => props.size === "large" && LargeStyles};
  ${props => props.size === "small" && SmallStyles};

  ${props =>
    props.buttontype === "default" &&
    DefaultColorStyles({ bordered: props.bordered })};
  ${props =>
    props.buttontype === "cancel" &&
    CancelColorStyles({ bordered: props.bordered })};
  ${props =>
    props.buttontype === "simple" &&
    SimpleColorStyles({ bordered: props.bordered })};

  ${props =>
    props.buttontype === "danger" &&
    DangerColorStyles({ bordered: props.bordered })}

  ${props => props.customstyles};
`;

const StyledLink = styled.a`
  ${commonStyles};
  text-decoration: none;

  ${props => props.size === "extra-small" && VerySmallStyles};
  ${props => props.size === "large" && LargeStyles};
  ${props => props.size === "small" && SmallStyles};

  ${props =>
    props.buttontype === "default" &&
    DefaultColorStyles({ bordered: props.bordered })};
  ${props =>
    props.buttontype === "cancel" &&
    CancelColorStyles({ bordered: props.bordered })};

  ${props => props.customstyles};
`;

class Button extends React.PureComponent {
  render() {
    const {
      title,
      size,
      onClick,
      type,
      htmlType,
      customstyles,
      isExternal,
      to,
      disabled,
      loading,
      bordered,
      loadingText,
      className,
      ...props
    } = this.props;

    if (!isExternal) {
      return (
        <StyledButton
          bordered={bordered}
          size={size}
          onClick={!disabled ? onClick : () => {}}
          type={htmlType}
          buttontype={type}
          customstyles={customstyles}
          disabled={disabled}
          className={className}
          {...props}
        >
          {!loading ? (
            title
          ) : (
            <span>
              <FontAwesomeIcon icon="spinner" spin style={{ marginRight: 8 }} />
              {loadingText}
            </span>
          )}
        </StyledButton>
      );
    }

    return (
      <StyledLink
        href={to}
        size={size}
        buttontype={type}
        customstyles={customstyles}
        className={className}
        {...props}
      >
        {title}
      </StyledLink>
    );
  }
}

Button.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  htmlType: PropTypes.string,
  customstyles: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(css)
  ]),
  isExternal: PropTypes.bool,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  bordered: PropTypes.bool,
  loadingText: PropTypes.string,
  className: PropTypes.string
};

Button.defaultProps = {
  title: "",
  htmlType: "button",
  type: "default",
  size: "default",
  isExternal: false,
  disabled: false,
  bordered: false,
  loading: false,
  loadingText: "Loading...",
  to: "",
  className: null,
  customstyles: null,
  onClick: () => null
};

export default Button;
