import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileCreatorContainer } from "./styledElements";

class FileCreator extends React.PureComponent {
  state = {
    showFileCreator: false
  };

  showFileCreator = event => {
    if (event) event.stopPropagation();

    this.setState(
      {
        showFileCreator: true
      },
      () => {
        this.fileInput.focus();
      }
    );
  };

  hideFileCreator = () => {
    this.setState({
      showFileCreator: false
    });
  };

  handleBlur = event => {
    const { showFileCreator } = this.state;

    if (showFileCreator) this.hideFileCreator(event);
  };

  handleKeyPress = event => {
    const { createFile, prefixedPath } = this.props;

    if (event.which === 13 || event.key === "Enter") {
      const value = `${prefixedPath}${event.target.value}`;

      createFile(value);
      this.handleBlur(event);
    }
  };

  render() {
    const { showFileCreator } = this.state;
    const { depth } = this.props;

    if (!showFileCreator) return null;

    return (
      <FileCreatorContainer depth={depth}>
        <FontAwesomeIcon icon="file" />
        <input
          ref={instance => {
            this.fileInput = instance;
          }}
          type="text"
          onBlur={this.handleBlur}
          onKeyPress={this.handleKeyPress}
        />
      </FileCreatorContainer>
    );
  }
}

FileCreator.propTypes = {
  depth: PropTypes.number,
  prefixedPath: PropTypes.string,
  createFile: PropTypes.func.isRequired
};

FileCreator.defaultProps = {
  depth: 0,
  prefixedPath: "/"
};

export default FileCreator;
