/* eslint import/prefer-default-export : off */

export const truncate = (str, maxLength) => {
  if (!str) return "";

  const LENGTH = maxLength || 200;

  if (str.length <= LENGTH) return str;

  return `${str.substr(0, LENGTH - 1)}...`;
};
