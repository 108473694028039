import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const SIZES = (screen = "xl") => ({
  big: `
    font-size: ${["xl", "lg", "md"].includes(screen) ? "2rem" : "2rem"};
    line-height: ${["xl", "lg", "md"].includes(screen) ? "2.5rem" : "2.5rem"};`,
  small: `
    font-size: ${["xl", "lg", "md"].includes(screen) ? "1.5rem" : "1.5rem"};
    line-height: ${["xl", "lg", "md"].includes(screen) ? "2rem" : "2rem"};
    `
});

const commonStyles = props => css`
white-space: ${["sm", "xs"].includes(props.screen) ? "pre-wrap" : "nowrap"};
text-decoration: none;
color: inherit;
display: table;
border-radius: 10px;
padding: 5px
  ${["xl", "lg", "md"].includes(props.screen) ? "10%" : "2%"};
margin-bottom: 32px;
background-color: #ffffff;
box-shadow: -10px 10px 15px 0px rgba(27, 243, 254, 0.75);
border: 1px solid rgba(51, 51, 51, 0.075);
width: auto;
text-align: center;
font-weight: bold;

${SIZES(props.screen)[props.size] || SIZES.small};

${props.indented && `margin-left: 10%`}

${props.customstyles}

${["xs"].includes(props.screen) &&
  `
  margin-left: 0;
  margin-right: 0;
`}
`;

export const Container = styled(NavLink).attrs({
  className: "link-button"
})`
  ${props => commonStyles(props)}
`;

export const ExternalLink = styled.a.attrs({
  className: "link-button"
})`
  ${props => commonStyles(props)}
`;
