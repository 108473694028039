import React from "react";
import PropTypes from "prop-types";
import Colors from "theme/colors";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: ${Colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 0.8rem;

  img {
    width: 100%;
  }

  ${props => props.customstyles}
`;

const Avatar = ({ text, image, customstyles }) => (
  <Wrapper customstyles={customstyles}>
    {text && text}
    {image && <img src={image} alt="avatar" />}
  </Wrapper>
);

Avatar.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  customstyles: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(css)
  ])
};

Avatar.defaultProps = {
  text: null,
  image: null,
  customstyles: {}
};

export default Avatar;
