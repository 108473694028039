import styled from "styled-components";
import { Row } from "react-grid-system";

import COLORS from "theme/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const SectionSubheading = styled.h3`
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  text-spacing: 0.2rem;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const FieldsContainer = styled.div`
  margin-bottom: 1rem;
`;

export const FieldGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: no-wrap;
  margin: 0;
  width: 100%;
  max-width: 400px;
  margin-bottom: 0.7rem;

  .wrapper {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const Label = styled.div`
  font-size: 0.8rem;
  margin-bottom: 3px;
`;

export const CardInput = styled.div`
  margin-bottom: 0.5rem;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid ${COLORS.black};
  transition: border-color 275ms ease-out, box-shadow 275ms ease-in;
  box-shadow: none;
  width: calc(100% - 28px);
  height: 2.5rem;

  &:hover,
  &:focus {
    transition: border-color 275ms ease-in, box-shadow 275ms ease-in;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const FilledField = styled.div`
  color: ${COLORS.onBackgroundLowOpacity};
  font-weight: bold;
`;

export const CardNumber = styled.div`
  color: ${COLORS.onBackgroundLowOpacity};
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const CouponGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SaveRow = styled(Row)`
  margin-top: 1rem;
`;
