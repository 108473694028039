/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import Colors from "theme/colors";

import { COURSE_TYPES, NEW_COURSE_INITIAL_DATA } from "constants/course";

import { connect } from "react-redux";
import { formValueSelector, reduxForm, Field } from "redux-form";
import { prettyPrint } from "utils/general/date";
import { validateCourseValues } from "utils/course/validation";
import { flatSyllabus } from "utils/course/syllabus";
import { isLoggedin } from "api/auth";

import {
  AdditionalDetails,
  CourseImage,
  DetailLabel,
  Footer,
  SectionLabel,
  PaymentPopup
} from "./components";

import IterationView from "../IterationView";
import SyllabusEditor from "../SyllabusEditor";
import { EditableText } from "../EditableInputs";
import {
  LastUpdate,
  ScheduledClasses,
  SyllabusContainer
} from "./styledElements";

class CourseDetails extends React.Component {
  initialSyllabus = null;

  state = {
    paymentFormVisible: false
  };

  componentDidMount = () => {
    this.initializeValues();
  };

  componentDidUpdate = prevProps => {
    const { courseData } = this.props;

    if (prevProps.courseData !== courseData) {
      this.initializeValues();
    }
  };

  togglePaymentForm = () => {
    this.setState(state => ({
      paymentFormVisible: !state.paymentFormVisible
    }));
  };

  changeIterationsValues = iterations => {
    const { change } = this.props;

    change("iterations", iterations);
  };

  changeSyllabusValues = syllabus => {
    const { change } = this.props;

    const syllabusValues = this.getSyllabusValues(syllabus);
    Object.keys(syllabusValues).map(key => {
      change(key, syllabusValues[key]);
      return key;
    });

    change("syllabus", syllabus);
  };

  getSyllabusValues = syllabus => {
    let treeIndex = 0;
    return syllabus.reduce((prev, next) => {
      /* eslint no-param-reassign : off */
      prev[`syllabus-field-${treeIndex}`] = next.title;

      treeIndex += 1;

      if (next.children) {
        next.children.map(child => {
          prev[`syllabus-field-${treeIndex}`] = child.title;
          treeIndex += 1;

          return child;
        });
      }
      return prev;
    }, {});
  };

  initializeValues = () => {
    const {
      image,
      title,
      type,
      price,
      tags,
      duration,
      difficulty,
      /* eslint-disable camelcase */
      hours_per_session,
      max_attendees,
      about,
      syllabus,
      iterations,
      language,
      initialize
    } = this.props;

    this.initialSyllabus = flatSyllabus({ syllabus, includeId: true });
    const syllabusValues = this.getSyllabusValues(syllabus);

    const initialValues = {
      type,
      image,
      title,
      about,
      tags,
      difficulty,
      language,
      syllabus,
      ...syllabusValues
    };

    if (type === COURSE_TYPES.SELF_STUDY) {
      initialize({
        ...initialValues,
        duration
      });
    }

    if (type === COURSE_TYPES.GUIDED_LEARNING) {
      initialize({
        ...initialValues,
        hours_per_session,
        max_attendees,
        iterations,
        price: price.toString()
      });
    }
  };

  isValidForm = () => {
    const { valid } = this.props;

    return valid;
  };

  render() {
    const { paymentFormVisible } = this.state;
    const {
      price,
      title,
      image,
      type,
      isNew,
      onChange,
      editMode,
      onEdit,
      syllabus,
      iterations,
      updatedAt,
      published,
      id,
      author,
      loading,
      onSave,
      isExistingCourse,
      reset,
      formValues,
      deletedSyllabus,
      refetch
    } = this.props;

    const { tags } = formValues;
    const lastUpdate = updatedAt || new Date().getTime() / 1000;
    const isSelfPaced = type === COURSE_TYPES.SELF_STUDY;

    return (
      <>
        <CourseImage
          image={image}
          tags={tags}
          enableInlineEdit={editMode}
          onChange={onChange("image")}
          editMode={editMode}
          onEdit={({ type } = {}) => {
            onEdit({ type, field: "image" });
          }}
        />
        <div
          style={{
            flex: 1,
            paddingTop: 40
          }}
        >
          <LastUpdate>
            <span>Last Updated: </span>
            <span>{prettyPrint(lastUpdate * 1000)}</span>
          </LastUpdate>
          <Field
            name="title"
            component={EditableText}
            centerItems
            onEdit={({ type } = {}) => {
              onEdit({ type, field: "title" });
            }}
            showEditAlways={editMode}
            enableInlineEdit={editMode}
            editing={editMode}
            allowEdit={editMode}
            type="h2"
            textStyle={{
              fontSize: "1.7rem",
              marginBottom: 0,
              fontWeight: "600",
              color: Colors.onBackground
            }}
          />
          <AdditionalDetails
            type={type}
            onEdit={onEdit}
            isNew={editMode}
            editMode={editMode}
          />
          <DetailLabel>COURSE DESCRIPTION</DetailLabel>
          <Field
            name="about"
            component={EditableText}
            onEdit={({ type } = {}) => {
              onEdit({ type, field: "about" });
            }}
            showEditAlways={editMode}
            enableInlineEdit={editMode}
            editing={editMode}
            allowEdit={editMode}
            type="p"
            textStyle={{
              fontSize: "inherit",
              lineHeight: 1.7,
              whiteSpace: "pre-wrap",
              color: Colors.onBackground
            }}
            containerStyle={{ marginBottom: "40px" }}
          />
          <SyllabusContainer>
            <SectionLabel>Structured Lessons</SectionLabel>
            <Field
              name="syllabus"
              component={SyllabusEditor}
              isNew={isNew}
              editMode={editMode}
              onEdit={({ type } = {}) => {
                onEdit({ type, field: "syllabus" });
              }}
              syllabus={syllabus}
              containerOnChange={onChange("syllabus")}
              changeIndividualValues={this.changeSyllabusValues}
            />
          </SyllabusContainer>
          {!isSelfPaced && (
            <>
              <SectionLabel>Scheduled Classes</SectionLabel>
              <ScheduledClasses>
                <Field
                  name="iterations"
                  component={IterationView}
                  isNew={isNew}
                  editMode={editMode}
                  onEdit={({ type } = {}) => {
                    onEdit({ type, field: "iterations" });
                  }}
                  iterations={iterations}
                  containerOnChange={onChange("iterations")}
                  changeIndividualValues={this.changeIterationsValues}
                  togglePaymentForm={this.togglePaymentForm}
                />
              </ScheduledClasses>
            </>
          )}
        </div>
        {(isLoggedin() || (isNew || editMode)) && (
          <Footer
            isExistingCourse={isExistingCourse}
            published={published}
            id={id}
            author={author}
            type={type}
            image={image}
            initialSyllabus={this.initialSyllabus}
            price={price}
            iterations={iterations}
            syllabus={syllabus}
            deletedSyllabus={deletedSyllabus}
            loading={loading}
            isValidForm={this.isValidForm()}
            onSave={onSave}
            reset={reset}
            refetch={refetch}
            togglePaymentForm={this.togglePaymentForm}
          />
        )}
        {paymentFormVisible && (
          <PaymentPopup
            tags={tags}
            price={price}
            title={title}
            type={type}
            iterations={iterations}
            syllabus={syllabus}
            togglePaymentForm={this.togglePaymentForm}
            refetch={refetch}
          />
        )}
      </>
    );
  }
}

CourseDetails.propTypes = {
  editMode: PropTypes.bool,
  syllabus: PropTypes.instanceOf(Array),
  iterations: PropTypes.instanceOf(Array),
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isNew: PropTypes.bool,
  type: PropTypes.string,
  about: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duration: PropTypes.string,
  difficulty: PropTypes.string,
  hours_per_session: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max_attendees: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
  tags: PropTypes.string,
  onEdit: PropTypes.func,
  onChange: PropTypes.func,
  initialize: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  courseData: PropTypes.instanceOf(Object),
  formValues: PropTypes.instanceOf(Object),
  deletedSyllabus: PropTypes.instanceOf(Object),
  updatedAt: PropTypes.number,
  id: PropTypes.string,
  author: PropTypes.string,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  isExistingCourse: PropTypes.bool,
  published: PropTypes.bool,
  reset: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

CourseDetails.defaultProps = {
  editMode: false,
  isNew: false,
  published: false,
  valid: true,
  courseData: {},
  deletedSyllabus: [],
  onEdit: () => null,
  onChange: () => null,
  updatedAt: null,
  id: null,
  author: null,
  loading: false,
  onSave: () => null,
  isExistingCourse: false,
  ...NEW_COURSE_INITIAL_DATA
};

export default reduxForm({
  form: "courseDetails",
  validate: validateCourseValues
})(
  connect(state => ({
    formValues: { tags: formValueSelector("courseDetails")(state, "tags") }
  }))(CourseDetails)
);
