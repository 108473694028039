/* eslint import/prefer-default-export : off */
import styled from "styled-components";

export const FileCreatorContainer = styled.div`
  padding: 0.5rem 0.8rem;
  padding-left: ${props => (props.depth ? props.depth * 0.8 : 0.8)}rem;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
    color: rgba(255, 255, 255, 0.5);
  }

  input {
    width: 100%;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 5px 8px;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
  }
`;
