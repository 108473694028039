/* eslint import/prefer-default-export : off */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const LAYOUT_CODES = {
  HTML: "text-html-editor",
  NODE: "text-nodejs-editor",
  REACT: "text-react-editor",
  PYTHON: "text-python-editor",
  IMAGE: "text-image-uploader",
  VIDEO: "text-video-uploader",
  TEXT: "text-text-editor",
  COMMON_SANDBOX: "common-sandbox"
};

export const LAYOUTS = [
  {
    label: "Text - HTML/CSS/JS Editor Layout",
    id: LAYOUT_CODES.HTML,
    leftContent: {
      icon: <FontAwesomeIcon icon="align-left" />,
      text: "Text"
    },
    rightContent: {
      icon: <FontAwesomeIcon icon={["fab", "html5"]} />,
      text: "HTML"
    }
  },
  {
    label: "Text - NodeJS Editor Layout",
    id: LAYOUT_CODES.NODE,
    leftContent: {
      icon: <FontAwesomeIcon icon="align-left" />,
      text: "Text"
    },
    rightContent: {
      icon: <FontAwesomeIcon icon={["fab", "node-js"]} />,
      text: "NodeJS"
    }
  },
  {
    label: "Text - ReactJS Editor Layout",
    id: LAYOUT_CODES.REACT,
    leftContent: {
      icon: <FontAwesomeIcon icon="align-left" />,
      text: "Text"
    },
    rightContent: {
      icon: <FontAwesomeIcon icon={["fab", "react"]} />,
      text: "ReactJS"
    }
  },
  {
    label: "Text - Python Editor Layout",
    id: LAYOUT_CODES.PYTHON,
    leftContent: {
      icon: <FontAwesomeIcon icon="align-left" />,
      text: "Text"
    },
    rightContent: {
      icon: <FontAwesomeIcon icon={["fab", "python"]} />,
      text: "Python"
    }
  },
  {
    label: "Text - Image Editor Layout",
    id: LAYOUT_CODES.IMAGE,
    leftContent: {
      icon: <FontAwesomeIcon icon="align-left" />,
      text: "Text"
    },
    rightContent: {
      icon: <FontAwesomeIcon icon="image" />,
      text: "Image"
    }
  },
  {
    label: "Text - Text Editor Layout",
    id: LAYOUT_CODES.TEXT,
    leftContent: {
      icon: <FontAwesomeIcon icon="align-left" />,
      text: "Text"
    },
    rightContent: {
      icon: <FontAwesomeIcon icon="align-left" />,
      text: "Text"
    }
  }
  // {
  //   id: "text-video-uploader",
  //   leftContent: {
  //     icon: <FontAwesomeIcon icon="align-left" />,
  //     text: "Text"
  //   },
  //   rightContent: {
  //     icon: <FontAwesomeIcon icon="video" />,
  //     text: "Video"
  //   }
  // }
];

export const LAYOUT_SANDBOX_TEMPLATES = {
  [LAYOUT_CODES.HTML]: "parcel",
  [LAYOUT_CODES.REACT]: "create-react-app",
  [LAYOUT_CODES.NODE]: null,
  [LAYOUT_CODES.PYTHON]: null,
  [LAYOUT_CODES.TEXT]: null,
  [LAYOUT_CODES.IMAGE]: null
};
