import Careers from "constants/landingCareers";

const initialState = {
  career: Careers[1]
};

export const setCareer = ({ key }) => {
  const career = Careers.find(el => el.key === key);

  return {
    type: "landing.SET_CAREER",
    career: {
      career
    }
  };
};

export const nextCareer = () => ({
  type: "landing.NEXT_CAREER"
});

export const prevCareer = () => ({
  type: "landing.PREV_CAREER"
});

export default (state = initialState, action) => {
  switch (action.type) {
    case "landing.SET_CAREER": {
      const { career } = action;

      return {
        ...state,
        ...career
      };
    }
    case "landing.NEXT_CAREER": {
      const { career } = state;
      const idx = Careers.indexOf(career);

      if (idx > -1) {
        let next = Careers[idx + 1];
        // eslint-disable-next-line prefer-destructuring
        if (!next) next = Careers[0];

        return {
          ...state,
          career: next,
          ...next
        };
      }

      return state;
    }
    case "landing.PREV_CAREER": {
      const { career } = state;
      const idx = Careers.indexOf(career);

      if (idx > -1) {
        let prev = Careers[idx - 1];
        if (!prev) prev = Careers[Careers.length - 1];

        return {
          ...state,
          career: prev,
          ...prev
        };
      }

      return state;
    }
    default:
      return state;
  }
};
