import styled from "styled-components";
import { Row, Col } from "react-grid-system";

import { GRADIENTS } from "theme/colors";

const getContainerLeft = screen => {
  switch (screen) {
    // case "md":
    //   return "-530px";
    // case "sm":
    //   return "-500px";
    // case "xs":
    //   return "-550px";
    // case "lg":
    //   return "-320px";
    default:
      return "75%";
  }
};

export const CatalogContainer = styled.div`
  position: absolute;
  top: 60px;
  left: ${props => getContainerLeft(props.screen)};
  width: 650px;
  height: 450px;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: -10px 10px 15px 0px rgba(27, 243, 254, 0.5);
`;

export const Buttons = styled(Row).attrs({
  align: "center"
})`
  border-bottom: 1px solid rgb(51, 51, 51, 0.25);
  padding: 20px 30px;
  width: calc(100% - 60px);
  margin: 0 !important;
`;

export const Button = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #e06666;
  margin-right: 7px;

  &:nth-of-type(2) {
    background-color: #fee598;
  }

  &:last-of-type {
    background-color: #b5d7a8;
  }
`;

export const CardsContainer = styled(Row)`
  padding: 40px 40px 0;
`;

export const CourseCard = styled(Col)`
  .container {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: -10px 10px 15px 0px rgba(27, 243, 254, 0.5);
    max-width: 140px;

    .thumbnail {
      width: 100%;
      height: 60px;
      ${props => GRADIENTS[props.gradient] || GRADIENTS.blue}
    }

    .content {
      padding: 10px;
      height: 40px;
      font-size: 14px;
    }
  }
`;
