import Sizes from "theme/sizes";
import styled from "styled-components";

import { Container } from "react-grid-system";

export const Wrapper = styled(Container)`
  border-radius: ${Sizes.baseRadius};
  overflow: hidden;
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const LayoutsWrapper = styled.div`
  padding: 20px 10px 20px;
  background-color: rgb(255, 255, 255);
`;

export const Trigger = styled.div`
  cursor: pointer;
  opacity: 1;
  transition: opacity 275ms ease-out;

  span {
    margin-left: 10px;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
    transition: opacity 275ms ease-out;
  }
`;
