import loadable from "loadable-components";

const Blog = loadable(() => import(/* webpackPrefetch: true */ "./Blog"));
const Class = loadable(() => import(/* webpackPrefetch: true */ "./Class"));
const Login = loadable(() => import(/* webpackPrefetch: true */ "./Login"));
const NotFound = loadable(() =>
  import(/* webpackPrefetch: true */ "./NotFound")
);
const Terms = loadable(() => import(/* webpackPrefetch: true */ "./Terms"));
const CourseCatalog = loadable(() =>
  import(/* webpackPrefetch: true */ "./CourseCatalog")
);
const Account = loadable(() => import(/* webpackPrefetch: true */ "./Account"));
const Mentorship = loadable(() =>
  import(/* webpackPrefetch: true */ "./Mentorship")
);
const Certification = loadable(() =>
  import(/* webpackPrefetch: true */ "./Certification")
);
const Bootcamp = loadable(() =>
  import(/* webpackPrefetch: true */ "./Bootcamp")
);
const BootcampApplication = loadable(() =>
  import(/* webpackPrefetch: true */ "./BootcampApplication")
);
const BootcampPayment = loadable(() =>
  import(/* webpackPrefetch: true */ "./BootcampPayment")
);
const OnsiteCourses = loadable(() =>
  import(/* webpackPrefetch: true */ "./OnsiteCourses")
);
const LiveCourseSubscription = loadable(() =>
  import(/* webpackPrefetch: true */ "./LiveCourseSubscription")
);

export {
  Blog,
  Class,
  Login,
  NotFound,
  Terms,
  CourseCatalog,
  Account,
  Mentorship,
  Certification,
  Bootcamp,
  BootcampApplication,
  BootcampPayment,
  OnsiteCourses,
  LiveCourseSubscription
};
