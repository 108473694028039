import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import { isLoggedin } from "api/auth";

import COLORS from "theme/colors";
import IconButton from "../SyllabusEditor/IconButton";

const IterationItem = ({
  children,
  handleDelete,
  handleEdit,
  enrolled,
  isNew,
  editMode
}) => (
  <Container disabled={enrolled}>
    <Content>{children}</Content>
    <ButtonGroup>
      {(isNew || editMode) && (
        <>
          <IconButton
            iconProps={{ icon: faEdit, fixedWidth: true }}
            onClick={handleEdit}
          />
          <IconButton
            iconProps={{ icon: faTrash, fixedWidth: true }}
            onClick={handleDelete}
          />
        </>
      )}
      {enrolled && <div>Enrolled</div>}
    </ButtonGroup>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: fit-content;
  margin: 0.5rem 0;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  ${props =>
    props.isButton &&
    `
    cursor: ${props.disabled ? "initial" : "pointer"};
    transition: all 0.2s ease-in;
    background: ${
      isLoggedin() && props.disabled ? "rgba(0, 0, 0, 0.2)" : "initial"
    };
    ${!props.disabled &&
      props.isButton &&
      `
         &:hover {
          transform: scale(0.985);
          color: ${COLORS.primaryDark};
        }
    `}
  `}
`;

const Content = styled.div`
  flex: 1;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

IterationItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  editMode: PropTypes.bool,
  enrolled: PropTypes.bool
};

IterationItem.defaultProps = {
  isNew: false,
  editMode: false,
  enrolled: false
};

export default IterationItem;
