/* eslint import/prefer-default-export : off */

export const validInput = ({ value, inputType } = {}) => {
  const priceRegex = /^[0-9]+\.?[0-9]*$/;
  const numRegex = /^[0-9]+$/;

  if (!value) return true;

  switch (inputType) {
    case "price": {
      return priceRegex.test(value);
    }
    case "number": {
      return numRegex.test(value);
    }
    default:
      return true;
  }
};
