import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  // Abstract,
  // OnlineCourses,
  // Activities,
  Hero,
  Customers,
  Courses,
  Reasons,
  Advantages,
  Steps,
  CTA,
  // Newsletter,
  // ProgressBar,
  Experience,
  Footer
} from "./components";

class LandingPage extends React.Component {
  timelineRef = React.createRef();

  render() {
    const { career } = this.props;

    return (
      <>
        <Hero career={career.key} color={career.color} />
        <Customers />
        <Courses />
        <Steps />
        <Reasons />
        {/* <ProgressBar /> */}
        <CTA />
        <Advantages />
        <Experience />
        <Footer />
        {/* <Newsletter /> */}
      </>
    );
  }
}

LandingPage.propTypes = {
  career: PropTypes.instanceOf(Object)
};

LandingPage.defaultProps = {
  career: {}
};

export default connect(state => ({
  career: state.landing.career
}))(withRouter(LandingPage));
