/**
 * Updates the course with a published status and
 * will be available for public viewing.
 */

import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import { PUBLISH_COURSE } from "api/mutations/course";
import { GET_COURSE } from "api/queries/course";

import { css } from "styled-components";
import { notify } from "react-notify-toast";
import { getGraphqlError } from "utils/general/errors";

import Colors from "theme/colors";

import Button from "../../../Button";

class PublishCourse extends React.PureComponent {
  onError = error => {
    notify.show(getGraphqlError(error), "error");
  };

  onCompleted = () => {
    notify.show(
      "You have published your course. It is now available in public.",
      "info"
    );
  };

  render() {
    const { id } = this.props;

    return (
      <Mutation
        mutation={PUBLISH_COURSE}
        onError={this.onError}
        onCompleted={this.onCompleted}
        variables={{
          id,
          published: true
        }}
        refetchQueries={[
          {
            query: GET_COURSE,
            variables: {
              id
            }
          }
        ]}
      >
        {(publishCourse, { loading }) => (
          <Button
            size="large"
            title="Publish Course"
            loadingText="Publishing..."
            loading={loading}
            disabled={loading}
            onClick={publishCourse}
            customstyles={css`
              color: ${Colors.primaryDark};
            `}
          />
        )}
      </Mutation>
    );
  }
}

PublishCourse.propTypes = {
  id: PropTypes.string.isRequired
};

export default PublishCourse;
