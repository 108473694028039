import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Colors from "theme/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UnstyledIconButton = ({ iconProps, ...buttonProps }) => (
  <button type="button" {...buttonProps}>
    <FontAwesomeIcon {...iconProps} />
  </button>
);

UnstyledIconButton.propTypes = {
  iconProps: PropTypes.instanceOf(Object)
};

UnstyledIconButton.defaultProps = {
  iconProps: {}
};

const IconButton = styled(UnstyledIconButton)`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 100%;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  & > svg {
    color: ${Colors.primaryDark};
  }
`;

export default IconButton;
