import React from "react";
import Colors from "theme/colors";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Visible } from "react-grid-system";
import { Wrapper } from "./ClassHeaderComponents";

import { Brand } from "../Brand";
import { Popover } from "../Popover";

import UserPopover from "./UserPopover";
import ContentWrapper from "../ContentWrapper";
import Avatar from "../Avatar";

export default connect(state => ({
  id: state.user.id,
  avatar: state.user.picture
}))(({ title = "", id, avatar }) => (
  <Wrapper>
    <ContentWrapper className="content-wrapper" wide>
      <div className="left">
        <Link to="/">
          <Brand small color={Colors.text} />
        </Link>
      </div>
      <Visible xl lg md>
        <div className="title">{title}</div>
      </Visible>
      <div className="right">
        <Visible xl lg>
          <div className="name">
            {id ? (
              ""
            ) : (
              <span>
                <span>Hi Guest, </span>
                <a className="login" href="/login">
                  Login Now!
                </a>
              </span>
            )}
          </div>
        </Visible>
        {id && <Avatar image={avatar} />}
        {id && (
          <Popover body={<UserPopover />}>
            <FontAwesomeIcon icon="angle-down" />
          </Popover>
        )}
      </div>
    </ContentWrapper>
  </Wrapper>
));
