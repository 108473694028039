/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import TimeField from "react-simple-timefield";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { prettyRange } from "utils/general/date";
import Colors from "theme/colors";
import Button from "../Button";
import IconButton from "../SyllabusEditor/IconButton";

class ScheduleClass extends React.Component {
  constructor(props) {
    super(props);

    let time;
    let selectedDays;
    if (props.selectedDays && props.selectedDays.length > 0) {
      selectedDays = props.selectedDays.map(day =>
        typeof day === "string" ? new Date(day) : day
      );

      const hour = selectedDays[0].getHours();
      const minute = selectedDays[0].getMinutes();

      // eslint-disable-next-line
      time = `${hour}:${minute < 10 ? "0" + minute : minute}`;
    }

    this.state = {
      time: time || "12:00",
      selectedDays: selectedDays || []
    };
  }

  handleTimeChange = (event, time) => {
    this.setState(state => {
      const days = [...state.selectedDays];
      days.forEach(day => {
        const timeSplit = time.split(":");
        day.setHours(Number(timeSplit[0]), Number(timeSplit[1]));
      });

      return { time, selectedDays: days };
    });
  };

  handleDayClick = day => {
    const { selectedDays } = this.state;

    const selectedIndex = selectedDays.findIndex(selectedDay =>
      DateUtils.isSameDay(selectedDay, day)
    );
    if (selectedIndex > -1) selectedDays.splice(selectedIndex, 1);
    else selectedDays.push(day);

    this.setState({ selectedDays });
  };

  handleScheduleClick = () => {
    const { selectedDays } = this.state;
    const { handleChange, hidePopup } = this.props;

    handleChange(selectedDays);
    hidePopup();
  };

  render() {
    const { time, selectedDays } = this.state;
    const { hidePopup } = this.props;

    return (
      <Background>
        <Popup>
          <ExitButton>
            <IconButton
              iconProps={{ icon: faTimes, fixedWidth: true }}
              onClick={hidePopup}
            />
          </ExitButton>
          <Header>Schedule a Class</Header>
          <DayPicker
            selectedDays={selectedDays}
            onDayClick={this.handleDayClick}
          />
          <TimeField value={time} onChange={this.handleTimeChange} />
          <div className="info">
            (Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone})
          </div>
          {selectedDays && selectedDays.length > 0 && (
            <TimePreview>{prettyRange(selectedDays, time)}</TimePreview>
          )}
          <Button title="Schedule" onClick={this.handleScheduleClick} />
        </Popup>
      </Background>
    );
  }
}

const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const TimePreview = styled.div`
  width: 100%;
  padding: 10px;
  background: #c1c1c1;
  border-radius: 5px;
  margin-bottom: 5px;
`;

const Header = styled.h2`
  padding-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
`;

const Popup = styled.div`
  position: relative;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  background: ${Colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  align-items: center;
  padding: 1rem 1.5rem;

  input {
    width: 5rem !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 700;
  }

  .info {
    color: ${Colors.onBackgroundLowOpacity};
    margin: 5px 0;
  }
`;

export const ExitButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
`;

ScheduleClass.propTypes = {
  selectedDays: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func.isRequired,
  hidePopup: PropTypes.func.isRequired
};

ScheduleClass.defaultProps = {
  selectedDays: undefined
};

export default ScheduleClass;
