import styled from "styled-components";
import Dropzone from "react-dropzone";
import Colors from "theme/colors";
import FontSizes from "theme/fontsizes";

export const Wrapper = styled.div`
  min-height: 500px;
`;
export const Header = styled.div`
  height: 38px;
  line-height: 38px;
  padding: 0 10px;
  background-color: ${Colors.codeEditorHeader};
  color: ${Colors.onPrimary};
  font-size: ${FontSizes.smallBase};
  font-weight: bold;

  svg {
    margin-right: 5px;
  }
`;

export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: calc(100% - 38px);
  width: 100%;

  ${props =>
    props.background &&
    `
    background: url(${props.background}) no-repeat center center;
  `}

  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : "cover"};
`;

export const StyledDropzone = styled(Dropzone)`
  cursor: pointer;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #bbb;
  color: ${Colors.onBackgroundSecondary};
`;

export const TriggerWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const VideoPreview = styled.video.attrs({
  controls: true
})`
  width: 100%;
  height: 100%;
`;
