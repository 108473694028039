/* eslint import/prefer-default-export : off */
import { COURSE_TYPES } from "constants/course";

export const validateCourseValues = values => {
  const errors = {};
  const { syllabus } = values;

  let index = 0;
  const treeIndexes =
    (syllabus &&
      syllabus.reduce((prev, next) => {
        prev.push(index);
        index += 1;

        if (next.children) {
          next.children.map(child => {
            prev.push(index);
            index += 1;
            return child;
          });
        }

        return prev;
      }, [])) ||
    [];

  if (!values.title) {
    errors.title = "Please indicate the title of your course.";
  }

  if (!values.about) {
    errors.about =
      "Please provide a short course description/overview about your course.";
  }

  treeIndexes.map(treeIndex => {
    if (!values[`syllabus-field-${treeIndex}`]) {
      errors[`syllabus-field-${treeIndex}`] = "Provide a title of your lesson.";
      errors.syllabus = "Please don't leave any empty values of your lessons.";
    }
    return treeIndex;
  });

  if (values.type === COURSE_TYPES.GUIDED_LEARNING) {
    if (!Array.isArray(values.iterations)) {
      errors.iterations = "Please create at least one iteration.";
    }

    if (!Number(values.price) > 0) {
      errors.price = "Please enter a price more than 0.";
    }
  }

  return errors;
};
