import React, { Component } from "react";
import PropTypes from "prop-types";
import Popover from "react-popover";
import "./style.css";

class CustomPopover extends Component {
  state = { show: false };

  toggle = () => {
    const { show } = this.state;

    this.setState({ show: !show });
  };

  hide = () => {
    const { show } = this.state;

    if (show) this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    const { children, ...props } = this.props;
    const childrenWithClick = React.cloneElement(children, {
      onClick: this.toggle
    });

    return (
      <Popover isOpen={show} onOuterAction={this.hide} {...props}>
        {childrenWithClick}
      </Popover>
    );
  }
}

CustomPopover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default CustomPopover;
