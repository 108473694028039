/* eslint import/prefer-default-export : off */
import styled from "styled-components";
import COLORS from "theme/colors";

export const Form = styled.form`
  position: relative;
  border: 1px solid ${COLORS.black};
  border-right: none;
  border-radius: 5px;
`;

export const Field = styled.input.attrs({
  name: "searchfield"
})`
  border: none;
  outline: none;
  font-weight: 600;
  color: rgb(51, 51, 51);
  font-size: ${props => (["xs"].includes(props.screen) ? "0.8rem" : "16px")};
  background-color: transparent;
  padding: 15px 20px;
  padding-left: ${props => (["xs"].includes(props.screen) ? "15px" : "20px")};
  padding-right: ${props => (["xs"].includes(props.screen) ? "60px" : "0px")};
  width: calc(
    100% - ${props => (["xs"].includes(props.screen) ? "60px" : "40px")}
  );

  ${props => props.inputstyle}
`;

export const SearchHint = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.4);

  button {
    height: 100%;
    border-radius: 5px;
    background: ${COLORS.black};

    svg {
      color: rgb(255, 255, 255);
    }
  }
`;
