const xs = "320";
const sm = "576";
const md = "768";
const lg = "992";
const xl = "1200";
const xxl = "1500";

export { xs, sm, md, lg, xl, xxl };

export default { xs, sm, md, lg, xl, xxl };

// import { xs, sm, md, lg, xl, xxl } from "theme/breakpoints";
