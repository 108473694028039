import React from "react";
import { ScreenClassRender } from "react-grid-system";

import {
  Section,
  SectionHeadline,
  SectionBody,
  CompanyContainer,
  CompanyImage,
} from "./styledElements";

const bwImg = require("assets/images/bwater.png");
const dbImg = require("assets/images/db.png");
const noImg = require("assets/images/nomura.png");
const usImg = require("assets/images/usa.png");
const amImg = require("assets/images/amagi.png");
const axaImg = require("assets/images/axa.png");

const Experience = () => (
  <ScreenClassRender
    render={screen => (
      <Section>
        <SectionHeadline>Our Industry Experience</SectionHeadline>
        <SectionBody>
          <CompanyContainer>
            <CompanyImage src={bwImg} screen={screen} alt="Bridgewater Associates" />
          </CompanyContainer>
          <CompanyContainer>
            <CompanyImage src={dbImg} screen={screen} alt="Deutsche Bank" />
          </CompanyContainer>
          <CompanyContainer>
            <CompanyImage src={noImg} screen={screen} alt="Nomura" />
          </CompanyContainer>
          <CompanyContainer>
            <CompanyImage src={usImg} screen={screen} alt="US Political Parties" />
          </CompanyContainer>
          <CompanyContainer>
            <CompanyImage src={amImg} screen={screen} alt="AMAGI" />
          </CompanyContainer>
          <CompanyContainer>
            <CompanyImage src={axaImg} screen={screen} alt="AXA" />
          </CompanyContainer>
        </SectionBody>
      </Section>
    )}
  />
);

export default Experience;
