import { wait } from "./timers";

const PARTICLE_COLORS = [
  ["rgba(27, 243, 254, 1)", "rgba(51, 205, 246, 1)"],
  ["rgba(130, 0, 251, 1)", "rgba(225, 0, 248, 1)"],
  ["rgba(255, 71, 136, 1)", "rgba(255, 154, 73, 1)"]
];
const BUBBLES_COUNT = 20;

const scrollToSection = section => {
  const element = document.querySelector(section);

  if (element) {
    element.scrollIntoView({
      behavior: "smooth"
    });
  }
};

const renderParticles = (particles, ctx, width, height) => {
  requestAnimationFrame(() => renderParticles(particles, ctx, width, height));
  ctx.clearRect(0, 0, width, height);

  particles.forEach(p => {
    const particle = p;
    particle.x +=
      particle.speed * Math.cos((particle.rotation * Math.PI) / 180);
    particle.y +=
      particle.speed * Math.sin((particle.rotation * Math.PI) / 180);

    particle.opacity -= 0.01;
    particle.speed *= particle.friction;
    particle.radius *= particle.friction;
    particle.yVel += particle.gravity;
    particle.y += particle.yVel;

    if (particle.opacity < 0 || particle.radius < 0) return;

    const [color1, color2] = particle.color;
    const gradient = ctx.createLinearGradient(0, 0, 0, 170);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    ctx.beginPath();
    ctx.globalAlpha = particle.opacity;
    ctx.fillStyle = gradient;
    ctx.arc(particle.x, particle.y, p.radius, 0, 2 * Math.PI, false);
    ctx.fill();
  });

  return ctx;
};

const randomRadius = (a, b, c) =>
  parseFloat(
    (Math.random() * ((a || 1) - (b || 0)) + (b || 0)).toFixed(c || 0)
  );

const explode = (x, y) => {
  const particles = [];
  const ratio = window.devicePixelRatio;
  const c = document.createElement("canvas");
  const ctx = c.getContext("2d");

  c.style.position = "absolute";
  c.style.left = `${x - 100}px`;
  c.style.top = `${y - 100}px`;
  c.style.pointerEvents = "none";
  c.style.width = "200px";
  c.style.height = "200px";
  c.style.zIndex = 100;
  c.width = 200 * ratio;
  c.height = 200 * ratio;
  document.body.appendChild(c);

  for (let i = 0; i < BUBBLES_COUNT; i += 1) {
    particles.push({
      x: c.width / 2,
      y: c.height / 2,
      radius: randomRadius(10, 13),
      color:
        PARTICLE_COLORS[Math.floor(Math.random() * PARTICLE_COLORS.length)],
      rotation: randomRadius(0, 360, true),
      speed: randomRadius(8, 12),
      friction: 0.9,
      opacity: randomRadius(0, 0.5, true),
      yVel: 0,
      gravity: 0.1
    });
  }

  renderParticles(particles, ctx, c.width, c.height);
  wait(() => document.body.removeChild(c), 1000);
};

export { scrollToSection, explode };
