/* eslint-disable import/no-cycle */
import React from "react";
import Colors from "theme/colors";
import styled from "styled-components";
import { Link as UnstyledLink } from "react-router-dom";
import { md } from "theme/breakpoints";
import Links from "../Header/Links";
import { Brand } from "../../Brand";

export default () => (
  <Container>
    <Gradient />
    <Links withSocial color={Colors.text} />
    <Link to="/">
      <Brand noBackground />
    </Link>
  </Container>
);

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: 20px 0 50px;
  background-color: ${Colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Link = styled(UnstyledLink)`
  @media (min-width: ${md}px) {
    margin-top: 10px;
  }
`;
