/* eslint import/prefer-default-export : off */
import styled from "styled-components";
import { Row } from "react-grid-system";

export const FooterContainer = styled.div`
  position: fixed !important;
  bottom: 0;
  left: 0;
  padding: 16px 0;
  background-color: inherit;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  width: 100%;
  z-index: 5;
`;

export const ButtonsContainer = styled(Row).attrs({
  align: "center"
})`
  button {
    margin: 0 10px;
  }
`;
