import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div.attrs({
  className: "hidden-font"
})`
  opacity: 0;
  ${props => `transition: opacity ${props.duration}s ease-in ${props.delay}s;`}

  ${props => props.additionalStyles}
`;

const FadeIn = ({ duration, delay, children, additionalStyles }) => (
  <Wrapper
    duration={duration}
    delay={delay}
    additionalStyles={additionalStyles}
  >
    {children}
  </Wrapper>
);

FadeIn.propTypes = {
  duration: PropTypes.number,
  delay: PropTypes.number,
  additionalStyles: PropTypes.string
};

FadeIn.defaultProps = {
  duration: 0.5,
  delay: 0.3,
  additionalStyles: ""
};

export default FadeIn;
