import styled from "styled-components";
import Colors from "theme/colors";

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StepLink = styled.div`
  cursor: pointer;
  opacity: 0.8;
`;

export const StepName = styled.h3`
  font-weight: bold;
`;

export const StepContainer = styled.div`
  margin: 2rem 0rem;

  h4 {
    margin-bottom: 0rem;
    opacity: 0.6;
  }

  p {
    margin: 0;
  }
`;

export const StepTime = styled.div`
  margin-bottom: 0.8rem;
`;

export const Barline = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 1px;
  margin: 2rem 0;
  background: ${props => props.color};
`;

export const Bar = styled.div`
  position: absolute;
  top: -0.75rem;
  border-radius: 30px;
  height: 1.5rem;
  width: ${props => props.progress}%;
  background: linear-gradient(
    45deg,
    ${props => props.gradient[0]},
    ${props => props.gradient[1]}
  );
  transition: width 0.2s ease-in;
`;

export const WeekText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StepBody = styled.div`
  margin-top: 3rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 1rem 0;
  padding-left: 5%;
  padding-right: 10%;
`;

export const SectionName = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${Colors.onBackgroundLowOpacity};
`;

export const SectionHeadline = styled.h2`
  font-size: 3rem;
  font-weight: bold;
`;

export const SectionBody = styled.div`
  color: ${Colors.text};
  text-align: left;
  margin-top: 1rem;

  hr {
    margin: 2rem 0;
    width: 50%;
    height: 1px;
    border: none;
    background: #212121;
    opacity: 0.3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       h2 {
         font-size: 2rem;   
       }
       text-align: center;

       hr {
         margin: 0 auto;
         margin-bottom: 1rem;
       }
    `}
`;
