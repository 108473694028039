import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, CloseContainer } from "./styledElements";

class Tag extends React.PureComponent {
  onClose = e => {
    e.stopPropagation();

    const { onClose } = this.props;
    onClose();
  };

  onTagClick = e => {
    e.stopPropagation();

    const { tagClicked } = this.props;

    tagClicked(e);
  };

  render() {
    const { label, background, closable } = this.props;

    return (
      <Container
        ref={instance => {
          this.container = instance;
        }}
        background={background}
        closable={closable}
        onClick={this.onTagClick}
      >
        <div>{label}</div>
        {closable && (
          <CloseContainer onClick={this.onClose}>
            <FontAwesomeIcon icon="times" />
          </CloseContainer>
        )}
      </Container>
    );
  }
}

Tag.propTypes = {
  label: PropTypes.string,
  background: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  closable: PropTypes.bool,
  tagClicked: PropTypes.func
};

Tag.defaultProps = {
  label: "",
  onClose: () => null,
  closable: true,
  tagClicked: () => {}
};

export default Tag;
