/* eslint no-console : off */
import React from "react";
import PropTypes from "prop-types";
import SplitterLayout from "react-splitter-layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ActionsContainer, OutputContainer } from "./styledElements";
import CodeEditor from "../../CodeEditor";
import PythonSandboxHelmet from "./PythonSandboxHelmet";
import Button from "../../../Button";

import "./Python.css";
import "brace/mode/python";

const Scripts = props => {
  const { code } = props;
  return <script type="text/python">{code}</script>;
};

const output = arr => {
  let out = "";
  for (let i = 0; i < arr.length; i += 1) {
    if (i !== arr.length - 1) {
      out = out.concat(`${arr[i]}\n`);
    } else {
      out = out.concat(arr[i]);
    }
  }
  return out;
};

class PythonSandbox extends React.Component {
  logs = [];

  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      code: value || "",
      outputArr: [],
      secondarypanesize: 250
    };

    console.oldLog = console.log;

    console.log = log => {
      if (log !== "using indexedDB for stdlib modules cache") {
        this.logs.push(`${log}`);
      }
    };
  }

  componentDidUpdate = prevProps => {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.onChange(value);
    }
  };

  brython = async () => {
    await window.brython();
  };

  runBrython = () => {
    this.logs = [];
    try {
      this.brython();
    } catch (error) {
      console.oldLog(error);
    }
  };

  updateState = inputValue => {
    this.setState({
      outputArr: inputValue
    });
    this.logs = [];
  };

  onChange = code => {
    this.setState({
      code
    });
  };

  run = () => {
    this.runBrython();
    setTimeout(() => {
      this.updateState(this.logs);
    }, 100);
  };

  clearLogs = () => {
    this.logs = [];

    this.setState({
      outputArr: []
    });
  };

  getValue = () => {
    return this.editor.getValue();
  };

  setSecondaryPaneSize = secondarypanesize =>
    this.setState({ secondarypanesize });

  render() {
    const { code, outputArr, secondarypanesize } = this.state;

    return (
      <>
        <PythonSandboxHelmet />
        <Scripts code={code} />
        <SplitterLayout
          onSecondaryPaneSizeChange={this.setSecondaryPaneSize}
          vertical
          secondaryInitialSize={250}
        >
          <CodeEditor
            ref={instance => {
              this.editor = instance;
            }}
            value={code}
            mode="python"
            height={`calc(100vh + 35px - 152px - ${secondarypanesize}px)`}
            onChange={this.onChange}
          />
          <OutputContainer>
            <ActionsContainer>
              <Button
                title={<FontAwesomeIcon icon="trash-alt" />}
                type="danger"
                size="extra-small"
                onClick={this.clearLogs}
              />
              <Button
                title={<FontAwesomeIcon icon="play" />}
                size="extra-small"
                onClick={this.run}
              />
            </ActionsContainer>
            <pre id="test">{output(outputArr)}</pre>
          </OutputContainer>
        </SplitterLayout>
      </>
    );
  }
}

Scripts.propTypes = {
  code: PropTypes.string.isRequired
};

PythonSandbox.propTypes = {
  value: PropTypes.string
};

PythonSandbox.defaultProps = {
  value: ""
};

export default PythonSandbox;
