export const flatSyllabus = ({ syllabus = [], includeId } = {}) =>
  syllabus.reduce((prev, next, idx) => {
    let section = {
      type: next.layoutCode,
      position: idx,
      order_index: idx,
      depth: 0,
      markdown: next.markdown || "",
      title: next.title,
      media: next.media || ""
    };

    if (includeId) {
      section = {
        ...section,
        id: next.id
      };
    }

    prev.push(section);

    if (next.children) {
      next.children.map((child, childIdx) => {
        let childSection = {
          type: child.layoutCode,
          position: idx,
          order_index: childIdx,
          depth: 1,
          markdown: child.markdown || "",
          title: child.title,
          media: child.media || ""
        };

        if (includeId) {
          childSection = {
            ...childSection,
            id: child.id
          };
        }

        prev.push(childSection);
        return child;
      });
    }
    return prev;
  }, []);

export const sortSyllabus = ({ syllabus = [] } = []) => {
  return syllabus
    .map(entry => ({ ...entry }))
    .sort((prev, next) => {
      if (parseInt(prev.orderIndex, 10) - parseInt(next.orderIndex, 10) < 0) {
        return -1;
      }

      if (parseInt(prev.orderIndex, 10) - parseInt(next.orderIndex, 10) > 0) {
        return 1;
      }

      return 0;
    });
};

export const expandSyllabus = ({ syllabus = [] } = {}) =>
  sortSyllabus({
    syllabus: syllabus
      .filter(entry => entry.depth === 0)
      .reduce((prev, next) => {
        prev.push({
          id: parseInt(next.id, 10),
          position: next.position,
          orderIndex: next.order_index,
          depth: next.depth,
          title: next.title,
          expanded: true,
          layoutCode: next.type,
          markdown: next.markdown || "",
          media: next.media || "",
          children: sortSyllabus({
            syllabus: syllabus
              .filter(
                entry => entry.position === next.position && entry.depth === 1
              )
              .map(entry => ({
                id: parseInt(entry.id, 10),
                position: entry.position,
                orderIndex: entry.order_index,
                depth: entry.depth,
                title: entry.title,
                expanded: true,
                layoutCode: entry.type,
                markdown: entry.markdown || "",
                media: entry.media || ""
              }))
          })
        });

        return prev;
      }, [])
  });

export const getSyllabusUpdate = ({
  syllabus = [],
  initialSyllabus = []
} = {}) => {
  const creates = [];
  const updates = [];

  syllabus.map((section, sectionIndex) => {
    const sectionObject = {
      type: section.layoutCode,
      position: sectionIndex,
      orderIndex: sectionIndex,
      depth: 0,
      markdown: section.markdown || "",
      media: section.media || "",
      title: section.title
    };

    if (!section.id) {
      creates.push(sectionObject);
    } else {
      const initialValue = initialSyllabus.find(
        initial => initial.id === section.id
      );

      if (initialValue.type !== section.layoutCode) {
        sectionObject.media = "";
      }

      updates.push({
        ...sectionObject,
        id: section.id
      });
    }

    if (section.children && Array.isArray(section.children)) {
      section.children.map((subSection, subIndex) => {
        const subSectionObject = {
          type: subSection.layoutCode,
          position: sectionIndex,
          orderIndex: subIndex,
          depth: 1,
          markdown: subSection.markdown || "",
          media: subSection.media || "",
          title: subSection.title
        };

        if (!subSection.id) {
          creates.push(subSectionObject);
        } else {
          const initialValue = initialSyllabus.find(
            initial => initial.id === subSection.id
          );

          if (initialValue.type !== subSection.layoutCode) {
            subSectionObject.media = "";
          }

          updates.push({
            ...subSectionObject,
            id: subSection.id
          });
        }

        return subSection;
      });
    }

    return section;
  });

  return {
    creates,
    updates
  };
};
