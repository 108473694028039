import CUSTOM_ERROR_CODES from "constants/customErrorCodes";

const ERROR_DESCRIPTIONS = {
  "23503":
    "You need to sign up to Amagi Academy before enrolling in this course. You can do it, we believe in you.",
  "400":
    "You're trying to access something that doesn't exist. Kudos for creativity.",
  unavailable: "We can't hear you. Is your internet on?",
  unknown:
    "You clicked something and it found its way to the negative zone. Believe really hard and try again.",

  [CUSTOM_ERROR_CODES.UPLOAD_ERROR_400]:
    "That kind of file will break things. You don't want to break things do you? Please upload a valid file.",
  [CUSTOM_ERROR_CODES.UPLOAD_ERROR_413]:
    "We prefer files smaller than the size of a planet. Our upload limit is only 10MB.",
  [CUSTOM_ERROR_CODES.UPLOAD_ERROR_GEN]:
    "You've uploaded something that caused an unknown error. Please try again, this time with feeling.",
  [CUSTOM_ERROR_CODES.UPLOAD_ERROR_500]:
    "Apparently the system is having trouble digesting its files. We'll get the plunger.",

  [CUSTOM_ERROR_CODES.FETCH_COURSE_ERROR]:
    "Ran out of magic fairy dust. Please try again.",
  [CUSTOM_ERROR_CODES.FETCH_COURSES_ERROR]:
    "Ran out of deuterium reserves. Please try again."
};

const getErrorDescription = code => {
  if (!ERROR_DESCRIPTIONS[code]) return ERROR_DESCRIPTIONS.unknown;

  return ERROR_DESCRIPTIONS[code];
};

export const getGraphqlError = error => {
  if (!error) return null;
  if (!error.message) return null;

  /* check first if no connection or failed connection */
  if (error.message.match("Failed to fetch")) {
    return getErrorDescription("unavailable");
  }

  /* check if data is corrupted */
  if (error.message.match("400")) return getErrorDescription("400");

  return error.message.replace("GraphQL error: ", "");
};

export const getUploadError = error => {
  if (!error) return null;

  return getErrorDescription(error);
};
