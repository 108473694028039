import React from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";
import Colors from "theme/colors";
import Sizes from "theme/sizes";

import { LAYOUTS } from "constants/layout";

import { Container, Row, Col, Visible } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, LayoutsWrapper, Trigger } from "./LayoutSelectorComponents";
import { Footer, Header, Layout } from "./components";

class LayoutSelector extends React.PureComponent {
  static defaultProps = {
    onChange: () => {}
  };

  state = {
    showLayoutSelector: false,
    selectedLayout: LAYOUTS[0].id
  };

  showLayoutSelector = ({ currentLayout } = {}) => {
    this.setState({ showLayoutSelector: true });

    const { selectedLayout } = this.state;

    if (currentLayout && currentLayout !== selectedLayout) {
      this.selectLayout({
        selectedLayout: currentLayout
      });
    }
  };

  hideLayoutSelector = () => this.setState({ showLayoutSelector: false });

  selectLayout = ({ selectedLayout } = {}) => {
    this.setState({ selectedLayout });
  };

  applyLayout = () => {
    const { selectedLayout } = this.state;
    const { onChange } = this.props;

    onChange(selectedLayout);
    this.hideLayoutSelector();
  };

  render() {
    const { showLayoutSelector, selectedLayout } = this.state;
    const { noTrigger, section } = this.props;

    const selectedLayoutLabel = LAYOUTS.find(
      layout => layout.id === selectedLayout
    ).label;
    return [
      <Modal
        key="selector-modal"
        center
        showCloseIcon={false}
        open={showLayoutSelector}
        onClose={this.hideLayoutSelector}
        styles={{
          modal: {
            background: Colors.secondaryBackground,
            padding: 0,
            borderRadius: Sizes.baseRadius
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.6)"
          }
        }}
      >
        <Wrapper fluid>
          <Header />
          <Row nogutter>
            <Col>
              <LayoutsWrapper>
                <Container fluid>
                  <Row>
                    {LAYOUTS.map(layout => (
                      <Col
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        key={`${layout.id}`}
                      >
                        <Layout
                          onSelect={() =>
                            this.selectLayout({ selectedLayout: layout.id })
                          }
                          selected={selectedLayout === layout.id}
                          {...layout}
                        />
                      </Col>
                    ))}
                  </Row>
                </Container>
              </LayoutsWrapper>
              <Footer
                layoutLabel={selectedLayoutLabel}
                onApply={this.applyLayout}
                onCancel={this.hideLayoutSelector}
              />
            </Col>
          </Row>
        </Wrapper>
      </Modal>,
      <React.Fragment key="trigger-selector">
        {!noTrigger && (
          <Trigger
            onClick={() =>
              this.showLayoutSelector({ currentLayout: section.type })
            }
          >
            <FontAwesomeIcon icon="cubes" />
            <Visible xl lg md sm>
              <span>Change Layout</span>
            </Visible>
          </Trigger>
        )}
      </React.Fragment>
    ];
  }
}

LayoutSelector.propTypes = {
  onChange: PropTypes.func,
  noTrigger: PropTypes.bool,
  section: PropTypes.instanceOf(Object)
};

LayoutSelector.defaultProps = {
  onChange: () => null,
  noTrigger: false,
  section: {}
};

export default LayoutSelector;
