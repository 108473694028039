import styled from "styled-components";

export const SubscribeNewsletter = styled.div`
  // box-shadow: inset 0px 10px 0px 0px rgb(22, 154, 185);
  background-color: #03acff;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 50px 20px;
`;

export const NewsletterHeadline = styled.div`
  color: rgb(35, 91, 104);
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
`;

export const NewsletterByline = styled.div`
  color: rgb(35, 91, 104);
  font-size: 0.8rem;
  margin-bottom: 1rem;
`;

export const SubscribeForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    margin-bottom: 0;
    margin: 5px;
  }

  input {
    border: none;
  }
`;
