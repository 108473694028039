import React from "react";
import Helmet from "react-helmet";

/* eslint-disable react/prefer-stateless-function */
class PythonSandboxHelmet extends React.PureComponent {
  render() {
    return (
      <Helmet>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/brython/3.7.1/brython.min.js"
        />
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/brython/3.7.1/brython_stdlib.js"
        />
      </Helmet>
    );
  }
}

export default PythonSandboxHelmet;
