import styled from "styled-components";

const DetailLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: rgb(0, 0, 0, 0.5);
  margin-bottom: 10px;
`;

export default DetailLabel;
