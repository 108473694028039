import React from "react";
import PropTypes from "prop-types";

import File from "../File";
import ModuleList from "../ModuleList";
import FileCreator from "../FileCreator";

class Directory extends React.Component {
  state = {
    open: true
  };

  showFileCreator = event => {
    this.fileCreator.showFileCreator(event);
  };

  hideFileCreator = () => {
    this.fileCreator.hideFileCreator();
  };

  toggleOpen = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const {
      prefixedPath,
      files,
      selectFile,
      openedPath,
      depth,
      createFile,
      deleteFile
    } = this.props;

    return (
      <div key={prefixedPath}>
        <File
          isDirectory
          showFileCreator={this.showFileCreator}
          isOpen={open}
          depth={depth}
          onClick={this.toggleOpen}
          path={`${prefixedPath}/`}
        />
        {open && (
          <ModuleList
            prefixedPath={prefixedPath}
            files={files}
            selectFile={selectFile}
            openedPath={openedPath}
            depth={depth}
            createFile={createFile}
            deleteFile={deleteFile}
          />
        )}

        <FileCreator
          ref={instance => {
            this.fileCreator = instance;
          }}
          depth={depth}
          createFile={createFile}
          prefixedPath={prefixedPath}
        />
      </div>
    );
  }
}

Directory.propTypes = {
  prefixedPath: PropTypes.string,
  files: PropTypes.instanceOf(Array),
  selectFile: PropTypes.func,
  openedPath: PropTypes.string,
  depth: PropTypes.string,
  createFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired
};

Directory.defaultProps = {
  prefixedPath: "",
  files: [],
  selectFile: () => null,
  openedPath: "",
  depth: ""
};

export default Directory;
