import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import COLORS from "theme/colors";

import { Wrapper, Error, Warning } from "./common";

const CustomSelect = ({
  input,
  options,
  name,
  meta: { touched, error, warning } = {},
  ...props
}) => {
  console.log("Select value:", input.value);
  let borderColor;

  if (touched && error) borderColor = "rgba(200, 0, 0, 0.6)";
  else if (touched && warning) borderColor = "rgba(100, 65, 0, 0.6)";
  else borderColor = COLORS.black;

  const customStyles = {
    container: provided => ({
      ...provided,
      position: "relative",
      width: "calc(100% - 28px)"
    }),
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${borderColor}`,
      boxShadow: state.isFocused && "0px 0px 10px rgba(0, 0, 0, .1)",
      "&:hover, &:focus": {
        border: `1px solid ${borderColor}`,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, .1)"
      },
      borderRadius: "5px",
      transition: "border-color 275ms ease-out, box-shadow 275ms ease-in"
    })
  };

  return (
    <Wrapper>
      <Select
        {...input}
        id={input.name}
        name={name}
        options={options}
        value={options.filter(opt => opt.value === input.value)}
        onChange={e => input.onChange(e.value)}
        onFocus={() => input.onFocus(input.value)}
        onBlur={() => input.onBlur(input.value)}
        styles={customStyles}
        {...props}
      />
      {touched &&
        ((error && <Error>{error}</Error>) ||
          (warning && <Warning>{warning}</Warning>))}
    </Wrapper>
  );
};

CustomSelect.propTypes = {
  input: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  id: PropTypes.string
};

CustomSelect.defaultProps = {
  input: {},
  options: {},
  name: undefined,
  id: undefined
};

export default CustomSelect;
