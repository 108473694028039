import styled from "styled-components";
import Colors from "theme/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
`;

export const Page = styled.div`
  padding: 3px;
  width: 28px;
  margin: 0 2px;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;
  color: ${props => (props.isActive ? Colors.onPrimary : Colors.onBackground)};
  border-radius: 4px;
  background-color: ${props =>
    props.isActive ? Colors.primary : "transparent"};

  &:hover {
    cursor: pointer;
    background-color: ${props =>
      props.isActive ? Colors.primary : "rgba(0, 0, 0, 0.075)"};
  }
`;
