import styled from "styled-components";
import FontSizes from "theme/fontsizes";
import Colors from "theme/colors";

const SectionLabel = styled.p`
  font-size: ${FontSizes.description};
  color: ${Colors.onBackground};
  font-weight: 600;
  margin: 0 0 20px;
`;

export default SectionLabel;
