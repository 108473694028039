import React from "react";
import PropTypes from "prop-types";

import { ScreenClassRender } from "react-grid-system";
import { css } from "styled-components";
import { explode } from "utils/general/ui";
import { Container, ExternalLink } from "./styledElements";

class LinkButton extends React.PureComponent {
  onMouseOver = e => explode(e.pageX, e.pageY);

  defaultFunction = () => null;

  render() {
    const {
      label,
      size,
      indented,
      containerStyle,
      to,
      isExternal,
      href,
      ...props
    } = this.props;

    return (
      <ScreenClassRender
        render={screen => {
          if (!isExternal) {
            return (
              <Container
                size={size}
                indented={indented ? indented.toString() : undefined}
                customstyles={containerStyle}
                screen={screen}
                to={to}
                onMouseOver={this.onMouseOver}
                onFocus={this.defaultFunction}
              >
                {label}
              </Container>
            );
          }

          return (
            <ExternalLink
              size={size}
              indented={indented ? indented.toString() : undefined}
              customstyles={containerStyle}
              screen={screen}
              href={href}
              onMouseOver={this.onMouseOver}
              onFocus={this.defaultFunction}
              {...props}
            >
              {label}
            </ExternalLink>
          );
        }}
      />
    );
  }
}

LinkButton.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  indented: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(css)
  ]),
  isExternal: PropTypes.bool,
  href: PropTypes.string
};

LinkButton.defaultProps = {
  to: "/",
  label: "",
  size: "small",
  containerStyle: {},
  indented: false,
  isExternal: false,
  href: ""
};

export default LinkButton;
