import Colors, { GRADIENTS } from "theme/colors";
import Sizes from "theme/sizes";
import styled from "styled-components";

export const Wrapper = styled.div`
  flex: 1;
  height: 120px;
  margin-bottom: 15px;
  border-radius: ${Sizes.baseRadius};
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
`;

export const Header = styled.div`
  height: 20px;
  line-height: 18px;
  padding: 3px 7px;
  font-size: 10px;
  border-top-left-radius: ${Sizes.baseRadius};
  border-top-right-radius: ${Sizes.baseRadius};
  background-color: ${Colors.onBackgroundSecondary};
  color: ${Colors.onPrimary};

  ${props => props.selected && GRADIENTS.orange}
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 13px;
  color: ${props =>
    !props.selected ? Colors.onBackgroundSecondary : Colors.primaryVariant};

  &.right {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  svg {
    margin-bottom: 5px;
    font-size: 18px;
  }
`;
