import React from "react";
import Bounce from "react-reveal/Bounce";

import { css } from "styled-components";
import { ActivitiesContainer } from "./styledElements";

import LinkButton from "../LinkButton";

const Activities = () => (
  <ActivitiesContainer>
    <Bounce>
      <LinkButton
        to="/"
        size="big"
        label="IN-PERSON OPPORTUNITIES"
        containerStyle={css`
          margin-left: 0%;
        `}
      />
    </Bounce>
    <Bounce delay={250}>
      <LinkButton
        isExternal="true"
        href="https://www.facebook.com/pg/AmagiAcademy/groups/?ref=page_internal"
        label="MEETUP COMMUNITIES"
        containerStyle={css`
          margin-left: -30%;
        `}
      />
    </Bounce>
    <Bounce delay={500}>
      <LinkButton
        isExternal="true"
        href="https://meetup.com/AmagiAcademy/"
        label="LIVE WORKSHOPS"
        containerStyle={css`
          margin-left: 40%;
        `}
      />
    </Bounce>
    <Bounce delay={750}>
      <LinkButton
        to="/"
        label="MORE TO BE ANNOUNCED"
        containerStyle={css`
          margin-left: 0%;
        `}
      />
    </Bounce>
  </ActivitiesContainer>
);

export default Activities;
