import { LAYOUT_CODES } from "constants/layout";

import HTML from "../Editors/HTML";
import ReactSandbox from "../Editors/ReactSandbox";
import TextEditor from "../Editors/TextEditor";
import {
  NodeSandbox,
  PythonSandbox,
  CommonSandbox
} from "../Editors/components";

import ImageRenderer from "./ImageRenderer";
import VideoRenderer from "./VideoRenderer";

export { ImageRenderer, VideoRenderer };

export default {
  [LAYOUT_CODES.HTML]: HTML,
  [LAYOUT_CODES.NODE]: NodeSandbox,
  [LAYOUT_CODES.REACT]: ReactSandbox,
  [LAYOUT_CODES.PYTHON]: PythonSandbox,
  [LAYOUT_CODES.IMAGE]: ImageRenderer,
  [LAYOUT_CODES.VIDEO]: VideoRenderer,
  [LAYOUT_CODES.TEXT]: TextEditor,
  [LAYOUT_CODES.COMMON_SANDBOX]: CommonSandbox
};
