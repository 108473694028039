import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";

import {
  PeriodColumn,
  PeriodIndicator,
  SmallPeriodIndicator,
  SmallCircle
} from "./styledElements";

const PeriodSection = ({ pulse, children, mainSection }) => {
  if (mainSection) {
    return (
      <PeriodColumn>
        <PeriodIndicator className={pulse ? "card pulse" : "card"}>
          <SmallCircle
            size={3}
            distance={7}
            duration={1600}
            position={css`
              top: -5px;
              left: 10px;
            `}
          />
          <SmallCircle
            distance={4}
            duration={2000}
            delay={500}
            size={12}
            borderOnly
            position={css`
              top: 30px;
              left: -20px;
            `}
          />
          <SmallCircle
            size={5}
            distance={10}
            duration={2300}
            position={css`
              top: 80px;
              left: -8px;
            `}
            delay={175}
          />
          <SmallCircle
            size={3}
            distance={7}
            duration={1400}
            delay={450}
            position={css`
              top: -15px;
              right: 15px;
            `}
          />
          <SmallCircle
            distance={7}
            duration={1500}
            delay={550}
            position={css`
              top: 15px;
              right: -10px;
            `}
          />
          <SmallCircle
            distance={10}
            duration={1900}
            size={15}
            delay={300}
            position={css`
              top: 55px;
              right: -20px;
            `}
          />
          {children}
        </PeriodIndicator>
      </PeriodColumn>
    );
  }

  return (
    <PeriodColumn>
      <SmallPeriodIndicator className={pulse && "pulse"} />
    </PeriodColumn>
  );
};

PeriodSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  mainSection: PropTypes.bool,
  pulse: PropTypes.bool
};

PeriodSection.defaultProps = {
  children: undefined,
  mainSection: false,
  pulse: false
};

export default PeriodSection;
