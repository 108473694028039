import styled from "styled-components";
import sizes from "theme/sizes";

const WidthWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: calc(${sizes.contentWidth});
  margin: 0 auto;
  padding: 0 5%;
  box-sizing: border-box;
  ${props => props.customstyles};
`;

export default WidthWrapper;
