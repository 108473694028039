import React from "react";
import { css } from "styled-components";
import { ScreenClassRender } from "react-grid-system";

import Colors from "theme/colors";
import { Button } from "components";

import {
  CTAContainer,
  CTABody
} from "./styledElements";

const CTA = () => (
  <ScreenClassRender
    render={screen => (
      <CTAContainer>
          <CTABody screen={screen}>
            <div>
              Start with the fundamentals. 
              Build skills for the real world. 
              Get the job with our help.
            </div>
            <Button
            title="Explore the Courses"
            isExternal
            to="#courses"
            screen={screen}
            customstyles={css`
                color: ${Colors.primary};
                background: ${Colors.white};
                align-self: center;
                width: 65%;
                padding: 1rem 1.4rem;

                &:hover {
                    box-shadow: none;
                    opacity: 0.7;
                }

                ${props =>
                  props.screen &&
                  ["sm", "xs"].includes(props.screen) &&
                  `
                  align-self: center;
                  width: 85%;
                  `}
            `}
            />
          </CTABody>
      </CTAContainer>
    )}
  />
);

export default CTA;
