import CODE_IMAGE from "assets/images/logo.png";
import { LAYOUTS } from "./layout";

const DEFAULT_IMAGE = CODE_IMAGE;
const DEFAULT_CLASS_TITLE = "Edit class title";
const DEFAULT_LAYOUT_CODE = LAYOUTS[0].id;

const DEFAULT_CLASS_ENTRY = {
  title: DEFAULT_CLASS_TITLE,
  layoutCode: DEFAULT_LAYOUT_CODE
};

const COURSE_TYPES = {
  SELF_STUDY: "self-study",
  GUIDED_LEARNING: "guided-learning"
};

const COURSE_TYPE_LABEL = {
  "self-study": "Self-paced",
  "guided-learning": "Live class"
};

const DIFFICULTY_LEVELS = [
  {
    label: "Beginner",
    value: "Beginner",
    icon: "thumbs-up",
    color: "#28bb28"
  },
  {
    label: "Intermediate",
    value: "Intermediate",
    icon: "handshake",
    color: "#ffbc00"
  },
  {
    label: "Advanced",
    value: "Advanced",
    icon: "fist-raised",
    color: "#ea2c23"
  }
];

const LANGUAGE_OPTIONS = [
  {
    label: "English",
    value: "English"
  },
  {
    label: "Tagalog",
    value: "Tagalog"
  }
];

const COURSE_TAGS = [
  {
    label: "Design",
    value: "Design",
    background: "linear-gradient(45deg, #02aab0, #00cdac)"
  },
  {
    label: "Frontend",
    value: "Frontend",
    background: "linear-gradient(45deg, #22e0ff, #3caaf0)"
  },
  {
    label: "Backend",
    value: "Backend",
    background: "linear-gradient(45deg, #ffb634, #ff08ba)"
  },
  {
    label: "DevOps",
    value: "DevOps",
    background: "linear-gradient(45deg, #ffe259, #ffa751)"
  },
  {
    label: "Data Science",
    value: "Data Science",
    background: "linear-gradient(45deg, #0000ff, #ff00f7)"
  },
  {
    label: "General",
    value: "General",
    background: "linear-gradient(45deg, #12c2e9, #c471ed)"
  }
];

const NEW_COURSE_INITIAL_DATA = {
  title: "Introduction to JavaScript",
  image: null,
  about:
    "Hi there, welcome to our imaginary course. If you're ready to learn stuff then you're in the right place. You'll learn stuff you don't know as well as stuff you may already know. So get ready for the most exciting course in your life.",
  price: "1",
  duration: "3 weeks", // might be enum
  hours_per_session: 2,
  max_attendees: 10,
  type: "self-study",
  difficulty: "Intermediate", // enum
  language: "English", // enum,
  tags: "Frontend", // enum,
  iterations: [],
  syllabus: [
    {
      title: DEFAULT_CLASS_TITLE,
      layoutCode: DEFAULT_LAYOUT_CODE,
      children: [
        { title: DEFAULT_CLASS_TITLE, layoutCode: DEFAULT_LAYOUT_CODE },
        { title: DEFAULT_CLASS_TITLE, layoutCode: DEFAULT_LAYOUT_CODE }
      ]
    },
    {
      title: DEFAULT_CLASS_TITLE,
      layoutCode: DEFAULT_LAYOUT_CODE,
      children: [
        { title: DEFAULT_CLASS_TITLE, layoutCode: DEFAULT_LAYOUT_CODE }
      ]
    },
    {
      title: DEFAULT_CLASS_TITLE,
      layoutCode: DEFAULT_LAYOUT_CODE,
      children: [
        { title: DEFAULT_CLASS_TITLE, layoutCode: DEFAULT_LAYOUT_CODE }
      ]
    }
  ]
};

export {
  DEFAULT_CLASS_ENTRY,
  DEFAULT_CLASS_TITLE,
  DEFAULT_LAYOUT_CODE,
  COURSE_TYPES,
  COURSE_TYPE_LABEL,
  DIFFICULTY_LEVELS,
  LANGUAGE_OPTIONS,
  NEW_COURSE_INITIAL_DATA,
  DEFAULT_IMAGE,
  COURSE_TAGS
};
