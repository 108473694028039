import React from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";
import Colors from "theme/colors";

import { css } from "styled-components";
import {
  Container,
  Title,
  Description,
  ButtonsContainer
} from "./styledElements";

import Button from "../../../Button";

const DeleteConfirmation = ({ open, onClose, onDelete, isDeleting }) => (
  <Modal
    open={open}
    onClose={onClose}
    styles={{
      modal: {
        borderRadius: 20
      }
    }}
  >
    <Container>
      <Title>Are you sure?</Title>
      <Description>Please confirm before deleting your course.</Description>
      <ButtonsContainer>
        <Button
          title="Cancel"
          onClick={onClose}
          customstyles={css`
            box-shadow: none;
            border: none;
            color: ${Colors.white};
            background-color: ${Colors.error};
          `}
        />
        <Button
          title="Delete"
          loading={isDeleting}
          loadingText="Deleting course..."
          onClick={onDelete}
          customstyles={css`
            box-shadow: none;
            border: none;
          `}
        />
      </ButtonsContainer>
    </Container>
  </Modal>
);

DeleteConfirmation.propTypes = {
  open: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

DeleteConfirmation.defaultProps = {
  open: false,
  isDeleting: false
};

export default DeleteConfirmation;
