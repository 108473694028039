import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ThemeProvider } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScreenClassRender, Visible } from "react-grid-system";

import { ChoiceSelect } from "../Hero/components";

import CAREERS from "constants/landingCareers";
import PeriodSection from "./PeriodSection";

import {
  TimelineContainer,
  TimelineRow,
  TimelineRowReverse,
  SmallTimelineRow
} from "./styledElements";

import sections from "./sections";

class Timeline extends React.Component {
  state = {
    currentPulse: 0,
    intervalId: undefined
  };

  componentDidMount() {
    this.setState({
      intervalId: setInterval(() => {
        this.setNextPulse();
      }, 2000)
    });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
  }

  setNextPulse = () => {
    const { careerKey } = this.props;
    const max = sections[careerKey].length - 1;

    this.setState(state => {
      const nextPulse = state.currentPulse + 1;
      const currentPulse = nextPulse > max ? 0 : nextPulse;

      return {
        currentPulse
      };
    });
  };

  render() {
    const { currentPulse } = this.state;
    const { color, careerKey, gradient } = this.props;
    return (
      <ThemeProvider
        theme={{
          color,
          gradient
        }}
      >
        <ScreenClassRender
          render={screen => (
            <TimelineContainer screen={screen}>
              <Visible sm xs>
                <ChoiceSelect career={careerKey} />
              </Visible>
              {sections[careerKey].map((section, ind) => {
                let RowComponent = section.reverse
                  ? TimelineRowReverse
                  : TimelineRow;
                RowComponent = section.mainSection
                  ? RowComponent
                  : SmallTimelineRow;

                if (section.mainSection) {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <RowComponent screen={screen} key={careerKey + ind}>
                      {section.mainComponent({
                        isMobile: ["xs", "sm"].includes(screen)
                      })}
                      <PeriodSection
                        mainSection
                        ind={ind}
                        pulse={currentPulse === ind}
                      >
                        <FontAwesomeIcon icon={section.icon} />
                      </PeriodSection>
                      {["xl", "lg", "md"].includes(screen) &&
                        section.assetComponent({
                          isMobile: ["xs", "sm"].includes(screen)
                        })}
                    </RowComponent>
                  );
                }

                if (section.mainComponent) {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <RowComponent key={careerKey + ind}>
                      <PeriodSection
                        mainSection={false}
                        ind={ind}
                        pulse={currentPulse === ind}
                      />
                      {section.mainComponent()}
                    </RowComponent>
                  );
                }

                return section;
              })}
            </TimelineContainer>
          )}
        />
      </ThemeProvider>
    );
  }
}

Timeline.propTypes = {
  color: PropTypes.string,
  careerKey: PropTypes.string,
  gradient: PropTypes.instanceOf(Array)
};

Timeline.defaultProps = {
  color: CAREERS[1].color,
  careerKey: CAREERS[1].key,
  gradient: []
};

export default connect(state => ({
  careerKey: state.landing.career.key
}))(Timeline);
