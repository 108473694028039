import React from "react";
import PropTypes from "prop-types";

import TagManager from "react-gtm-module";

import { notify } from "react-notify-toast";
import { isCourseOwner } from "utils/user";
import { Mutation, Query } from "react-apollo";

import { ENROLL_COURSE } from "api/mutations/course";
import { COURSE_TYPES } from "constants/course";
import {
  getDashboardQuery,
  getCoursesFromQueryResponse
} from "utils/user/dashboard";
import { getGraphqlError } from "utils/general/errors";

import Button from "../../../Button";

const EnrollCourse = ({
  userId,
  id,
  author,
  onOpen,
  togglePaymentForm,
  type,
  isEnrolled
}) => {
  const onError = error => {
    notify.show(getGraphqlError(error), "error");
  };

  const onCompleted = refetch => () => {
    // Create an enrollCourse event
    const tagManagerArgs = {
      dataLayer: {
        event: `enrollCourse_${id}`,
        courseId: id,
        userId
      },
      dataLayerName: "PageDataLayer"
    };

    // Push the event to TagManager
    TagManager.dataLayer(tagManagerArgs);

    notify.show("Successfully enrolled course.", "success");
    refetch();
  };

  const isSelfStudy = type === COURSE_TYPES.SELF_STUDY;

  return (
    <Query
      {...getDashboardQuery({
        pathname: "/user/dashboard/enrolled-courses",
        userId
      })}
      notifyOnNetworkStatusChange
    >
      {({ data, refetch }) => {
        if (userId && id) {
          const enrolledCourses = getCoursesFromQueryResponse({
            data,
            pathname: "/user/dashboard/enrolled-courses"
          });

          if (isSelfStudy)
            // eslint-disable-next-line no-param-reassign
            isEnrolled = enrolledCourses
              .map(enrolledCourse => enrolledCourse.id)
              .includes(id);
        }

        return (
          <Mutation
            notifyOnNetworkStatusChange
            mutation={ENROLL_COURSE}
            variables={{
              courseId: id,
              userId
            }}
            errorPolicy="all"
            onError={onError}
            onCompleted={onCompleted(refetch)}
          >
            {(createUserCourses, { loading: isEnrolling }) => {
              return (
                <>
                  {!isCourseOwner({
                    courseAuthor: author,
                    userId
                  }) &&
                    isEnrolled &&
                    isSelfStudy && (
                      <Button
                        title="Open Course"
                        size="large"
                        onClick={onOpen}
                      />
                    )}
                  <Button
                    size="large"
                    title={!isEnrolled ? "Enroll Course" : "Already Enrolled"}
                    loadingText="Enrolling..."
                    loading={isEnrolling}
                    disabled={isEnrolled || isEnrolling}
                    onClick={
                      isSelfStudy ? createUserCourses : togglePaymentForm
                    }
                  />
                </>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

EnrollCourse.propTypes = {
  userId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  togglePaymentForm: PropTypes.func.isRequired,
  type: PropTypes.string,
  price: PropTypes.string,
  isEnrolled: PropTypes.bool
};

EnrollCourse.defaultProps = {
  type: COURSE_TYPES.SELF_STUDY,
  isEnrolled: false,
  price: null
};

export default EnrollCourse;
