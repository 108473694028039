/* eslint no-nested-ternary : off */
import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import styled from "styled-components";

import { notify } from "react-notify-toast";
import { getPreview, getBackgroundGradient } from "utils/general/image";

const Image = styled.div`
  ${props => `
  width: 100%;
  height: 300px;
  background: ${props.gradient};
  ${!props.noImage &&
    `
    background-image: url(${props.image});
    background-image: url(${props.image}), ${props.gradient};
    background-repeat: no-repeat;
    background-position: center center;
  `}
  background-size: cover;
  cursor: pointer;
  border-radius: 10px;
  `}
`;

class CourseImage extends React.PureComponent {
  state = {
    preview: null
  };

  setPreview = preview =>
    this.setState({
      preview
    });

  onDropAccepted = async accepted => {
    if (accepted && accepted.length > 0) {
      const { onChange } = this.props;

      if (accepted[0].size > 2000000) {
        notify.show("File size should not exceed 2MB. ", "error");
        return;
      }

      const preview = await getPreview(accepted[0]);
      this.setPreview(preview);

      onChange(accepted[0]);
    }
  };

  render() {
    const { preview } = this.state;
    const { editMode, image, tags } = this.props;

    const noImage = !image;
    const displayPreview = image
      ? typeof image === "object"
        ? image.preview
        : image
      : getBackgroundGradient(tags);

    if (!editMode) {
      return (
        <Image
          noImage={noImage}
          gradient={getBackgroundGradient(tags)}
          image={preview || displayPreview}
        />
      );
    }

    return (
      <Dropzone
        multiple={false}
        onDropAccepted={this.onDropAccepted}
        accept="image/*"
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Image
              noImage={noImage}
              gradient={getBackgroundGradient(tags)}
              image={preview || displayPreview}
            />
          </div>
        )}
      </Dropzone>
    );
  }
}

CourseImage.propTypes = {
  editMode: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  tags: PropTypes.string
};

CourseImage.defaultProps = {
  onChange: () => null,
  editMode: false,
  image: "",
  tags: null
};

export default CourseImage;
