import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./RendererComponents";

const ImageRenderer = ({ value }) => <Wrapper background={value} />;

ImageRenderer.propTypes = {
  value: PropTypes.string
};

ImageRenderer.defaultProps = {
  value:
    "https://images.unsplash.com/photo-1523554888454-84137e72c3ce?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b73b0c2349deddcbe6cbe8b9bfb71853&dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb"
};

export default ImageRenderer;
