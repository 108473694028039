/* eslint import/prefer-default-export : off */
import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #303036;
  color: rgb(255, 255, 255);
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);

  small {
    color: rgb(255, 255, 255);
  }
`;
