import styled from "styled-components";
import Colors, { GRADIENTS } from "theme/colors";

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 10%;
  padding: 1rem;
  border-radius: 1rem;

  ${GRADIENTS.software};
`;

export const CTABody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  margin: 2rem;
  text-align: center;
  font-size: 1.9rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;

  div {
    color: ${Colors.white};
    margin-bottom: 2rem;
  }

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
      width: 100%;
      margin: 1rem;
      font-size: 1rem;
    `}
`;