/* eslint import/prefer-default-export : off */

import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 20px 10px;
  color: rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);

  small {
    color: rgba(0, 0, 0, 0.5);
  }
`;
