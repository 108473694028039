import React from "react";
import PropTypes from "prop-types";
import Colors from "theme/colors";
import styled from "styled-components";

import Logo from "./Logo";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LogoWrapper = styled.div`
  position: relative;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${props => (!props.small ? "50px" : "25px")};
  height: ${props => (!props.small ? "50px" : "25px")};
`;

const Academy = styled.div`
  color: ${props => (props.color ? props.color : Colors.text)};
  background: none;
  font-weight: 400;
  display: block;
  letter-spacing: 4px;
  font-size: 1.5rem;
  font-family: "Futura PT";
  opacity: 1;
  transition: all 275ms ease-out;

  &:hover {
    background: linear-gradient( 19deg, rgb(33,212,253) 0%, rgb(183,33,255) 100% );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 275ms ease-out;
  }
`;

const Brand = ({ small, color, noBackground, noLogo }) => (
  <Wrapper>
    {!noLogo && (
      <LogoWrapper small={small} noBackground={noBackground}>
        <Logo size="image" color={color} />
      </LogoWrapper>
    )}
    <Academy color={color} small={small}>
      <div>AMAGI ACADEMY</div>
    </Academy>
  </Wrapper>
);

Brand.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string,
  noBackground: PropTypes.bool,
  noLogo: PropTypes.bool
};

Brand.defaultProps = {
  small: false,
  color: null,
  noBackground: false,
  noLogo: false
};

export default Brand;
