import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const Image = styled.img`
  display: block;
  height: 100%;
`;

const LazyImage = ({ src, alt }) => {
  const [imageSrc, setImageSrc] = useState("");
  const imageRef = useRef();

  useEffect(() => {
    let observer;
    let didCancel = false;
    const image = imageRef.current;

    if (image && imageSrc === "") {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              // when image is visible in the viewport + rootMargin
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: "75%"
          }
        );
        observer.observe(image);
      } else {
        // Old browsers fallback
        setImageSrc(src);
      }
    }
    return () => {
      didCancel = true;
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(image);
      }
    };
  }, [imageSrc, src]);

  return <Image ref={imageRef} src={imageSrc} alt={alt} />;
};

export default LazyImage;
