export default [
  {
    title: "Live Courses",
    to: "/live-courses"
  },
  {
    title: "Practice",
    to: "/catalog"
  },
  {
    title: "Hire Us",
    to: "https://amagi.io",
    isExternal: true
  },
  {
    title: "Jobs",
    to: "https://techpool.io/",
    isExternal: true
  },
  {
    title: "Blog",
    to: "/blog",
    isExternal: true
  },
  {
    title: "FAQ",
    to: "/terms"
  }
];
