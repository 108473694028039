import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import enhanceWithClickOutside from "react-click-outside";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditButton } from "components/Button";
import { Bullet, Container, ErrorContainer, styles } from "./common";

class EditableDropdown extends React.Component {
  state = { editing: false };

  toggleEdit = () => {
    const { editing } = this.state;
    const { onEdit, enableInlineEdit, startEdit } = this.props;

    if (!editing) {
      onEdit({ type: "dropdown" });
    }

    if (enableInlineEdit) {
      if (!editing) startEdit();
      this.setState({ editing: !editing });
    }
  };

  onChange = ({ value }) => {
    const {
      input: { onChange }
    } = this.props;

    onChange(value);
  };

  stopEditOnEsc = e => {
    const {
      stopEdit,
      meta: { touched, error }
    } = this.props;

    if (/escape/i.test(e.key) && touched && !error) {
      stopEdit();
      this.toggleEdit();
    }
  };

  handleClickOutside = () => {
    const { editing } = this.state;
    const {
      stopEdit,
      meta: { touched, error }
    } = this.props;

    if (editing && touched && !error) {
      stopEdit();
      this.toggleEdit();
    }
  };

  handleBlur = value => () => {
    const {
      input: { onBlur }
    } = this.props;

    onBlur(value);
  };

  render() {
    const { editing } = this.state;
    const {
      containerStyle,
      options,
      type,
      input,
      meta: { touched, error },
      textStyle,
      editing: controlledEditing,
      allowEdit,
      showBullet,
      centerItems,
      showEditAlways
    } = this.props;

    const { value, onFocus } = input;

    const selectValue = options.find(entry => entry.value === value);

    let editableStyle = { ...styles.text, ...styles.dropdown, ...textStyle };

    const editMode =
      typeof controlledEditing === "boolean"
        ? controlledEditing && editing
        : editing;

    if (!editing) {
      editableStyle = {
        ...editableStyle,
        ...styles.displayText
      };
    }

    return (
      <>
        <Container
          showEditAlways={showEditAlways}
          centerItems={centerItems}
          editing={editing}
          allowEdit={allowEdit}
          style={containerStyle}
        >
          {editMode ? (
            <Select
              autoFocus
              value={selectValue}
              defaultValue={selectValue}
              onFocus={onFocus}
              onBlur={this.handleBlur(value)}
              onKeyDown={this.stopEditOnEsc}
              styles={{
                input: style => ({
                  ...style,
                  ...editableStyle,
                  ...styles.input
                }),
                control: style => ({
                  ...style,
                  ...styles.input
                }),
                singleValue: style => ({
                  ...style,
                  ...textStyle
                }),
                placeholder: style => ({
                  ...style,
                  ...textStyle
                })
              }}
              onChange={this.onChange}
              options={options}
            />
          ) : (
            React.createElement(
              type,
              {
                style: {
                  ...editableStyle,
                  cursor: allowEdit ? "pointer" : "default",
                  display: "inline",
                  width: "auto"
                },
                className: "display-text",
                onClick: allowEdit ? this.toggleEdit : null
              },
              showBullet ? (
                <span>
                  <Bullet>•</Bullet>
                  {value}
                </span>
              ) : (
                value
              )
            )
          )}
          {!editMode && allowEdit && (
            <EditButton onClick={allowEdit ? this.toggleEdit : null}>
              <FontAwesomeIcon icon="pen" />
            </EditButton>
          )}
        </Container>
        {touched && error && <ErrorContainer>{error}</ErrorContainer>}
      </>
    );
  }
}

EditableDropdown.propTypes = {
  startEdit: PropTypes.func,
  onEdit: PropTypes.func,
  stopEdit: PropTypes.func,
  enableInlineEdit: PropTypes.bool,
  containerStyle: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array),
  type: PropTypes.string,
  textStyle: PropTypes.instanceOf(Object),
  editing: PropTypes.bool,
  allowEdit: PropTypes.bool,
  showBullet: PropTypes.bool,
  centerItems: PropTypes.bool,
  showEditAlways: PropTypes.bool,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object)
};

EditableDropdown.defaultProps = {
  startEdit: () => null,
  onEdit: () => null,
  stopEdit: () => null,
  enableInlineEdit: false,
  containerStyle: {},
  options: [],
  type: "p",
  textStyle: {},
  editing: false,
  allowEdit: true,
  showBullet: false,
  centerItems: false,
  showEditAlways: false,
  input: {},
  meta: {}
};

export default enhanceWithClickOutside(EditableDropdown);
