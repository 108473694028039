import styled from "styled-components";
import Colors from "theme/colors";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  padding: 1rem 10%;
  background: ${Colors.secondaryBackground};
`;

export const SectionHeadline = styled.h2`
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: ${Colors.onBackgroundLowOpacity};
`;

export const SectionBody = styled.div`
  color: ${Colors.text};
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: space-around;

  hr {
    margin: 1rem 0;
    width: 50%;
    height: 1px;
    border: none;
    background: #212121;
    opacity: 0.3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  a {
    font-size: 1rem;
  }

  ${props => props.customCss && props.customCss}

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       h2 {
         font-size: 2rem;   
         text-align: center;
       }

       hr {
         margin: 0 auto;
         margin-bottom: 1rem;
       }
    `}
`;

export const CompanyContainer = styled.div`
  max-width: 300px;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all .15s ease-in;

  &:hover {
    filter: grayscale(0%);
    opacity: 1;
  }
`;

export const CompanyHeadline = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
`;

export const CompanyImage = styled.img`
  max-width: 10vw;

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
      max-width: 25vw;
    `}
`;

export const CompanyBody = styled.p`
  margin: 0;
`;