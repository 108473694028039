import React from "react";
import { ScreenClassRender } from "react-grid-system";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FooterContainer,
  FooterEnd,
  FooterByline,
  SocialIcons
} from "./styledElements";

const Footer = () => (
  <ScreenClassRender
    render={screen => (
      <FooterContainer>
        <FooterEnd>
          <SocialIcons screen={screen}>
            <a href="mailto:academy@amagi.io" rel="external nofollow">
              <FontAwesomeIcon icon={["fa", "envelope"]} />
            </a>
            <a href="https://www.facebook.com/amagiacademy" rel="external nofollow">
              <FontAwesomeIcon icon={["fab", "facebook"]} />
            </a>
            <a href="https://twitter.com/amagiacademy" rel="external nofollow">
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </a>
            <a href="https://instagram.com/amagiacademy" rel="external nofollow">
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </a>
            <a
              href="https://slack-amagiacademy.herokuapp.com"
              rel="external nofollow"
            >
              <FontAwesomeIcon icon={["fab", "slack"]} />
            </a>
          </SocialIcons>
          <FooterByline>
            <a href="https://amagi.io">AMAGI</a>
          </FooterByline>
        </FooterEnd>
      </FooterContainer>
    )}
  />
);

export default Footer;
