import React from "react";

export default {
  progressSteps: [
    {
      key: "prep",
      name: "Full-Stack - Level I",
      time: "1 week",
      week: 1, // specify which week the progress bar should move to when step is selected
      component: (
        <>
          <h4>Web Fundamentals</h4>
          <p>
            Begin with the foundational languages of the web: HTML and CSS. We
            will make simple to more intricate websites while developing an
            appreciation for good UI/UX design.
          </p>
          <br />
          <h4>JavaScript</h4>
          <p>
            JavaScript has become the dominant language for modern web
            developers and it has even become popular among modern developers
            focused on mobile, AR, VR, IoT, and more. We'll learn the modern
            version of the language to give you a strong foundation as a
            JavaScript developer.
          </p>
          <br />
          <h5>Skills:</h5>
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>CSS Frameworks</li>
            <li>UI/UX Design</li>
            <li>JavaScript</li>
            <li>DOM Manipulation</li>
            <li>AJAX</li>
          </ul>
        </>
      )
    },
    {
      key: "prep",
      name: "Full-Stack - Level II",
      time: "3 weeks",
      week: 4, // specify which week the progress bar should move to when step is selected
      component: (
        <>
          <h4>ReactJS</h4>
          <p>
            ReactJS is the most popular JavaScript framework today. It allows
            you to quickly build complex, responsive, and dynamic websites using
            the most modern features of the web in less time. We'll be
            harnessing the whole, modern ReactJS ecosystem to bring your
            front-end to life.
          </p>
          <br />
          <h5>Skills:</h5>
          <ul>
            <li>Functional Programming</li>
            <li>JSX</li>
            <li>ReactJS</li>
            <li>Redux</li>
            <li>React Router</li>
            <li>React Native</li>
          </ul>
        </>
      )
    },
    {
      key: "prep",
      name: "Full-Stack - Level III",
      time: "3 weeks",
      week: 7, // specify which week the progress bar should move to when step is selected
      component: (
        <>
          <h4>NodeJS</h4>
          <p>
            NodeJS is one of the most popular server runtimes in the world, and
            it uses JavaScript. Focused on ease of use, high-speed performance,
            streaming, and event-based architectures, you'll have the power to
            build modern servers and API's quickly.
          </p>
          <br />
          <h4>Databases</h4>
          <p>
            Every server-side developer must become familiar with modern
            databases. We'll be working with the popular and versatile MongoDB
            and PostgreSQL databases so you will be able to build performant
            routes in SQL/NoSQL architectures with no problem.
          </p>
          <br />
          <h5>Skills:</h5>
          <ul>
            <li>NodeJS</li>
            <li>Async JS</li>
            <li>Events</li>
            <li>Streams</li>
            <li>MongoDB</li>
            <li>PostgreSQL</li>
          </ul>
        </>
      )
    }
  ]
};
