import styled from "styled-components";
import { Col } from "react-grid-system";

import Colors from "theme/colors";

export const CustomersContainer = styled.div`
  margin: 1rem 0;
  padding: 20px 0;
  background-color: ${Colors.secondaryBackground};
`;

export const CustomersHeadline = styled.h2`
  margin: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: ${Colors.onBackgroundLowOpacity};
`;

export const CustomerContainer = styled(Col).attrs({
  xl: 12 / 7,
  lg: 12 / 7,
  md: 12 / 4,
  sm: 12 / 3,
  xs: 5
})`
  text-align: center;

  filter: grayscale(100%);
  opacity: 0.3;
  transition: all .15s ease-in;

  &:hover {
    filter: grayscale(0%);
    opacity: 1;
`;

export const Customer = styled.img`
  width: ${props => (props.size ? `${props.size}px` : "8vw")};
  margin: 1rem;

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
      width: ${(props.size ? `${props.size}px` : "25vw")};
      margin: 0.5rem;
    `};
`;
