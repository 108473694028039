import styled from "styled-components";
import FontSizes from "theme/fontsizes";
import Colors from "theme/colors";

import { Container } from "react-grid-system";

export const ClassReadingTitle = styled.div`
  font-weight: bold;
  padding-top: 10px;
  padding-left: 30px;
  padding-bottom: 10px;
  font-size: ${FontSizes.smallBase};
  background-color: ${Colors.background};
  display: flex;
  align-items: center;

  .text {
    margin-left: 10px;
  }
`;

export const ClassContent = styled(Container).attrs({
  fluid: true
})`
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: calc(100vh - 108px) !important;
  max-height: calc(100vh - 108px) !important;
  overflow-y: auto;
`;
