/* eslint import/prefer-default-export : off */
import Sizes from "theme/sizes";
import FontSizes from "theme/fontsizes";
import Colors from "theme/colors";
import styled from "styled-components";

export const Wrapper = styled.header`
  width: 100%;
  height: ${Sizes.authHeaderHeight};
  color: ${Colors.text};
  display: flex;
  align-items: center;

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left,
  .title,
  .right {
    flex: 1;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: ${FontSizes.smallBase};

    svg {
      cursor: pointer;
      padding: 10px;
      margin-right: -10px;

      &:hover {
        opacity: 0.7;
      }
    }

    .name {
      margin-right: 20px;

      a.login {
        color: ${Colors.primaryDark};
      }
    }
  }

  .title {
    text-align: center;
    font-size: ${FontSizes.headerTitle};
  }
`;
