import React from "react";
import PropTypes from "prop-types";

import Sandbox from "../Sandbox";

class CommonSandbox extends React.Component {
  getValue = () => {
    return this.sandbox.getValue();
  };

  render() {
    const { value, template } = this.props;

    return (
      <Sandbox
        value={value}
        template={template}
        ref={instance => {
          this.sandbox = instance;
        }}
      />
    );
  }
}

CommonSandbox.propTypes = {
  value: PropTypes.string,
  template: PropTypes.string
};

CommonSandbox.defaultProps = {
  value: null,
  template: "parcel"
};

export default CommonSandbox;
