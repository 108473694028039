import React from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";

import { css } from "styled-components";
import { searchLibraries } from "api/npm";
import { truncate } from "utils/general/string";
import {
  AddButton,
  Container,
  Dependency,
  ResultsContainer
} from "./styledElements";

import SearchField from "../../../SearchField";
import Paginate from "../Paginate";
import NpmLoading from "../NpmLoading";

const MAX_PER_PAGE = 25; // Fixed in api call
const MAX_PAGE_LIMIT = Math.ceil(10000 / MAX_PER_PAGE);

class NpmSearch extends React.Component {
  state = {
    showModal: false,
    currentQuery: null,
    totalResults: 0,
    dependencies: [],
    isFetching: false
  };

  showModal = () =>
    this.setState({
      showModal: true
    });

  hideModal = () =>
    this.setState({
      showModal: false
    });

  onClickItem = ({ name, version } = {}) => event => {
    if (event) event.stopPropagation();

    const { onSelect } = this.props;

    onSelect({ name, version });
    this.hideModal();
  };

  handleSubmit = query => {
    this.paginate.reset();
    this.search({ query, from: 0 });
  };

  toggleFetching = () => {
    const { isFetching } = this.state;

    this.setState({
      isFetching: !isFetching
    });
  };

  search = async ({ query, from }) => {
    if (!query) return;

    this.toggleFetching();

    const [error, dependencies] = await searchLibraries({
      query,
      from
    });

    this.toggleFetching();
    if (!error && dependencies.results) {
      this.setState({
        dependencies: dependencies.results,
        totalResults: dependencies.total,
        currentQuery: query
      });
    }
  };

  handlePageChange = page => {
    const { currentQuery: query } = this.state;

    const from = (page - 1) * MAX_PER_PAGE;
    this.search({ query, from });
  };

  getMaxPage = () => {
    const { totalResults } = this.state;

    let max = Math.ceil(totalResults / MAX_PER_PAGE);

    if (max > MAX_PAGE_LIMIT) {
      max = MAX_PAGE_LIMIT;
    }

    return max;
  };

  render() {
    const { showModal, dependencies, isFetching } = this.state;

    return (
      <Container>
        <AddButton title="Add Dependency" onClick={this.showModal} />
        <Modal
          showCloseIcon={false}
          styles={{
            modal: {
              borderRadius: 4,
              padding: 0,
              width: "100%",
              maxWidth: 600
            },
            overlay: {
              background: "rgba(0, 0, 0, 0.6)"
            }
          }}
          open={showModal}
          onClose={this.hideModal}
        >
          <SearchField
            onSearch={this.handleSubmit}
            disabled={isFetching}
            placeholder="Search npm dependency..."
            inputStyle={css`
              padding: 15px;
              padding-right: 130px;
              margin: 0;
              background-color: rgba(0, 0, 0, 0.15);
              width: calc(100% - 145px);
            `}
          />
          <Paginate
            ref={instance => {
              this.paginate = instance;
            }}
            maxPage={this.getMaxPage()}
            onPageChange={this.handlePageChange}
          />
          <ResultsContainer>
            {dependencies.map(({ package: { name, description, version } }) => (
              <Dependency
                key={`dependency-${name}`}
                onClick={this.onClickItem({
                  name,
                  version
                })}
              >
                <div>
                  <div className="name">{name}</div>
                  <div className="description">
                    {truncate(description, 150)}
                  </div>
                </div>
                <div className="version">{version}</div>
              </Dependency>
            ))}
          </ResultsContainer>
          <NpmLoading isLoading={isFetching} />
        </Modal>
      </Container>
    );
  }
}

NpmSearch.propTypes = {
  onSelect: PropTypes.func
};

NpmSearch.defaultProps = {
  onSelect: () => null
};

export default NpmSearch;
