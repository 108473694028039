/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Field, getFormValues, formValueSelector, reduxForm } from "redux-form";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { withApollo, Mutation, Query } from "react-apollo";
import { notify } from "react-notify-toast";
import { ScreenClassRender } from "react-grid-system";

import FullNodeDragTheme from "react-sortable-tree-theme-full-node-drag";
import SortableTree from "react-sortable-tree";

import { VERIFY_PROMO_CODE } from "api/queries/payment";
import { GET_USER_CARD_DETAILS } from "api/queries/user";
import { UPDATE_USER_CARD_DETAILS } from "api/mutations/user";
import { CREATE_USER_ITERATION } from "api/mutations/course";

import { COURSE_TYPES } from "constants/course";
import Colors from "theme/colors";
import Sizes from "theme/sizes";

import { getGraphqlError } from "utils/general/errors";
import { getBackgroundGradient } from "utils/general/image";
import { isIterationOngoing, prettyRange } from "utils/general/date";

import Button from "../../../Button";
import { EditableText } from "../../../EditableInputs";
import LayoutSelector from "../../../LayoutSelector";
import PaymentForm from "../../../PaymentForm";
import { Select } from "../../../FormElements";

export const PaymentPopup = ({
  id,
  title,
  type,
  tags,
  price,
  iterations,
  syllabus,
  togglePaymentForm,
  formValues,
  refetch,
  client,
  initialize,
  courseDetails
}) => {
  const [currentPrice, setCurrentPrice] = useState(Number(price));
  const [isLoading, setIsLoading] = useState(false);
  const { coupon, iteration } = formValues;
  const stripe = useStripe();
  const elements = useElements();
  const isSelfPaced = type === COURSE_TYPES.SELF_STUDY;
  const filteredIterations = iterations
    .filter(el => {
      // check if user is already attending
      const attendees = el.attendees.map(attendee => JSON.parse(attendee));
      const isAttending = !!attendees.find(attendee => attendee.userId === id);

      // filter out ended/already enrolled classes for students
      return isIterationOngoing(el.session_starts) && !isAttending;
    })
    .sort((iteration1, iteration2) => {
      const startDate1 = new Date(iteration1.session_starts[0]);
      const startDate2 = new Date(iteration2.session_starts[0]);

      if (startDate1 < startDate2) return -1;
      if (startDate1 > startDate2) return 1;
      return 0;
    });

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    const initializeValues = () => {
      initialize({
        iteration: iteration || filteredIterations[0]
      });
    };

    initializeValues();

    return function cleanup() {
      document.body.style.overflowY = "initial";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = error => {
    notify.show(getGraphqlError(error), "error");
  };

  const onCompleted = () => {
    notify.show(
      "You've successfully updated your card details and confirmed your payment.",
      "info"
    );
    togglePaymentForm();
    refetch();
  };

  const onCouponSuccess = data => {
    /* eslint-disable camelcase */
    const { promo_code } = data;
    /* eslint-disable-next-line */
    const { id, valid, percent_off } = promo_code;

    if (!valid) {
      notify.show(`Coupon ${id} is no longer valid.`, "error");
      return;
    }

    const percentage = percent_off / 100;
    const priceNum = Number(price);
    setCurrentPrice(priceNum - priceNum * percentage);
    notify.show(`Coupon ${id} applied.`, "info");
  };

  const onCouponError = error => {
    notify.show(getGraphqlError(error), "error");
  };

  const handleSubmit = async (cardCb, iterationCb) => {
    setIsLoading(true);

    if (!cardCb) {
      await iterationCb();
      setIsLoading(false);
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { name, address } = formValues;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name,
        address: {
          ...address,
          country: address && address.country
        }
      }
    });

    if (error) {
      notify.show(error.message, "error");
      setIsLoading(false);
      return;
    }

    cardCb({
      variables: {
        id,
        details: {
          payment_method: paymentMethod.id
        }
      }
    }).then(() => {
      iterationCb();
      setIsLoading(false);
    });
  };

  // eslint-disable-next-line react/prop-types
  const renderTitle = ({ treeIndex } = {}) => {
    return (
      <EditableText
        input={{
          value: courseDetails[`syllabus-field-${treeIndex}`]
        }}
        textStyle={{
          fontSize: "inherit",
          fontWeight: "normal",
          color: Colors.onBackground
        }}
        singleLine
        centerItems
        showBullet
        type="p"
      />
    );
  };

  return (
    <ScreenClassRender
      render={screen => (
        <Container>
          <Popup screen={screen}>
            <ChargeDisplay
              gradient={getBackgroundGradient(tags)}
              screen={screen}
            >
              <ChargeText>
                {isNaN(currentPrice) ? currentPrice : `$${currentPrice}`}
              </ChargeText>
              <CourseTitle>{title}</CourseTitle>
              <SyllabusWrapper>
                <LayoutSelector noTrigger />
                <div className="display-text">
                  <SortableTree
                    onChange={() => {}}
                    className=""
                    maxDepth={2}
                    canDrag={false}
                    isVirtualized={false}
                    style={{ flex: 1 }}
                    treeData={syllabus}
                    theme={FullNodeDragTheme}
                    generateNodeProps={({ node, path, treeIndex }) => ({
                      title: renderTitle({ treeIndex, node, path }),
                      listIndex: 0,
                      lowerSiblingCounts: []
                    })}
                  />
                </div>
              </SyllabusWrapper>
              {!isSelfPaced &&
                filteredIterations &&
                (filteredIterations.length === 1 ? (
                  <DateAndTime>
                    {iteration &&
                      iteration.session_starts &&
                      prettyRange(iteration.session_starts)}
                  </DateAndTime>
                ) : (
                  <Field
                    name="iteration"
                    options={filteredIterations.map(i => ({
                      value: i,
                      label: prettyRange(i.session_starts)
                    }))}
                    component={Select}
                  />
                ))}
            </ChargeDisplay>
            <Query
              query={GET_USER_CARD_DETAILS}
              variables={{ id }}
              fetchPolicy="network-only"
            >
              {({
                data: cardData,
                loading: cardLoading,
                refetch: cardRefetch
              }) => (
                <Mutation
                  onCompleted={cardRefetch}
                  onError={onError}
                  mutation={UPDATE_USER_CARD_DETAILS}
                >
                  {(updateCardDetails, { loading }) => (
                    <Mutation
                      mutation={CREATE_USER_ITERATION}
                      onCompleted={onCompleted}
                      onError={onError}
                      variables={{
                        userId: id,
                        iterationId: iteration && iteration.id,
                        promoCoupon: coupon
                      }}
                    >
                      {(createUserIteration, { loading: iterationLoading }) => (
                        <PaymentContainer>
                          <Heading>Payment Details</Heading>
                          <PaymentForm
                            data={!cardLoading ? cardData : undefined}
                            handleCouponApply={async () => {
                              const { data, errors } = await client.query({
                                query: VERIFY_PROMO_CODE,
                                variables: {
                                  id: coupon
                                },
                                errorPolicy: "all"
                              });

                              if (errors && errors.length > 0) {
                                onCouponError(errors[0]);
                                return;
                              }
                              onCouponSuccess(data);
                            }}
                            isPayment
                          />
                          <ActionContainer>
                            <Button
                              title={
                                isLoading || loading || iterationLoading
                                  ? "Loading..."
                                  : "Confirm and Pay"
                              }
                              onClick={() =>
                                handleSubmit(
                                  cardData && cardData.userCardDetails
                                    ? null
                                    : updateCardDetails,
                                  createUserIteration
                                )
                              }
                            />
                            <Button
                              title="Cancel"
                              buttontype="cancel"
                              onClick={togglePaymentForm}
                            />
                          </ActionContainer>
                        </PaymentContainer>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Query>
          </Popup>
        </Container>
      )}
    />
  );
};

const Container = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
`;

const Popup = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${props =>
    ["sm", "xs"].includes(props.screen) ? "column" : "row"};
  justify-content: stretch;
  border-radius: ${props =>
    ["sm", "xs"].includes(props.screen) ? "none" : Sizes.baseRadius};
  background-color: ${Colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: ${props =>
    ["sm", "xs"].includes(props.screen) ? "fixed" : "initial"};
  overflow: none;
  width: ${props => (["sm", "xs"].includes(props.screen) ? "100vw" : "80vw")};
  height: ${props => (["sm", "xs"].includes(props.screen) ? "100vh" : "80vh")};
`;

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  overflow: auto;
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-spacing: 0.2rem;
  margin: 0;
  margin-bottom: 0.5rem;
`;

const ChargeDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-basis: 40%;
  height: 100%;
  box-sizing: border-box;
  border-top-left-radius: ${Sizes.baseRadius};
  border-bottom-left-radius: ${Sizes.baseRadius};
  padding: 20px;
  background: ${props => props.gradient};

  ${props =>
    ["sm", "xs"].includes(props.screen) &&
    `
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}
`;

const ChargeText = styled.div`
  font-weight: bold
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: ${Colors.white};
`;

const CourseTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
`;

const DateAndTime = styled.div`
  font-style: italic;
  font-size: 0.9rem;
`;

const SyllabusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 0.3rem;
  margin-left: -50px;
  margin-bottom: 1rem;

  .rst__node {
    margin: 3px 0;
    height: unset !important;
  }

  .rstcustom__rowContents {
    box-shadow: none;
  }

  .display-text {
    font-family: inherit !important;
    color: inherit !important;
    flex: 1;
  }

  .rstcustom__rowContents {
    &:hover {
      box-shadow: none;
    }
  }

  .display-text {
    flex: 1;
  }

  .edit-button {
    cursor: default !important;
  }

  &:hover {
    .display-text {
      background-color: initial;
      transition: unset;
      cursor: default !important;
    }

    .edit-button {
      visibility: hidden;
      cursor: default !important;
    }
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

PaymentPopup.propTypes = {
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  iteration: PropTypes.instanceOf(Object),
  syllabus: PropTypes.instanceOf(Array),
  tags: PropTypes.string,
  title: PropTypes.string,
  togglePaymentForm: PropTypes.func.isRequired,
  formValues: PropTypes.instanceOf(Object),
  refetch: PropTypes.func,
  type: PropTypes.string,
  client: PropTypes.instanceOf(Object).isRequired,
  iterations: PropTypes.instanceOf(Array),
  initialize: PropTypes.func.isRequired,
  courseDetails: PropTypes.instanceOf(Object)
};

PaymentPopup.defaultProps = {
  iteration: null,
  syllabus: [],
  tags: "",
  title: "",
  formValues: {},
  refetch: () => {},
  type: undefined,
  iterations: [],
  courseDetails: {}
};

export default reduxForm({
  form: "payment"
})(
  connect(state => ({
    id: state.user.id,
    courseDetails: getFormValues("courseDetails")(state),
    formValues: {
      iteration: formValueSelector("payment")(state, "iteration"),
      name: formValueSelector("payment")(state, "name"),
      coupon: formValueSelector("payment")(state, "coupon"),
      address: formValueSelector("payment")(state, "address")
    }
  }))(withApollo(PaymentPopup))
);
