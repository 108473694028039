import gql from "graphql-tag";

export const GET_COURSE = gql`
  query Course($id: ID!) {
    course(id: $id) {
      id
      created_at
      updated_at
      author
      author_id
      title
      image
      about
      price
      tags
      difficulty
      language
      type
      published

      ... on SelfStudyCourse {
        instructors
        start_date
        duration
        layouts {
          id
          type
          depth
          position
          title
          order_index
          markdown
          media
        }
      }
      ... on GuidedLearningCourse {
        session_count
        hours_per_session
        max_attendees
        iterations {
          id
          session_starts
          attendees
        }
        layouts {
          id
          type
          depth
          position
          title
          order_index
          markdown
          media
        }
      }
    }
  }
`;

export const GET_PUBLIC_COURSE = gql`
  query Course($id: ID!) {
    publicCourse(id: $id) {
      id
      created_at
      updated_at
      author
      title
      image
      about
      price
      tags
      difficulty
      language
      type

      ... on SelfStudyCourse {
        instructors
        start_date
        duration
        layouts {
          id
          type
          depth
          position
          title
          order_index
          markdown
          media
        }
      }
      ... on GuidedLearningCourse {
        session_count
        hours_per_session
        max_attendees
        iterations {
          id
          session_starts
          attendees
        }
        layouts {
          id
          type
          depth
          position
          title
          order_index
          markdown
          media
        }
      }
    }
  }
`;

export const GET_COURSES_BY_AUTHOR = gql`
  query CouresByAuthor($author: String!) {
    courseByAuthor(author: $author) {
      id
      author
      title
      image
      about
      price
      tags

      ... on SelfStudyCourse {
        instructors
        start_date
        duration
        layouts {
          id
          type
          depth
          position
          title
          order_index
          markdown
          media
        }
      }
      ... on GuidedLearningCourse {
        session_count
        hours_per_session
        max_attendees
        iterations {
          id
          session_starts
          attendees
        }
        layouts {
          id
          type
          depth
          position
          title
          order_index
          markdown
          media
        }
      }
    }
  }
`;

export const GET_COURSES = gql`
  query {
    courses {
      id
      author
      title
      image
      about
      tags
      type
    }
  }
`;

export const GET_PUBLIC_COURSES = gql`
  query {
    publicCourses {
      id
      author
      title
      image
      about
      tags
      type
    }
  }
`;

export const SEARCH_COURSES = gql`
  query SearchCourses($title: String!) {
    searchCourses(title: $title) {
      id
      author
      title
      image
      about
      tags
      type
    }
  }
`;

export const GET_ENROLLED_COURSES = gql`
  query UserCourseByUser($userId: ID!) {
    usercoursebyuser(user_id: $userId) {
      user_id
      courses {
        id
        author
        title
        image
        about
        tags
      }
    }
  }
`;
