import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { isIterationOngoing, prettyRange } from "utils/general/date";

import Button from "../Button";
import ScheduleClass from "./ScheduleClass";
import IterationItem from "./IterationItem";
import { Container, IterationContainer } from "./styledElements";

let editId;

class IterationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleClassVisible: false
    };
  }

  // componentDidMount() {
  //   const { iterations } = this.props;
  //   this.onChange(newIterations);
  // }

  onChange = iterations => {
    const {
      containerOnChange,
      changeIndividualValues,
      input: { onChange }
    } = this.props;

    if (onChange) {
      onChange(iterations);

      if (containerOnChange) {
        containerOnChange(iterations);
      }

      if (changeIndividualValues) {
        changeIndividualValues(iterations);
      }
    }
  };

  handleRemoveSchedule = iteration => {
    const { iterations } = this.props;
    let newIterations;

    if (iterations) newIterations = [...iterations];
    else newIterations = [];

    const idx = newIterations.findIndex(el => el === iteration);

    newIterations.splice(idx, 1);
    this.onChange(newIterations);
  };

  onChangeSchedule = schedule => {
    const { iterations } = this.props;

    let newIterations;
    if (iterations) newIterations = [...iterations];
    else newIterations = [];

    const idx = newIterations.findIndex(el => el === editId);

    // Below is the code to add new classes and edit by selected index:
    if (editId && typeof idx === "number" && newIterations[idx])
      newIterations[idx].session_starts = schedule;
    else {
      newIterations.push({
        session_starts: schedule,
        attendees: []
      });
    }

    newIterations.sort((iteration1, iteration2) => {
      const startDate1 = new Date(iteration1.session_starts[0]);
      const startDate2 = new Date(iteration2.session_starts[0]);

      if (startDate1 < startDate2) return -1;
      if (startDate1 > startDate2) return 1;
      return 0;
    });

    this.onChange(newIterations);
  };

  handleEditSchedule = iteration => {
    editId = iteration;

    this.setState({
      scheduleClassVisible: true
    });
  };

  showScheduleClass = () => {
    this.setState({
      scheduleClassVisible: true
    });
  };

  hideScheduleClass = () => {
    // reset edit idx
    editId = undefined;

    this.setState({
      scheduleClassVisible: false
    });
  };

  render() {
    const { scheduleClassVisible } = this.state;
    const { userId, iterations, isNew, editMode } = this.props;
    const filteredIterations = iterations
      .filter(iteration => {
        // filter out ended classes for students
        return (
          editMode || isNew || isIterationOngoing(iteration.session_starts)
        );
      })
      .sort((iteration1, iteration2) => {
        const startDate1 = new Date(iteration1.session_starts[0]);
        const startDate2 = new Date(iteration2.session_starts[0]);

        if (startDate1 < startDate2) return -1;
        if (startDate1 > startDate2) return 1;
        return 0;
      });

    const idx = iterations.findIndex(el => el === editId);

    let selectedDays;

    if (editId && iterations[idx]) {
      selectedDays = iterations[idx].session_starts;
    }

    return (
      <Container>
        {/* list of iterations here */}
        <IterationContainer>
          {filteredIterations && filteredIterations.length > 0 ? (
            filteredIterations.map(iteration => {
              // check if user is already enrolled in a class
              const attendees = iteration.attendees.map(attendee =>
                JSON.parse(attendee)
              );
              const enrolled = attendees.find(
                attendee => attendee.userId === userId
              );

              return (
                <IterationItem
                  key={iteration.session_starts[0]}
                  isNew={isNew}
                  editMode={editMode}
                  enrolled={!!enrolled}
                  handleDelete={() => this.handleRemoveSchedule(iteration)}
                  handleEdit={() => this.handleEditSchedule(iteration)}
                >
                  {prettyRange(iteration.session_starts)}
                </IterationItem>
              );
            })
          ) : (
            <p>No upcoming classes.</p>
          )}
        </IterationContainer>
        {/* For the meantime, hide the "Schedule a Class" button when a class
         * already exists. Max. number of classes is 1 per course.
         */}
        {(isNew || editMode) && (
          <Button
            buttontype="simple"
            title="Schedule a Class"
            onClick={this.showScheduleClass}
          />
        )}
        {scheduleClassVisible && (
          <ScheduleClass
            selectedDays={selectedDays}
            handleChange={this.onChangeSchedule}
            hidePopup={this.hideScheduleClass}
          />
        )}
      </Container>
    );
  }
}

IterationView.propTypes = {
  iterations: PropTypes.instanceOf(Array),
  containerOnChange: PropTypes.func,
  changeIndividualValues: PropTypes.func,
  isNew: PropTypes.bool,
  editMode: PropTypes.bool,
  input: PropTypes.instanceOf(Object),
  userId: PropTypes.string
};

IterationView.defaultProps = {
  iterations: [],
  containerOnChange: null,
  changeIndividualValues: null,
  isNew: false,
  editMode: false,
  input: {},
  userId: undefined
};

export default connect(state => ({
  userId: state.user.id
}))(IterationView);
