/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const LastUpdate = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

export const ScheduledClasses = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SyllabusContainer = styled.div`
  margin-bottom: 3rem;
`;
