import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default [
  {
    title: <FontAwesomeIcon icon={["fab", "facebook"]} size="2x" />,
    isExternal: true,
    to: "https://www.facebook.com/AmagiAcademy/"
  },
  {
    title: <FontAwesomeIcon icon={["fab", "twitter"]} size="2x" />,
    isExternal: true,
    to: "https://twitter.com/AmagiAcademy"
  },
  {
    title: <FontAwesomeIcon icon={["fab", "instagram"]} size="2x" />,
    isExternal: true,
    to:
      "https://www.instagram.com/explore/locations/1735127560054759/amagi-academy/?hl=en"
  },
  {
    title: <FontAwesomeIcon icon={["fab", "meetup"]} size="2x" />,
    isExternal: true,
    to: "https://www.meetup.com/AmagiAcademy/"
  }
];
