import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Dependency, ListContainer, Title } from "./styledElements";

class DependencyList extends React.PureComponent {
  onDelete = dependency => () => {
    const { onDelete } = this.props;

    onDelete(dependency);
  };

  render() {
    const { dependencies } = this.props;

    return (
      <Container>
        <Title>Dependencies</Title>
        <ListContainer>
          {Object.keys(dependencies).map(dependency => (
            <Dependency key={`dependency-${dependency}`} title={dependency}>
              <div className="name">{dependency}</div>
              <div className="version">{dependencies[dependency]}</div>
              <div className="actions">
                <FontAwesomeIcon
                  onClick={this.onDelete(dependency)}
                  icon="trash-alt"
                />
              </div>
            </Dependency>
          ))}
        </ListContainer>
      </Container>
    );
  }
}

DependencyList.propTypes = {
  dependencies: PropTypes.instanceOf(Object),
  onDelete: PropTypes.func.isRequired
};

DependencyList.defaultProps = {
  dependencies: {}
};

export default DependencyList;
