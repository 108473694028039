import React from "react";
import { ScreenClassRender } from "react-grid-system";

import {
    Section,
    SectionHeadline,
    SectionBody,
    ReasonCount,
    ReasonContainer,
    ReasonHeadline,
    ReasonBody
} from "./styledElements";

const Reasons = () => (
    <ScreenClassRender
    render={screen => (
        <Section screen={screen} id="reasons">
            <SectionHeadline>5 reasons to <span>choose Amagi Academy</span></SectionHeadline>
            <SectionBody screen={screen}>
                <ReasonContainer>
                    <ReasonCount>#1</ReasonCount>
                    <ReasonHeadline>Extremely Low Cost</ReasonHeadline>
                    <ReasonBody>
                        Building up your knowledge, skills, and network with Amagi Academy 
                        is one of the lowest cost ways to start a new career path.
                    </ReasonBody>
                    <ReasonBody>
                        You don’t need to spend all the time and money you would with
                        universities or legacy bootcamps, and there’s no relocation or 
                        extra fees to worry about.
                    </ReasonBody>
                    <ReasonBody>
                        Residents of certain countries may even get special discounts or 
                        alternative payment options!
                    </ReasonBody>
                </ReasonContainer>
                <ReasonContainer>
                    <ReasonCount>#2</ReasonCount>
                    <ReasonHeadline>Beginner Friendly</ReasonHeadline>
                    <ReasonBody>
                        You don’t need to be an expert to start, and with our history of training 
                        thousands of people of all backgrounds from Asia to America, we're about 
                        as “beginner friendly” as it gets.
                    </ReasonBody>
                    <ReasonBody>
                        If you've been stuck or anxious about the years it takes most people to 
                        become proficient...just ignore those insecurities because they're a part 
                        of learning. 
                    </ReasonBody>
                    <ReasonBody>
                        With Amagi Academy, you can take an easier, and more direct, 
                        approach by relying on the methods and training that our experts have honed 
                        over half of a decade. 
                    </ReasonBody>
                </ReasonContainer>
                <ReasonContainer>
                    <ReasonCount>#3</ReasonCount>
                    <ReasonHeadline>Real Expertise</ReasonHeadline>
                    <ReasonBody>
                        Most tech schools and bootcamps hire inexperienced people on random job 
                        boards who get brief training to follow a script or slides made by others. 
                        Some even try to have other or recent students do most of the teaching.
                    </ReasonBody>
                    <ReasonBody>
                        We think this approach is unsatisfactory and unethical. Our mentors 
                        are real members of our tech teams at AMAGI. They build real, digital 
                        products for real people around the world.
                    </ReasonBody>
                    <ReasonBody>
                        We're proud of our mentors, our students, and our reputation. You are 
                        our legacy, and we want you to be great.
                    </ReasonBody>
                </ReasonContainer>
            </SectionBody>
            <SectionBody screen={screen}>
                <ReasonContainer>
                    <ReasonCount>#4</ReasonCount>
                    <ReasonHeadline>Modern and Relevant</ReasonHeadline>
                    <ReasonBody>
                        The amount of languages, tools, libraries, frameworks, tutorials, videos, 
                        blogs, etc. that are available to new learners can feel overwhelming, and 
                        it's hard to determine which to focus on.
                    </ReasonBody>
                    <ReasonBody>
                        All of our mentors are professional developers, engineers, and data 
                        scientists, and we work with modern companies around the world. We know what 
                        works and how to teach it.
                    </ReasonBody>
                    <ReasonBody>
                        You won't have to sift through the myriad options online and can rest assured 
                        that what you're learning with us is both relevant, powerful, and marketable.
                    </ReasonBody>
                </ReasonContainer>
                <ReasonContainer>
                    <ReasonCount>#5</ReasonCount>
                    <ReasonHeadline>Proven Record</ReasonHeadline>
                    <ReasonBody>
                        Amagi Academy has been around for over half a decade - since 2015! That means 
                        we've been evolving our teaching methods, style, exercises, and more for years 
                        successfully. You get the benefit of all this hard work.
                    </ReasonBody>
                    <ReasonBody>
                        We run some of the largest tech communities in the world. We don't just train 
                        for profit, we host large events, workshops, and meetups bringing in speakers 
                        for tech concepts from around the world. We are obviously much more invested 
                        than most others.
                    </ReasonBody>
                    <ReasonBody>
                        And you'll be learning the exact same way we teach junior hires of our own 
                        teams. Professional training by expert mentors that works because we use 
                        it, too!  
                    </ReasonBody>
                </ReasonContainer>
            </SectionBody>
        </Section>
    )}
    />
  );
  
  export default Reasons;