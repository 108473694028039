/* eslint import/prefer-default-export : off */
import styled from "styled-components";

export const ActivitiesContainer = styled.div.attrs({
  id: "training"
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90vh;
`;
