export const BASIC_HTML = `<!doctype html>
<html lang="en">
  <head>
    <link rel="stylesheet" type="text/css" href="./styles.css" />
  </head>
  <body>
    <div>
      <h2>HTML/CSS/JS Live Editor</h2>
      <h4>Start editing index.html to see changes.</h4>
    </div>
  </body>    
</html>`;

export const BASIC_CSS = `body, html {
  text-align: center;
  width: 100%;
}

h4 {
  font-weight: normal;
}
`;

export const REACT_BASIC_HTML = `<!doctype html>
<html lang="en">
  <body>
    <div id="root"></div>
  </body>    
</html>`;

export const REACT_BASIC_ENTRY = `import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

const App = () => <div>
  <h2>React JS Live Editor</h2>
  <h4>Start editing src/index.js to see changes.</h4>
</div>

ReactDOM.render(<App />, document.getElementById('root'));`;

export const BASIC_NODE = `const koa = require('koa')
const router = require("koa-router")`;
