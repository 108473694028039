/* eslint import/prefer-default-export : off */
import { notify } from "react-notify-toast";
import { COURSE_TAGS } from "constants/course";

export const getBackgroundGradient = tag => {
  try {
    const selected = COURSE_TAGS.find(
      entry => entry.value.toUpperCase() === tag.toUpperCase()
    );

    if (selected) return selected.background;
    return COURSE_TAGS[0].background;
  } catch (err) {
    return COURSE_TAGS[0].background;
  }
};

export const getPreview = image => {
  if (!image) return null;

  const reader = new FileReader();

  return new Promise(resolve => {
    reader.onabort = () => {
      notify.show("File reading was aborted", "error");
      resolve(null);
    };
    reader.onerror = () => {
      notify.show("File reading has failed", "error");
      resolve(null);
    };
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(image);
  });
};
