import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Page } from "./styledElements";

const MAX_ITEMS = 7;
const TRIGGER_VALUE = 5;

class Paginate extends React.PureComponent {
  state = {
    currentPage: 1,
    pageOffset: 0,
    pages: []
  };

  componentDidMount() {
    const { pageOffset } = this.state;

    this.setPages(pageOffset);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { currentPage, pageOffset, pages } = this.state;
    const { onPageChange, maxPage } = this.props;

    if (prevState.currentPage !== currentPage) {
      onPageChange(currentPage);

      if (currentPage >= TRIGGER_VALUE) {
        let offset = currentPage - (TRIGGER_VALUE - 1);

        const lastItem = pages[MAX_ITEMS - 1];
        if (offset + MAX_ITEMS >= maxPage && lastItem + 1 > maxPage) {
          offset = pageOffset;
        }

        this.setPages(offset);
      } else {
        const offset = pageOffset > 0 ? pageOffset - 1 : 0;

        this.setPages(offset);
      }
    }

    if (prevProps.maxPage !== maxPage) {
      this.setPages(0);
    }
  };

  setPages = offset => {
    const { maxPage } = this.props;

    const pages = Array.from(new Array(MAX_ITEMS))
      .map((entry, index) => offset + index + 1)
      .filter(entry => entry <= maxPage);

    this.setState({
      pages,
      pageOffset: offset
    });
  };

  onNext = () => {
    const { currentPage } = this.state;
    const { maxPage } = this.props;

    const nextPage = currentPage >= maxPage ? currentPage : currentPage + 1;
    this.onSelectPage(nextPage)();
  };

  onPrevious = () => {
    const { currentPage } = this.state;

    this.onSelectPage(currentPage > 1 ? currentPage - 1 : currentPage)();
  };

  onFirst = () => {
    this.setPages(0);
    this.onSelectPage(1)();
  };

  onLast = () => {
    const { maxPage } = this.props;

    const offset = maxPage - MAX_ITEMS;
    this.setPages(offset);
    this.onSelectPage(maxPage)();
  };

  onSelectPage = currentPage => () => {
    this.setState({
      currentPage
    });
  };

  reset = () => {
    this.setState({
      currentPage: 1,
      pageOffset: 0,
      pages: []
    });
  };

  render() {
    const { currentPage, pages } = this.state;

    return (
      <Container>
        <Page onClick={this.onFirst}>
          <FontAwesomeIcon icon="angle-double-left" />
        </Page>
        <Page onClick={this.onPrevious}>
          <FontAwesomeIcon icon="angle-left" />
        </Page>
        {pages.map(page => (
          <Page
            isActive={currentPage === page}
            key={`page-${page}`}
            onClick={this.onSelectPage(page)}
          >
            {page}
          </Page>
        ))}
        <Page onClick={this.onNext}>
          <FontAwesomeIcon icon="angle-right" />
        </Page>
        <Page onClick={this.onLast}>
          <FontAwesomeIcon icon="angle-double-right" />
        </Page>
      </Container>
    );
  }
}

Paginate.propTypes = {
  onPageChange: PropTypes.func,
  maxPage: PropTypes.number.isRequired
};

Paginate.defaultProps = {
  onPageChange: () => null
};

export default Paginate;
