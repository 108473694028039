/**
 * Updates the course with an unpublished status and
 * will not be available for public viewing.
 */

import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import { PUBLISH_COURSE } from "api/mutations/course";
import { GET_COURSE } from "api/queries/course";

import { notify } from "react-notify-toast";
import { getGraphqlError } from "utils/general/errors";

import Button from "../../../Button";

class UnPublishCourse extends React.PureComponent {
  onError = error => {
    notify.show(getGraphqlError(error), "error");
  };

  onCompleted = () => {
    notify.show(
      "Your course is now unpublished. It is not available in public at the moment.",
      "info"
    );
  };

  render() {
    const { id } = this.props;

    return (
      <Mutation
        mutation={PUBLISH_COURSE}
        onError={this.onError}
        onCompleted={this.onCompleted}
        variables={{
          id,
          published: false
        }}
        refetchQueries={[
          {
            query: GET_COURSE,
            variables: {
              id
            }
          }
        ]}
      >
        {(unPublishCourse, { loading }) => (
          <Button
            size="large"
            title="Unpublish Course"
            loadingText="Unpublishing..."
            loading={loading}
            disabled={loading}
            onClick={unPublishCourse}
          />
        )}
      </Mutation>
    );
  }
}

UnPublishCourse.propTypes = {
  id: PropTypes.string.isRequired
};

export default UnPublishCourse;
