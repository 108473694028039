/* eslint import/prefer-default-export : off */
import styled from "styled-components";

import { Row } from "react-grid-system";

export const ActionsContainer = styled(Row).attrs({
  align: "center",
  justify: "end",
  className: "actions-container"
})`
  position: absolute;
  right: 30px;
  margin-top: 5px;
  opacity: 0.5;
  transition: opacity 275ms ease-out;

  button {
    margin-left: 5px;
    align-self: flex-end;
  }
`;

export const OutputContainer = styled.div`
  white-space: pre;
  padding: 5px;
  height: calc(100% - 10px);
  overflow: auto;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-size: 1rem;

  &:hover {
    .actions-container {
      opacity: 1;
      transition: opacity 275ms ease-in;
    }
  }
`;
