import styled from "styled-components";
import Colors from "theme/colors";

export const Container = styled.div`
  display: flex;
  align-items: space-around;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  width: 100%;
`;

export const NavigationSelect = styled.button`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: none;
  position: absolute;
  font-size: 1.5rem;
  z-index: 20;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: 0.1s ease-in;

  &:first-of-type {
    left: 10%;
  }

  &:last-of-type {
    right: 10%;
  }

  &:active,
  &:hover {
    transform: translateY(5px);
  }
`;

export const ChoiceList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 10%;
  padding: 0;
  transition: transform 0.3s ease-in;
`;

export const ChoiceItem = styled.li.attrs({
  className: "choice-item"
})`
  display: flex;
  margin: 0rem 15px;
  position: relative;
  z-index: 5;
  clip-path: inset(-10px -10px 0px -10px);

  svg {
    transform: scaleY(-1) scaleX(5);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
  }

  ${props =>
    ["sm", "xs"].includes(props.screen)
      ? `
      display: none;

      button {
        outline: none;
        border: none;
        background: none;
        padding: 0;
      }

   ${props.isFocused &&
     `
     display: flex;
     align-items: center;
     justify-content: center;
     min-width: 100%;
   `}
`
      : `
      button {
        min-width: 200px;
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1rem 2rem;
        padding-bottom: 0;
        margin: 0;
        border: none;
        outline: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: linear-gradient(#f2f2f2 60%, #e0e0e0);
        border: solid 5px inherit;
        font-size: 1rem;
        color: #787878;
        text-align: center;
        font-family: inherit;
        transition: all 0.2s ease-in;

        @keyframes bounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(5px);
          }
          60% {
            transform: translateY(3px);
          }
        }

        h2 {
          font-weight: 500;
          margin-bottom: 10px;
          font-size: 1rem;
        }

        div {
          text-transform: lowercase;
        }
      }

      button:hover {
        color: ${props.color};
      }

      button.focused {
        border-bottom: none;
        background: ${Colors.background};
        border: solid 5px inherit;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        color: ${props.color};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
`}
`;

ChoiceItem.defaultProps = {
  color: "rgba(0,222,250,1)"
};

ChoiceList.defaultProps = {
  scrollIndex: 0
};
