import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Colors from "theme/colors";

import { NavHashLink as UnstyledLink } from "react-router-hash-link";

const linkStyles = css`
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  opacity: 1;
  transition: opacity 275ms ease-out;
  margin-bottom: 30px;
  display: inline-block;

  svg {
    margin-left: 5px;
    transform: translateX(0);
    transition: transform 275ms ease-out;
  }

  &:hover {
    opacity: 0.7;
    transition: opacity 275ms ease-in;

    svg {
      transform: translateX(5px);
      transition: transform 275ms ease-in;
    }
  }
`;

const ExternalLink = styled.a`
  ${linkStyles};
  color: ${props => props.color || Colors.primary};
  ${props => props.customstyles};
`;

const InternalLink = styled(UnstyledLink)`
  ${linkStyles};
  color: ${props => props.color || Colors.primary};
  ${props => props.customstyles};
`;

const AppLink = ({ isExternal, to, ...props }) =>
  !isExternal ? (
    <InternalLink to={to} {...props} />
  ) : (
    <ExternalLink target="_blank" href={to} {...props} />
  );

AppLink.propTypes = {
  isExternal: PropTypes.bool,
  to: PropTypes.string
};

AppLink.defaultProps = {
  isExternal: false,
  to: null
};

export default AppLink;
