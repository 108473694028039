/* eslint import/prefer-default-export : off */
import { ApolloClient, ApolloLink, HttpLink } from "apollo-boost";
import { WebSocketLink } from "apollo-link-ws";
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from "apollo-cache-inmemory";

import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";

import introspectionQueryResultData from "../../fragmentTypes.json";

export const setupClient = () => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_BACKEND_URI}/graphql`
  });

  const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_BACKEND_WS_URI}/graphql`,
    options: {
      reconnect: true
    }
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const authLink = new ApolloLink((operation, next) => {
    const token = localStorage.getItem("idToken");

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });

    return next(operation);
  });

  const cache = new InMemoryCache({ fragmentMatcher });
  const client = new ApolloClient({
    link: authLink.concat(link),
    cache
  });

  return client;
};
