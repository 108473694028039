import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScreenClassRender, Visible } from "react-grid-system";
import Colors from "theme/colors";
import { Popover } from "components";

import { LinksContainer, LoginLink } from "./styledElements";

export default () => (
  <ScreenClassRender
    render={screen => (
      <Popover
        style={{
          width: "95%",
          margin: "0 auto"
        }}
        preferPlace="below"
        // eslint-disable-next-line prettier/prettier
        body={
          <PopoverContent>
            <LinksContainer screen={screen}>
              <NavLink to="/catalog" screen={screen}>
                learn
              </NavLink>
              <NavLink to="/live-courses" screen={screen}>
                live courses
              </NavLink>
              <a href="https://amagi.io">hire us</a>
              <a href="https://techpool.io">jobs</a>
              <a href="/blog" screen={screen}>
                blog
              </a>
              <NavLink to="/terms" screen={screen}>
                FAQ
              </NavLink>
              <Visible xl lg md>
                <LoginLink to="/catalog">
                  <FontAwesomeIcon icon="user" />
                  enter
                </LoginLink>
              </Visible>
            </LinksContainer>
          </PopoverContent>
          // eslint-disable-next-line prettier/prettier
        }
      >
        <Button>
          <FontAwesomeIcon icon="bars" />
        </Button>
      </Popover>
    )}
  />
);

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  background-color: ${Colors.background};
`;

const Button = styled.button`
  border: 0;
  padding: 10px 7px;
  background: none;
  cursor: pointer;
  outline: none;
`;
