import React from "react";
import PropTypes from "prop-types";

import { notify } from "react-notify-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPreview } from "utils/general/image";

import {
  DropzoneWrapper,
  Header,
  StyledDropzone,
  Wrapper,
  TriggerWrapper
} from "./UploadersComponents";

class ImageUploader extends React.PureComponent {
  state = {
    value: null,
    preview: null
  };

  componentDidMount = () => {
    const { value } = this.props;

    if (value) {
      this.reset();
    }
  };

  componentDidUpdate = prevProps => {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.reset();
    }
  };

  setPreview = preview =>
    this.setState({
      preview
    });

  handleImageDrop = async acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      if (acceptedFiles[0].size > 2000000) {
        notify.show("File size should not exceed 2MB. ", "error");
        return;
      }

      const preview = await getPreview(acceptedFiles[0]);
      this.setPreview(preview);

      this.setState({
        value: acceptedFiles[0]
      });
    }
  };

  getValue = () => {
    const { value } = this.state;
    return value;
  };

  reset = () => {
    const { value } = this.props;

    this.setState({
      value
    });
  };

  render() {
    const { value, preview } = this.state;
    let displayPreview = null;

    if (value) {
      displayPreview = typeof value === "string" ? value : value.preview;
    }

    return (
      <Wrapper>
        <Header>
          <FontAwesomeIcon icon="info-circle" />
          <span>Please drag or select the image you want to upload below.</span>
        </Header>
        <DropzoneWrapper background={displayPreview || preview}>
          <StyledDropzone onDrop={this.handleImageDrop} accept="image/*">
            {({ getRootProps, getInputProps }) => (
              <TriggerWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                {!value && (
                  <>
                    <FontAwesomeIcon icon="image" size="4x" />
                    <div>Drag your image or click here</div>
                  </>
                )}
              </TriggerWrapper>
            )}
          </StyledDropzone>
        </DropzoneWrapper>
      </Wrapper>
    );
  }
}

ImageUploader.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string])
};

ImageUploader.defaultProps = {
  value: null
};

export default ImageUploader;
