import styled from "styled-components";

import Colors, { GRADIENTS } from "theme/colors";

export const AdvantagesContainer = styled.div`
  height: 100%;
  padding: 1rem 10%;
  max-width: unset;

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
      padding: 1%;
    `}
`;

export const AdvantagesHeadline = styled.h2`
  padding: 2rem;
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
  font-family: "Open Sans", sans-serif;

  span {
    ${GRADIENTS.software};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const AdvantagesSubline = styled.p`
  font-size: 1rem;
  text-align: left;
  font-family: "Open Sans", sans-serif;
`;

export const AdvantagesCol = styled.div`
  margin: 0 2rem;

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
  }

  div {
    font-size: 1rem;
    justify-content: center;
  }
`;

export const AdvantagesList = styled.div`
  margin-top: 2rem;

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 15rem;
  }

  ul li {
    list-style-type: none;
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }

  ul li:before {
    font-family: 'FontAwesome';
    font-size: 1.5rem;
    content: '\f00c';
    margin-right: 1rem;
    color: ${Colors.green};
  }

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
    margin-top: 1rem;

      ul {
        height: unset;
        padding-left: 0;
        margin-top: 0;
      }

      ul li, ul li:before {
        font-size: 1rem;
        margin-right: .5rem;
      }
    `}
`;