/* eslint import/prefer-default-export : off */
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

/**
 * Returns a human friendly date.
 * @param {number|date} input
 * @returns {string}
 */
export const prettyPrint = input => {
  if (
    [undefined, null].includes(input) ||
    ![Date, Number].includes(input.constructor)
  )
    return "";

  const date = input.constructor === Date ? input : new Date(input);

  const month = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  return `${months[month]} ${day}, ${year}`;
};

export const prettyRange = (days, time) => {
  if (!days.length) return "";

  const firstDay = typeof days[0] === "string" ? new Date(days[0]) : days[0];

  const hour = time ? Number(time.split(":")[0]) : firstDay.getHours();
  const minute = time ? Number(time.split(":")[1]) : firstDay.getMinutes();

  const AmOrPm = hour >= 12 ? "PM" : "AM";
  const hours = hour % 12 || 12;

  time = `${hours}:${minute < 10 ? "0" + minute : minute} ${AmOrPm}`;

  const dates = days.reduce((acc, date) => {
    date = typeof date === "string" ? new Date(date) : date;
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    if (acc[year]) {
      if (acc[year][month]) acc[year][month].push(day);
      else acc[year][month] = [day];
    } else
      acc[year] = {
        [month]: [day]
      };

    return acc;
  }, {});

  // format object of dates
  const years = Object.keys(dates);
  const yearString = years.map(yearKey => {
    const year = dates[yearKey];
    const months = Object.keys(year);

    const monthString = months.map(monthKey => {
      const month = dates[yearKey][monthKey].sort((a, b) => a - b);

      // separate array of dates by consecutive ranges
      const ranges = month.reduce((acc, curr, idx) => {
        const prev = month[idx - 1];

        // check if number is consecutive
        if (prev && curr === prev + 1) {
          const prevRange = acc[acc.length - 1];
          // push current number into array, if an array already exists
          if (Array.isArray(prevRange)) {
            prevRange.push(curr);
            return acc;
          }
          // remove last element from array
          acc.pop();
          acc.push([prev, curr]);
          return acc;
        }
        acc.push(curr);
        return acc;
      }, []);

      const rangesString = ranges.map(range => {
        if (Array.isArray(range))
          return `${range[0]}-${range[range.length - 1]}`;
        return range;
      });

      return `${monthKey} ${rangesString.join(", ")}`;
    });

    return `${monthString.join(", ")}, ${yearKey}`;
  });

  return `${time} on ${yearString.join("; ")}`;
};

export const isIterationOngoing = sessions => {
  return sessions.every(session => new Date() < new Date(session));
};
