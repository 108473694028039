import React from "react";
import PropTypes from "prop-types";
import AceEditor from "react-ace";

import "brace/theme/twilight";
import "./CodeEditor.css";

class CodeEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      value
    };
  }

  componentDidUpdate = prevProps => {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.setState({
        value
      });
    }
  };

  onChange = value => {
    const { onChange } = this.props;

    this.setState({ value });
    onChange(value);
  };

  getValue = () => {
    const { value } = this.state;
    return value;
  };

  getLines = () => {
    return this.editor.editor.renderer.session.$wrapData.reduce(
      (prev, next) => {
        /* eslint-disable-next-line */
        prev += Array.isArray(next) ? next.length + 1 : 0;
        return prev;
      },
      0
    );
  };

  render() {
    const { value } = this.state;

    return (
      <AceEditor
        ref={instance => {
          this.editor = instance;
        }}
        debounceChangePeriod={500}
        theme="twilight"
        showPrintMargin={false}
        editorProps={{ $blockScrolling: true }}
        {...this.props}
        onChange={this.onChange}
        value={value}
        width="100%"
        wrapEnabled
        showInvisibles
      />
    );
  }
}

CodeEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

CodeEditor.defaultProps = {
  value: "",
  onChange: () => null
};

export default CodeEditor;
