import React, { Component } from "react";
import PropTypes from "prop-types";
import UnstyledTextarea from "react-textarea-autosize";
import styled from "styled-components";
import enhanceWithClickOutside from "react-click-outside";

import Colors from "theme/colors";

import { validInput } from "utils/general/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditButton } from "components/Button";
import { Bullet, Container, ErrorContainer, styles } from "./common";

class EditableText extends Component {
  state = { editing: false };

  toggleEdit = () => {
    const { editing } = this.state;
    const { onEdit, enableInlineEdit, startEdit, hasParentError } = this.props;

    if (hasParentError) return;

    if (!editing) {
      onEdit({ type: "text" });
    }

    if (enableInlineEdit) {
      if (!editing) startEdit();

      this.setState({ editing: !editing });
    }
  };

  onChange = e => {
    let { value } = e.target;
    const {
      singleLine,
      inputType,
      input: { onChange },
      containerOnChange
    } = this.props;

    if (!validInput({ value, inputType })) {
      e.preventDefault();
      return;
    }

    if (singleLine) value = value.replace("\n", "");
    onChange(value);

    if (containerOnChange) {
      containerOnChange(value);
    }
  };

  stopEditOnEsc = e => {
    const {
      stopEdit,
      meta: { touched, error }
    } = this.props;

    if (/escape/i.test(e.key) && touched && !error) {
      stopEdit();
      this.toggleEdit();
    }
  };

  handleClickOutside = () => {
    const { editing } = this.state;
    const {
      stopEdit,
      meta: { touched, error }
    } = this.props;

    if (editing && touched && !error) {
      stopEdit();
      this.toggleEdit();
    }
  };

  displayValue = () => {
    const {
      fallbackDisplayValue,
      showBullet,
      input: { value }
    } = this.props;

    const displayText = !value ? fallbackDisplayValue : value;

    if (!showBullet) return displayText;

    return (
      <span>
        <Bullet>•</Bullet>
        {displayText}
      </span>
    );
  };

  render() {
    const { editing } = this.state;
    const {
      containerStyle,
      type,
      input,
      meta: { touched, error },
      textStyle = {},
      editing: controlledEditing,
      allowEdit,
      centerItems,
      showEditAlways,
      showTextError,
      inputType
    } = this.props;

    const { value } = input;

    let editableStyle = { ...styles.text, ...textStyle };

    const editMode =
      typeof controlledEditing === "boolean"
        ? controlledEditing && editing
        : editing;

    if (!editing) {
      editableStyle = {
        ...editableStyle,
        ...styles.displayText
      };
    }

    const verifyPrice =
      inputType === "price" && value.length > 0 && !isNaN(Number(value));

    return (
      <>
        <Container
          showEditAlways={showEditAlways}
          editing={editing}
          allowEdit={allowEdit}
          style={containerStyle}
          centerItems={centerItems}
        >
          {!editMode && verifyPrice && "$"}
          {editMode ? (
            <Textarea
              {...input}
              autoFocus
              onKeyDown={this.stopEditOnEsc}
              value={value}
              style={editableStyle}
              onChange={this.onChange}
              error={touched && error ? 1 : 0}
            />
          ) : (
            React.createElement(
              type,
              {
                style: {
                  ...editableStyle,
                  cursor: allowEdit ? "pointer" : "default",
                  display: "inline",
                  width: "auto"
                },
                className: "display-text",
                onClick: allowEdit ? this.toggleEdit : null
              },
              this.displayValue()
            )
          )}
          {!editMode && allowEdit && (
            <EditButton onClick={allowEdit ? this.toggleEdit : null}>
              <FontAwesomeIcon icon="pen" />
            </EditButton>
          )}
          {touched && error && showTextError && (
            <ErrorContainer>{error}</ErrorContainer>
          )}
        </Container>
      </>
    );
  }
}

EditableText.propTypes = {
  onEdit: PropTypes.func,
  startEdit: PropTypes.func,
  stopEdit: PropTypes.func,
  enableInlineEdit: PropTypes.bool,
  containerStyle: PropTypes.instanceOf(Object),
  textStyle: PropTypes.instanceOf(Object),
  type: PropTypes.string,
  allowEdit: PropTypes.bool,
  centerItems: PropTypes.bool,
  showEditAlways: PropTypes.bool,
  editing: PropTypes.bool,
  showBullet: PropTypes.bool,
  singleLine: PropTypes.bool,
  inputType: PropTypes.string,
  input: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object),
  containerOnChange: PropTypes.func,
  showTextError: PropTypes.bool,
  hasParentError: PropTypes.bool,
  fallbackDisplayValue: PropTypes.string
};

EditableText.defaultProps = {
  onEdit: () => null,
  startEdit: () => null,
  stopEdit: () => null,
  enableInlineEdit: false,
  containerStyle: {},
  textStyle: {},
  inputType: null,
  type: null,
  allowEdit: true,
  centerItems: false,
  showEditAlways: false,
  editing: false,
  showBullet: false,
  singleLine: false,
  input: {},
  meta: {},
  containerOnChange: null,
  showTextError: true,
  hasParentError: false,
  fallbackDisplayValue: null
};

export default enhanceWithClickOutside(EditableText);

const Textarea = styled(UnstyledTextarea)`
  border: 2px solid ${Colors.primary};

  ${props =>
    props.error &&
    `
    border-color: ${Colors.error};
  `}
`;
