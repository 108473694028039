import React from "react";
import styled, { css } from "styled-components";

import Button from "../../../Button";

export const Container = styled.div`
  margin-top: 25px;
  padding: 0 0.8rem;
`;

export const AddButton = styled(props => <Button {...props} />).attrs({
  size: "extra-small",
  customstyles: css`
    font-size: 0.8rem;
    width: 100%;
    box-shadow: none;
    color: rgb(255, 255, 255);
    background-color: #2f3335;
  `
})``;

export const ResultsContainer = styled.div`
  min-height: 350px;
  height: 350px;
  max-height: 350px;
  overflow-y: auto;
`;

export const Dependency = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .name,
  .version {
    color: rgb(51, 51, 51);
    font-size: 1rem;
  }

  .name {
    font-weight: bold;
    margin-bottom: 7px;
  }

  .description {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.8rem;
    max-width: 300px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;
