import gql from "graphql-tag";

export const CREATE_LAYOUT = gql`
  mutation CreateLayout($layouts: [CreateLayoutInput]) {
    createLayout(layouts: $layouts) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const UPDATE_LAYOUT = gql`
  mutation UpdateLayout(
    $id: ID!
    $type: String
    $subtype: String
    $position: Int
    $orderIndex: Int
    $depth: Int
    $markdown: String
    $title: String
    $media: String
  ) {
    updateLayout(
      id: $id
      layout: {
        type: $type
        subtype: $subtype
        position: $position
        order_index: $orderIndex
        depth: $depth
        markdown: $markdown
        title: $title
        media: $media
      }
    ) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const DELETE_LAYOUT = gql`
  mutation DeleteLayout($id: ID!) {
    deleteLayout(id: $id) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;
