import React from "react";
import PropTypes from "prop-types";

import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import { isAuthor, isCourseOwner } from "utils/user";
import { Container } from "react-grid-system";

import { COURSE_TYPES } from "constants/course";

import { FooterContainer, ButtonsContainer } from "./styledElements";

import Button from "../../../Button";
import EnrollCourse from "../EnrollCourse";
import DeleteCourse from "../DeleteCourse";
import PublishCourse from "../PublishCourse";
import UnPublishCourse from "../UnPublishCourse";
import SaveCourse from "../SaveCourse";
import CreateCourse from "../CreateCourse";
import UpdateCourse from "../UpdateCourse";

class Footer extends React.PureComponent {
  openCourseAuthor = () => {
    const { id } = this.props;
    window.location.href = `/class/${id}/edit`;
  };

  openCourseStudent = () => {
    const { id, userId } = this.props;

    const tagManagerArgs = {
      dataLayer: {
        event: `openCourse_${id}`,
        courseId: id,
        userId
      },
      dataLayerName: "PageDataLayer"
    };

    TagManager.dataLayer(tagManagerArgs);

    window.location.href = `/class/${id}`;
  };

  render() {
    const {
      id,
      loading,
      isValidForm,
      isExistingCourse,
      userRole,
      userId,
      author,
      image,
      price,
      type,
      iterations,
      initialSyllabus,
      syllabus,
      deletedSyllabus,
      published,
      reset,
      refetch,
      togglePaymentForm
    } = this.props;

    const isSelfStudy = type === COURSE_TYPES.SELF_STUDY;
    const isEnrolled = iterations.some(iteration => {
      const attendees = iteration.attendees.map(attendee =>
        JSON.parse(attendee)
      );
      return !!attendees.find(attendee => attendee.userId === userId);
    });

    return (
      <FooterContainer>
        <Container>
          <ButtonsContainer>
            {id &&
              isAuthor(userRole) &&
              isCourseOwner({ courseAuthor: author, userId }) && (
                <UpdateCourse
                  id={id}
                  type={type}
                  image={image}
                  reset={reset}
                  refetch={refetch}
                  iterations={iterations}
                  initialSyllabus={initialSyllabus}
                  deletedSyllabus={deletedSyllabus}
                  isValidForm={isValidForm}
                />
              )}
            {!id && isAuthor(userRole) && (
              <>
                <CreateCourse
                  image={image}
                  iterations={iterations}
                  type={type}
                  syllabus={syllabus}
                  reset={reset}
                  isValidForm={isValidForm}
                />
                <SaveCourse
                  image={image}
                  iterations={iterations}
                  type={type}
                  syllabus={syllabus}
                  reset={reset}
                  isValidForm={isValidForm}
                />
              </>
            )}
            {isExistingCourse &&
              !loading &&
              isCourseOwner({ courseAuthor: author, userId }) && (
                <>
                  {isSelfStudy && (
                    <Button
                      title="Open Course"
                      size="large"
                      onClick={this.openCourseAuthor}
                    />
                  )}

                  {published ? (
                    <UnPublishCourse id={id} />
                  ) : (
                    <PublishCourse id={id} />
                  )}

                  <DeleteCourse id={id} type={type} />
                </>
              )}
            {id && !isCourseOwner({ courseAuthor: author, userId }) && (
              <EnrollCourse
                type={type}
                id={id}
                price={price}
                onOpen={this.openCourseStudent}
                author={author}
                userId={userId}
                togglePaymentForm={togglePaymentForm}
                isEnrolled={isEnrolled}
              />
            )}
          </ButtonsContainer>
        </Container>
      </FooterContainer>
    );
  }
}

Footer.propTypes = {
  userRole: PropTypes.string,
  userId: PropTypes.string.isRequired,
  id: PropTypes.string,
  author: PropTypes.string,
  loading: PropTypes.bool,
  isValidForm: PropTypes.bool,
  isExistingCourse: PropTypes.bool,
  published: PropTypes.bool,
  price: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  iterations: PropTypes.instanceOf(Array),
  initialSyllabus: PropTypes.instanceOf(Array),
  syllabus: PropTypes.instanceOf(Array),
  deletedSyllabus: PropTypes.instanceOf(Array),
  reset: PropTypes.func.isRequired,
  refetch: PropTypes.func,
  togglePaymentForm: PropTypes.func.isRequired
};

Footer.defaultProps = {
  id: null,
  author: null,
  userRole: null,
  loading: false,
  isValidForm: false,
  isExistingCourse: false,
  published: false,
  price: null,
  image: null,
  type: null,
  initialSyllabus: [],
  syllabus: [],
  iterations: [],
  deletedSyllabus: [],
  refetch: () => {}
};

export default connect(state => ({
  userRole: state.user.role,
  userId: state.user.id
}))(Footer);
