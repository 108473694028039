import {
  BASIC_HTML,
  BASIC_CSS,
  REACT_BASIC_ENTRY,
  REACT_BASIC_HTML
} from "./codeSamples";
import { LAYOUT_CODES } from "./layout";

const FILE_ICONS = {
  js: { icon: "js", color: "#FFCA28" },
  html: { icon: "html5", color: "#E44D26" },
  css: { icon: "css3", color: "#42A5F5" },
  "package.json": { icon: "npm", color: "#CB3837" },
  folder: { icon: "folder", color: "#4A616C" },
  "folder-open": { icon: "folder-open", color: "#4A616C" }
};

const EXCLUDED_FILE_DELETION = [
  "/src/index.js",
  "/public/index.html",
  "/package.json"
];

const SANDBOX_TEMPLATE_NAMES = {
  react: "create-react-app",
  parcel: "parcel",
  js: "nodejs"
};

const SANDBOX_TEMPLATES = {
  [SANDBOX_TEMPLATE_NAMES.react]: {
    entry: "/src/index.js",
    files: {
      "/public/index.html": {
        code: REACT_BASIC_HTML
      },
      "/src/index.js": {
        code: REACT_BASIC_ENTRY
      },
      "/src/index.css": {
        code: BASIC_CSS
      }
    },
    dependencies: {
      react: "latest",
      "react-dom": "latest",
      "prop-types": "latest",
      redux: "latest",
      "redux-saga": "latest"
    }
  },
  [SANDBOX_TEMPLATE_NAMES.parcel]: {
    entry: "/index.html",
    files: {
      "/index.html": {
        code: BASIC_HTML
      },
      "/styles.css": {
        code: BASIC_CSS
      }
    },
    dependencies: {}
  }
};

const SANDBOX_TEMPLATE_WITH_NPM = {
  [SANDBOX_TEMPLATE_NAMES.react]: true,
  [SANDBOX_TEMPLATE_NAMES.parcel]: false
};

const COMMON_SANDBOXES = [LAYOUT_CODES.HTML, LAYOUT_CODES.REACT];

export {
  FILE_ICONS,
  EXCLUDED_FILE_DELETION,
  SANDBOX_TEMPLATES,
  SANDBOX_TEMPLATE_WITH_NPM,
  COMMON_SANDBOXES,
  SANDBOX_TEMPLATE_NAMES
};
