/* eslint import/prefer-default-export : off */

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBoxes,
  faAlignJustify,
  faAlignLeft,
  faBars,
  faCode,
  faBookOpen,
  faChevronLeft,
  faChevronRight,
  faLaptop,
  faPeopleCarry,
  faHandshake,
  faHandHoldingUsd,
  faHandsHelping,
  faChalkboardTeacher,
  faHands,
  faImage,
  faVideo,
  faInfoCircle,
  faCaretDown,
  faSyncAlt,
  faTimes,
  faSpinner,
  faCheckCircle,
  faExclamationCircle,
  faPen,
  faLayerGroup,
  faPollH,
  faSquare,
  faSearch,
  faAngleDown,
  faFolder,
  faFolderOpen,
  faFileCode,
  faFolderPlus,
  faFile,
  faTrashAlt,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faCompress,
  faExpand,
  faPlay,
  faCubes,
  faPlus,
  faBug,
  faLock,
  faThumbsUp,
  faFistRaised,
  faUser,
  faServer,
  faComments,
  faAward,
  faChartPie,
  faObjectGroup,
  faTerminal,
  faForward,
  faCloud,
  faTable,
  faTh,
  faShareAlt,
  faProjectDiagram,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

export const setupIcons = () => {
  library.add(faBoxes);
  library.add(faAlignJustify);
  library.add(faAlignLeft);
  library.add(faBars);
  library.add(faCode);
  library.add(faBookOpen);
  library.add(faChevronLeft);
  library.add(faChevronRight);
  library.add(faLaptop);
  library.add(faPeopleCarry);
  library.add(faHandshake);
  library.add(faHandHoldingUsd);
  library.add(faHandsHelping);
  library.add(faChalkboardTeacher);
  library.add(faHands);
  library.add(fab);
  library.add(faImage);
  library.add(faVideo);
  library.add(faInfoCircle);
  library.add(faCaretDown);
  library.add(faSyncAlt);
  library.add(faTimes);
  library.add(faSpinner);
  library.add(faCheckCircle);
  library.add(faExclamationCircle);
  library.add(faPen);
  library.add(faLayerGroup);
  library.add(faPollH);
  library.add(faSquare);
  library.add(faSearch);
  library.add(faAngleDown);
  library.add(faFolder);
  library.add(faFolderOpen);
  library.add(faFileCode);
  library.add(faFolderPlus);
  library.add(faFile);
  library.add(faTrashAlt);
  library.add(faAngleLeft);
  library.add(faAngleRight);
  library.add(faAngleDoubleLeft);
  library.add(faAngleDoubleRight);
  library.add(faCompress);
  library.add(faExpand);
  library.add(faPlay);
  library.add(faCubes);
  library.add(faPlus);
  library.add(faBug);
  library.add(faLock);
  library.add(faThumbsUp);
  library.add(faFistRaised);
  library.add(faUser);
  library.add(faServer);
  library.add(faComments);
  library.add(faAward);
  library.add(faChartPie);
  library.add(faObjectGroup);
  library.add(faTerminal);
  library.add(faForward);
  library.add(faCloud);
  library.add(faTable);
  library.add(faTh);
  library.add(faShareAlt);
  library.add(faProjectDiagram);
  library.add(faEnvelope);
};
