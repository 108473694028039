import styled from "styled-components";

import { GRADIENTS } from "theme/colors";

export const Container = styled.div`
  z-index: -1;
`;

export const Top = styled.div`
  -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  width: ${props => (["xl", "lg"].includes(props.screen) ? "50vw" : "95vw")};
  height: ${props => (["xl", "lg"].includes(props.screen) ? "50vw" : "95vw")};
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  ${GRADIENTS.blue}
`;

export const Right = styled.div`
  transform: skew(0deg, -45deg);
  width: 100%;
  height: 95%;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: -1;
  ${GRADIENTS.purple}
`;

export const Left = styled.div`
  transform: skew(0deg, 45deg);
  width: 100%;
  height: 95%;
  position: absolute;
  top: 170%;
  left: 0;
  z-index: -1;
  ${GRADIENTS.orange}
`;
