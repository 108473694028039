import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Wrapper } from "./RendererComponents";

const StyledVideo = styled.video`
  width: calc(100% - 20px);
`;

const VideoRenderer = props => (
  <Wrapper>
    <StyledVideo controls>
      <source type="video/mp4" {...props} />
      Your browser does not support the video tag.
    </StyledVideo>
  </Wrapper>
);

VideoRenderer.propTypes = {
  src: PropTypes.string
};

VideoRenderer.defaultProps = {
  src: "https://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4"
};

export default VideoRenderer;
