import styled from "styled-components";
import { xxl } from "theme/breakpoints";

export const Container = styled.div`
  font-size: 12px;
  font-weight: 700;
  padding: 5px 10px;
  padding-right: ${props => (props.closable ? `30px` : `10px`)};
  border-radius: 4px;
  background: ${props => props.background};
  color: rgb(255, 255, 255);
  display: table;
  margin-right: 10px;
  position: relative;
  cursor: pointer;

  @media only screen and (min-width: ${xxl}px) {
    font-size: 1.2rem;
  }
`;

export const CloseContainer = styled.div.attrs({
  className: "close-icon",
  title: "Remove"
})`
  cursor: pointer;
  position: absolute;
  padding: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  right: 0;
  top: 0;
  opacity: 0.5;
  height: calc(100% - 10px);
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
`;
