import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Colors from "theme/colors";
import LogoImg from "assets/images/logo.png";

const LogoSmall = styled.div`
  margin-top: 15px;

  .rectangle {
    height: 15px;
    width: 40px;
    background-color: ${props => (props.color ? props.color : Colors.primary)};
    transform: rotate(-45deg);
    border-radius: 1px;
  }
  .triangle {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 8px solid
      ${props => (props.color ? props.color : Colors.primary)};
    position: relative;
    left: 32px;
    top: -12px;
    border-radius: 1px;
  }
  .trapezoid {
    border-bottom: 20px solid
      ${props => (props.color ? props.color : Colors.primary)};
    border-right: 20px solid transparent;
    height: 0;
    width: 20px;
    transform: rotate(-45deg);
    position: relative;
    left: 16px;
    top: -10px;
    border-radius: 0 0 1px 1px;
  }
`;

const LogoMedium = styled.div`
  margin-top: 18px;

  .rectangle {
    height: 18px;
    width: 52px;
    background-color: ${props => (props.color ? props.color : Colors.primary)};
    transform: rotate(-45deg);
    border-radius: 1px;
  }
  .triangle {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 13px solid
      ${props => (props.color ? props.color : Colors.primary)};
    position: relative;
    left: 40px;
    top: -17px;
    border-radius: 1px;
  }
  .trapezoid {
    border-bottom: 27px solid
      ${props => (props.color ? props.color : Colors.primary)};
    border-right: 27px solid transparent;
    height: 0;
    width: 27px;
    transform: rotate(-45deg);
    position: relative;
    left: 19px;
    top: -16px;
    border-radius: 0 0 1px 1px;
  }
`;

export const LogoImage = styled.div.attrs({
  src: LogoImg,
  alt: "Amagi Academy Logo"
})`
  background-image: url("${LogoImg}");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-width: 50px;
  min-height: 50px;
  
  ${props => {
    if (props.career) {
      if (props.career === "devops") {
        // transform the logo to green
        return "filter: hue-rotate(300deg);";
      }
      if (props.career === "software") {
        return "filter: hue-rotate(380deg);";
      }
      if (props.career === "data-science") {
        return "filter: hue-rotate(790deg);";
      }
    }

    return null;
  }}
`;

const Logo = ({ size, color, ...props }) => {
  switch (size) {
    case "small": {
      return (
        <LogoSmall color={color}>
          <div className="rectangle" />
          <div className="triangle" />
          <div className="trapezoid" />
        </LogoSmall>
      );
    }
    case "medium": {
      return (
        <LogoMedium color={color}>
          <div className="rectangle" />
          <div className="triangle" />
          <div className="trapezoid" />
        </LogoMedium>
      );
    }
    case "image": {
      return <LogoImage color={color} {...props} />;
    }
    default: {
      return null;
    }
  }
};

Logo.propTypes = {
  size: PropTypes.string
};

Logo.defaultProps = {
  size: "image"
};

export default Logo;
