/* eslint no-underscore-dangle : off */
import { combineReducers, createStore } from "redux";
import { reducer as formReducer } from "redux-form";

import syllabus from "./syllabus";
import user from "./user";
import landing from "./landing";

const reducers = combineReducers({
  form: formReducer,
  syllabus,
  user,
  landing
});

export default createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
