import React from "react";
import PropTypes from "prop-types";

import { GRADIENTS } from "theme/colors";

import { css } from "styled-components";
import { Row } from "react-grid-system";
import { Container } from "./styledElements";

import Button from "../../../Button";

const Footer = ({ onCancel, onApply, layoutLabel }) => (
  <Container>
    <div>
      <small>{layoutLabel}</small>
    </div>
    <Row align="center" justify="end">
      <Button
        title="Cancel"
        size="extra-small"
        onClick={onCancel}
        customstyles={css`
          color: rgb(255, 255, 255);
          border: none;
          box-shadow: none;
        `}
      />
      <Button
        title="Apply Layout"
        onClick={onApply}
        size="extra-small"
        customstyles={css`
          box-shadow: none;
          color: rgb(255, 255, 255);
          ${GRADIENTS.orange}
        `}
      />
    </Row>
  </Container>
);

Footer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  layoutLabel: PropTypes.string
};

Footer.defaultProps = {
  layoutLabel: null
};

export default Footer;
