import Colors from "theme/colors";
import styled from "styled-components";

export default styled.div.attrs({
  className: "edit-button"
})`
  margin: 0 10px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.primaryDark};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 275ms ease-out;

  &:hover {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    transition: box-shadow 275ms ease-in;
  }

  font-size: 12px;
`;
