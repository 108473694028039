import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withWindowSize from "react-window-size";
import { Link } from "react-router-dom";
import Colors from "theme/colors";
import Links from "./Links";
import Menu from "./Menu";

import { Brand } from "../../Brand";
import WidthWrapper from "../WidthWrapper";

const Container = styled.div`
  padding: 1rem 0;
  width: 100%;
  z-index: 1;
  background-color: ${Colors.background};
  top: 0;
  left: 0;
`;

const noDecoration = {
  textDecoration: "none"
};

const Header = ({ windowWidth }) => {
  const small = windowWidth <= 500;

  return (
    <Container>
      <WidthWrapper>
        <Link to="/" style={noDecoration}>
          <Brand small={small} noBackground />
        </Link>
        {windowWidth > 890 ? <Links color={Colors.text} /> : <Menu />}
      </WidthWrapper>
    </Container>
  );
};

Header.propTypes = {
  windowWidth: PropTypes.number.isRequired
};

export default withWindowSize(Header);
