import React from "react";
import PropTypes from "prop-types";

import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ScreenClassRender } from "react-grid-system";
import { Form, Field, SearchHint } from "./styledElements";

import Button from "../Button";

class SearchField extends React.PureComponent {
  handleSearch = event => {
    event.preventDefault();

    const { onSearch } = this.props;

    const formData = new FormData(this.form);
    const query = formData.get("searchfield");
    onSearch(query);
  };

  render() {
    const { onChange, disabled, placeholder, inputStyle } = this.props;

    return (
      <ScreenClassRender
        render={screen => (
          <Form
            ref={instance => {
              this.form = instance;
            }}
            onSubmit={this.handleSearch}
            screen={screen}
          >
            <Field
              screen={screen}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              inputstyle={inputStyle}
            />
            <SearchHint screen={screen}>
              <Button
                title={<FontAwesomeIcon icon="search" />}
                onClick={this.handleSearch}
                buttontype="submit"
              />
            </SearchHint>
          </Form>
        )}
      />
    );
  }
}

SearchField.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(css)
  ])
};

SearchField.defaultProps = {
  onChange: () => null,
  disabled: false,
  placeholder: "Search...",
  inputStyle: {}
};

export default SearchField;
