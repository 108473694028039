/* eslint import/prefer-default-export : off */
import { COURSE_TAGS } from "constants/course";
import CAREERS from "constants/landingCareers";

const getCareerConstant = career => {
  return CAREERS.find(c => c.key === career);
};

const getTagColor = (tag = COURSE_TAGS[0].value) => {
  const courseTag = COURSE_TAGS.find(
    entry => entry.value.toUpperCase() === tag.toUpperCase()
  );

  if (courseTag) {
    return courseTag.background;
  }

  return COURSE_TAGS[0].background;
};

export { getCareerConstant, getTagColor };
