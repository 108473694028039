import React from "react";
import PropTypes from "prop-types";
import Colors from "theme/colors";

import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  font-weight: 300;
`;

const PageWrapper = ({ children, background }) => (
  <Wrapper background={background}>{children}</Wrapper>
);

PageWrapper.propTypes = {
  background: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

PageWrapper.defaultProps = {
  background: Colors.background
};

export default PageWrapper;
