import React from "react";
import { ScreenClassRender } from "react-grid-system";

import SplitMedia from "assets/images/splitmedia.webp";
import Nokia from "assets/images/nokia.svg";
import Axa from "assets/images/axa.png";
import BakerMckenzie from "assets/images/bakermckenzie.png";
import CocaCola from "assets/images/cocacola.png";
import EY from "assets/images/ey.png";
import Amagi from "assets/images/amagi.png";
import Salesforce from "assets/images/salesforce.png";
import Bloomberg from "assets/images/bloomberg.png";
import Accenture from "assets/images/accenture.svg";

import { Container, Row } from "react-grid-system";
import {
  CustomersHeadline,
  CustomersContainer,
  CustomerContainer,
  Customer
} from "./styledElements";

const CUSTOMERS = [
  {
    image: SplitMedia,
    name: "Splitmedia"
  },
  {
    image: Nokia,
    name: "Nokia"
  },
  {
    image: Amagi,
    name: "Amagi"
  },
  {
    image: BakerMckenzie,
    name: "Baker Mckenzie"
  },
  {
    image: CocaCola,
    name: "Coca Cola"
  },
  {
    image: Axa,
    name: "Axa"
  },
  {
    image: EY,
    name: "Ernst & Young",
    size: 55
  },
  {
    image: Accenture,
    name: "Accenture"
  },
  {
    image: Salesforce,
    name: "Salesforce",
    size: 90
  },
  {
    image: Bloomberg,
    name: "Bloomberg"
  }
];

const Customers = () => (
  <ScreenClassRender
      render={screen => (
        <CustomersContainer>
          <CustomersHeadline>Previous students have worked at or been hired by</CustomersHeadline>
          <Container>
            <Row align="center" justify="center">
              {CUSTOMERS.map(customer => (
                <CustomerContainer key={customer.name}>
                  <Customer
                    src={customer.image}
                    alt={customer.name}
                    title={customer.name}
                    size={customer.size}
                    screen={screen}
                  />
                </CustomerContainer>
              ))}
            </Row>
          </Container>
        </CustomersContainer>
      )}
    />
);

export default Customers;
