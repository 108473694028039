import styled from "styled-components";
import Colors from "theme/colors";

export const BrandContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${props =>
    ["xl", "lg", "md"].includes(props.screen) ? "2rem 0" : ".5rem 0"};
  width: 90%;
  flex-grow: 1;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: ${props =>
    ["xl", "lg", "md"].includes(props.screen) ? "55%" : "100%"};
  max-width: 55rem;
  margin: ${props =>
    ["xl", "lg", "md"].includes(props.screen) ? "1rem" : "0"};
  margin-right: ${props =>
    ["xl", "lg", "md"].includes(props.screen) ? "3rem" : "0"};
  text-align: ${props =>
    ["xl", "lg", "md"].includes(props.screen) ? "left" : "center"};

  h1 {
    display: inline;
    color: #000;
    text-transform: uppercase;
    font-weight: 400;
    font-size: ${props =>
      ["xs", "sm"].includes(props.screen) ? "3.5rem" : "5.5rem"};
    font-family: "Open Sans";
    line-height: ${props =>
      ["xs", "sm"].includes(props.screen) ? "3.5rem" : "5.5rem"};
    margin-bottom: 0;
    white-space: wrap;
    text-align: left;

    strong {
      font-weight: 800;
      background: linear-gradient( 19deg, rgb(33,212,253) 0%, rgb(183,33,255) 100% );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & > div {
      display: inline;
    }

    .word {
      display: inline-block;
      ${props =>
        ["xs", "sm"].includes(props.screen) &&
        `
        justify-content: center;
      `}
    }

`;

export const CTA = styled.div`
  width: 80%
  margin-top: ${props =>
    ["xs", "sm"].includes(props.screen) ? "2rem" : "1.5rem"};
  display: flex;
  align-items: center;
  ${props =>
    ["xs", "sm"].includes(props.screen) &&
    `
    align-self: center;
    justify-content: center;
    flex-direction: column;
  `}
`;

export const Letter = styled.span`
  display: inline-block;
  white-space: nowrap;
  // background: linear-gradient(
  //   90deg,
  //   rgba(0, 222, 250, 1) 0%,
  //   rgba(36, 171, 237, 1) 100%
  // );
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  width: fit-content;
  color: #212121;
  text-shadow: ${props => props.color} 5px 5px, 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

Letter.defaultProps = {
  color: Colors.primary
};

export const BrandName = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;

  &:before,
  &:after {
    display: block;
    content: attr(data-glitch);
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    text-transform: uppercase;
    font-weight: 800;
    font-size: ${props =>
      ["xs", "sm"].includes(props.screen) ? "4rem" : "5.5rem"};
    font-family: "Open Sans";
    line-height: ${props =>
      ["xs", "sm"].includes(props.screen) ? "4rem" : "5.5rem"};
    margin-bottom: 0;
  }

  &:after {
    color: #f0f;
    z-index: -2;
  }
  &:before {
    color: #0ff;
    z-index: -1;
  }

  &:hover {
    &:before {
      opacity: 0.5;
      animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    }
    &:after {
      opacity: 0.5;
      animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both
        infinite;
    }
  }

  @media only screen and (max-width: 400px) {
    & {
      font-size: 3em;
    }
  }

  @keyframes glitch {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-5px, 5px);
    }
    40% {
      transform: translate(-5px, -5px);
    }
    60% {
      transform: translate(5px, 5px);
    }
    80% {
      transform: translate(5px, -5px);
    }
    to {
      transform: translate(0);
    }
  }
`;

export const ChoiceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 0;
  margin-top: 2rem;
`;

export const Description = styled.div`
  z-index: 5;
  display: ${props => (["sm", "xs"].includes(props.screen) ? "none" : "block")};
  color: inherit;
  margin-top: 2rem;
  font-size: 1.15rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  border-radius: 10px;
  color: ${Colors.text};
  // box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
  // margin-left: ${props =>
    ["sm", "xs"].includes(props.screen) ? "5%" : "0"};
  // margin-right: ${props =>
    ["sm", "xs"].includes(props.screen) ? "5%" : "0"};
  // border-left: 3px solid rgba(0, 222, 250, 1);
`;

export const LogoContainer = styled.div`
  display: ${props =>
    ["xl", "lg", "md"].includes(props.screen) ? "flex" : "none"};
  align-self: stretch;

  .logo,
  .glitch {
    width: 20vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: filter 0.2s ease-in;
  }

  .logo {
    overflow: hidden;
    margin: 0 auto;
    position: relative;
  }

  .glitch {
    background-blend-mode: none;
    background-color: transparent;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .glitching .glitch:nth-child(n + 2) {
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-delay: 0;
    animation-delay: 0;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 5;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  .glitching .glitch:nth-child(2) {
    -webkit-animation-name: glitch-anim-1;
    animation-name: glitch-anim-1;
  }
  .glitching .glitch:nth-child(3) {
    -webkit-animation-name: glitch-anim-2;
    animation-name: glitch-anim-2;
  }
  .glitching .glitch:nth-child(4) {
    -webkit-animation-name: glitch-anim-3;
    animation-name: glitch-anim-3;
  }
  .glitching .glitch:nth-child(5) {
    -webkit-animation-name: glitch-anim-4;
    animation-name: glitch-anim-4;
  }

  .glitching:nth-child(n + 2) {
    opacity: 0;
  }

  @-webkit-keyframes glitch-anim-1 {
    100% {
      opacity: 1;
      -webkit-transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
      transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    20% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    30% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    40% {
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    50% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    55% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    60% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    65% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    70% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    80% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    85% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
      clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
    }
    95% {
      -webkit-clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
      clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
    }
  }

  @keyframes glitch-anim-1 {
    0%,
    100% {
      opacity: 1;
      -webkit-transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
      transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    20% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    30% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    40% {
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    50% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    55% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    60% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    65% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    70% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    80% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    85% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
      clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
    }
    95% {
      -webkit-clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
      clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
    }
  }
  @-webkit-keyframes glitch-anim-2 {
    0%,
    100% {
      opacity: 1;
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
      -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    10% {
      -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    15% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    17% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    19% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    33% {
      -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    35% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    40% {
      -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    45% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    49% {
      -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    50% {
      -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    55% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
      clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
    }
    60% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    70% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
      clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
    }
    80% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
      clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
    }
    90% {
      -webkit-clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
      clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
    }
  }
  @keyframes glitch-anim-2 {
    0%,
    100% {
      opacity: 1;
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
      -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    10% {
      -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    15% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    17% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    19% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    33% {
      -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    35% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    40% {
      -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    45% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    49% {
      -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    50% {
      -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    55% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
      clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
    }
    60% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    70% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
      clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
    }
    80% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
      clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
    }
    90% {
      -webkit-clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
      clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
    }
  }
  @-webkit-keyframes glitch-anim-3 {
    0%,
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
      transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    5% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    11% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    20% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    25% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    35% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    42% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    48% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    50% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    56% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    61% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    68% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    72% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    77% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    81% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    86% {
      -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    90% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    92% {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    94% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
  }
  @keyframes glitch-anim-3 {
    0%,
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
      transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    5% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    11% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    20% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    25% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    35% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    42% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    48% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    50% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    56% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    61% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    68% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    72% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    77% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    81% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    86% {
      -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    90% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    92% {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    94% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
  }
  @-webkit-keyframes glitch-anim-4 {
    0%,
    5% {
      opacity: 0.2;
      -webkit-transform: translate3d(10px, 5px, 0);
      transform: translate3d(10px, 5px, 0);
    }
    5.5%,
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes glitch-anim-4 {
    0%,
    5% {
      opacity: 0.2;
      -webkit-transform: translate3d(10px, 5px, 0);
      transform: translate3d(10px, 5px, 0);
    }
    5.5%,
    100% {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
`;
