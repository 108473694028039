import React from "react";
import PropTypes from "prop-types";

import { EXCLUDED_FILE_DELETION } from "constants/sandbox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "./styledElements";

class FileActions extends React.PureComponent {
  deleteFile = event => {
    event.stopPropagation();

    const { deleteFile } = this.props;
    deleteFile();
  };

  render() {
    const { showFileCreator, isDirectory, path } = this.props;

    return (
      <Container>
        {isDirectory && (
          <FontAwesomeIcon
            onClick={showFileCreator}
            icon="file"
            title="New File"
          />
        )}
        {!isDirectory && !EXCLUDED_FILE_DELETION.includes(path) && (
          <FontAwesomeIcon
            onClick={this.deleteFile}
            icon="trash-alt"
            title="Delete File"
          />
        )}
      </Container>
    );
  }
}

FileActions.propTypes = {
  showFileCreator: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  isDirectory: PropTypes.bool,
  path: PropTypes.string
};

FileActions.defaultProps = {
  isDirectory: false,
  path: ""
};

export default FileActions;
