/* eslint import/prefer-default-export : off */
import CUSTOM_ERROR_CODES from "constants/customErrorCodes";

export const uploadMedia = async ({ media, token = "" } = {}) => {
  const formData = new FormData();

  formData.append("file", media);

  const [error, response] = await fetch(process.env.REACT_APP_UPLOAD_URI, {
    method: "POST",
    body: formData,
    headers: new Headers({
      Authorization: `Bearer ${token}`
    })
  })
    .then(res => [null, res])
    .catch(err => [err]);

  if (!response.ok || error) {
    switch (response.status) {
      case 400:
        return CUSTOM_ERROR_CODES.UPLOAD_ERROR_400;
      case 413:
        return CUSTOM_ERROR_CODES.UPLOAD_ERROR_413;
      case 500:
        return CUSTOM_ERROR_CODES.UPLOAD_ERROR_500;
      default:
        return CUSTOM_ERROR_CODES.UPLOAD_ERROR_GEN;
    }
  }

  const data = await response.json();
  const { location } = data;
  return [null, location];
};
