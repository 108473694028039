import React from "react";
import PropTypes from "prop-types";

import { LazyImage } from "components";
import { AssetColumn, AssetBounce, ImageContainer } from "./styledElements";

const ImageAsset = ({ src, alt, bounceLeft, bounceRight }) => (
  <AssetColumn>
    <AssetBounce left={bounceLeft} right={bounceRight}>
      <ImageContainer>
        <LazyImage src={src} alt={alt} />
      </ImageContainer>
    </AssetBounce>
  </AssetColumn>
);

const VideoAsset = () => {};

ImageAsset.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  bounceLeft: PropTypes.bool,
  bounceRight: PropTypes.bool
};

ImageAsset.defaultProps = {
  alt: "",
  bounceLeft: false,
  bounceRight: false
};

export { ImageAsset, VideoAsset };
