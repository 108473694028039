import styled from "styled-components";

import Colors, { GRADIENTS } from "theme/colors";


export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: 3rem 10%;
  padding: 3rem;
  border-radius: 2rem;
  background: ${Colors.codeEditorBackground};

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
      margin: 5%;
      padding: 2rem 1rem;
      border-radius: 1rem;
    `}
`;

export const SectionHeadline = styled.h2`
  margin: 0 2rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: ${Colors.white};
`;

export const SectionBody = styled.div`
  color: ${Colors.text};
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-between;

  hr {
    margin: 1rem 0;
    width: 50%;
    height: 1px;
    border: none;
    background: #212121;
    opacity: 0.3;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  a {
    font-size: 1rem;
  }

  .data {
    ${GRADIENTS.data};
  }

  .devops {
    ${GRADIENTS.devops};
  }

  .software {
    ${GRADIENTS.software};
  }

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       h2 {
         font-size: 2rem;   
         text-align: center;
       }

       hr {
         margin: 0 auto;
         margin-bottom: 1rem;
       }
    `}
`;

export const CourseContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2%;
  color: white;
  border-radius: 10px;
  padding: 2rem;

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    `
      margin: 5%;
      margin-bottom: 10%;
    `}
`;

export const CourseHeadline = styled.h3`
  margin: 0;
  margin-bottom: 1rem;
`;

export const CourseBody = styled.p`
  margin: 0;
  margin-bottom: 1rem;
`;

export const CourseButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  background: white;
  box-shadow: 0px 9px 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: inherit;
`;