/* eslint no-underscore-dangle : off */

import React from "react";
import PropTypes from "prop-types";
import SplitterLayout from "react-splitter-layout";

import {
  SANDBOX_TEMPLATES,
  SANDBOX_TEMPLATE_WITH_NPM
} from "constants/sandbox";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileCreator } from "components/SandboxFileExplorer/components";
import {
  CodeMirror,
  BrowserPreview,
  SandpackProvider
} from "react-smooshpack/es/components";
import {
  FilesWrapper,
  LayoutWrapper,
  SplitterWrapper,
  FilesTitle
} from "./styledElements";

import DependencyList from "../DependencyList";
import NpmSearch from "../NpmSearch";
import SandboxFileExplorer from "../../../SandboxFileExplorer";
import "react-smooshpack/dist/styles.css";
import "./Sandbox.css";

class Sandbox extends React.Component {
  constructor(props) {
    super(props);

    const { template } = this.props;
    const { files, dependencies } = SANDBOX_TEMPLATES[template];

    this.state = {
      secondarypanesize: 250,
      currentFiles: files,
      currentDependencies: dependencies,
      isDragging: false
    };
  }

  componentDidMount = () => {
    const { value } = this.props;

    if (value) {
      const { files, dependencies } = this.parseSandboxValue();

      this.setState({
        currentFiles: files,
        currentDependencies: dependencies
      });
    }
  };

  componentDidUpdate = prevProps => {
    const { value } = this.props;

    if (prevProps.value !== value) {
      const { files, dependencies } = this.parseSandboxValue();

      this.setState(
        {
          currentFiles: files,
          currentDependencies: dependencies
        },
        () => {
          const firstKey = Object.keys(files)[0];
          this.sandpack.openFile(firstKey);
        }
      );
    }
  };

  onDragStart = () => {
    this.setState({
      isDragging: true
    });
  };

  onDragEnd = () => {
    this.setState({
      isDragging: false
    });
  };

  setSecondaryPaneSize = secondarypanesize =>
    this.setState({ secondarypanesize });

  parseSandboxValue = () => {
    const { value, template } = this.props;

    try {
      if (value) {
        return JSON.parse(value);
      }

      return SANDBOX_TEMPLATES[template];
    } catch (err) {
      return SANDBOX_TEMPLATES[template];
    }
  };

  getValue = () => {
    const { currentDependencies } = this.state;
    const { files: sandpackFiles } = this.sandpack._getSandpackState();

    if (!sandpackFiles) return JSON.stringify({});

    return JSON.stringify({
      files: sandpackFiles,
      dependencies: currentDependencies
    });
  };

  showFileCreator = event => {
    this.fileCreator.showFileCreator(event);
  };

  createFile = file => {
    const { files } = this.sandpack._getSandpackState();

    this.setState({
      currentFiles: {
        ...files,
        [file]: {
          code: ""
        }
      }
    });
  };

  deleteFile = file => () => {
    const { files } = this.sandpack._getSandpackState();

    const { openedPath } = this.sandpack.state;

    const newFiles = Object.keys(files).reduce((prev, next) => {
      if (file !== next) {
        /* eslint-disable-next-line */
        prev[next] = files[next];
      }

      return prev;
    }, {});

    const dirs = file.split("/");
    dirs.pop();
    const openFile = Object.keys(newFiles).find(key =>
      key.includes(dirs.join("/"))
    );

    if (openedPath === file) this.sandpack.openFile(openFile);

    this.setState({
      currentFiles: newFiles
    });
  };

  addDependency = ({ name, version } = {}) => {
    const { currentDependencies } = this.state;

    this.setState({
      currentDependencies: {
        ...currentDependencies,
        [name]: version
      }
    });
  };

  deleteDependency = name => {
    const { currentDependencies } = this.state;

    const newDependencies = Object.keys(currentDependencies)
      .filter(entry => entry !== name)
      .reduce((prev, next) => {
        /* eslint-disable-next-line */
        prev[next] = currentDependencies[next];

        return prev;
      }, {});

    this.setState({
      currentDependencies: newDependencies
    });
  };

  render() {
    const {
      secondarypanesize,
      currentFiles,
      currentDependencies,
      isDragging
    } = this.state;
    const { template } = this.props;

    return (
      <div>
        <SandpackProvider
          ref={instance => {
            this.sandpack = instance;
          }}
          files={currentFiles}
          dependencies={currentDependencies}
          entry={SANDBOX_TEMPLATES[template].entry}
          template={template}
        >
          <LayoutWrapper>
            <FilesWrapper>
              <FilesTitle>
                <div>Files</div>
                <FontAwesomeIcon
                  onClick={this.showFileCreator}
                  icon="file"
                  title="New File"
                />
              </FilesTitle>
              <SandboxFileExplorer
                createFile={this.createFile}
                deleteFile={this.deleteFile}
                style={{ flex: 0.2 }}
              />
              <FileCreator
                ref={instance => {
                  this.fileCreator = instance;
                }}
                createFile={this.createFile}
              />
              {SANDBOX_TEMPLATE_WITH_NPM[template] && (
                <>
                  <DependencyList
                    onDelete={this.deleteDependency}
                    dependencies={currentDependencies}
                  />
                  <NpmSearch onSelect={this.addDependency} />
                </>
              )}
            </FilesWrapper>
            <SplitterWrapper>
              <SplitterLayout
                onSecondaryPaneSizeChange={this.setSecondaryPaneSize}
                vertical
                secondaryInitialSize={250}
              >
                <CodeMirror
                  style={{
                    flex: 1,
                    minHeight: "calc((100vh - 108px) / 2)",
                    height: `calc(100vh - 117px - ${secondarypanesize}px)`
                  }}
                />
                <BrowserPreview
                  ref={instance => {
                    this.preview = instance;
                  }}
                  style={{
                    flex: 1,
                    height: "100%",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    pointerEvents: isDragging ? "none" : "all"
                  }}
                />
              </SplitterLayout>
            </SplitterWrapper>
          </LayoutWrapper>
        </SandpackProvider>
      </div>
    );
  }
}

Sandbox.propTypes = {
  value: PropTypes.string,
  template: PropTypes.string.isRequired
};

Sandbox.defaultProps = {
  value: null
};

export default Sandbox;
