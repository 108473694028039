import gql from "graphql-tag";

export const GET_USER_CARD_DETAILS = gql`
  query UserCardDetails($id: ID!) {
    userCardDetails(id: $id) {
      billing_details {
        name
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
      }
      card {
        brand
        country
        exp_year
        exp_month
        funding
        last4
      }
    }
  }
`;

export const GET_USER_ITERATIONS = gql`
  query UserIterationsByUser($userId: ID!) {
    useriterationsbyuser(user_id: $userId) {
      user_id
      iterations {
        id
        updated_at
        created_at
        deleted
        course_id
        session_starts
        passed
        payment_intent_id
        paid
        title
        price
        max_attendees
        session_count
        hours_per_session
      }
    }
  }
`;
