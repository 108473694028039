import Abstract from "./Abstract";
import Hero from "./Hero";
import Header from "./Header";
import Experience from "./Experience";
import ProgressBar from "./ProgressBar";
import Courses from "./Courses";
import OnlineCourses from "./OnlineCourses";
import Reasons from "./Reasons";
import Steps from "./Steps";
import CTA from "./CTA";
import Activities from "./Activities";
import Customers from "./Customers";
import Timeline from "./Timeline";
import Advantages from "./Advantages";
import Newsletter from "./Newsletter";
import Footer from "./Footer";

export {
  Abstract,
  Hero,
  Header,
  Experience,
  ProgressBar,
  Courses,
  OnlineCourses,
  Reasons,
  Steps,
  CTA,
  Activities,
  Customers,
  Timeline,
  Advantages,
  Newsletter,
  Footer
};
