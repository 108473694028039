import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ScreenClassRender, Visible } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { nextCareer, prevCareer, setCareer } from "store/landing";
import Careers from "constants/landingCareers";

import {
  Container,
  ChoiceList,
  ChoiceItem,
  NavigationSelect
} from "./styledElements";

const ChoiceSelect = ({ career, onNextCareer, onPrevCareer, onSetCareer }) => {
  console.log(career);
  return (
    <ScreenClassRender
      render={screen => (
        <Container screen={screen}>
          <Visible xs sm>
            <NavigationSelect onClick={onPrevCareer}>
              <FontAwesomeIcon icon="chevron-left" />
            </NavigationSelect>
          </Visible>
          <ChoiceList screen={screen}>
            {Careers.map(el => {
              const isFocused = career && career === el.key;
              console.log(isFocused, career, el.key);
              return (
                <ChoiceItem
                  screen={screen}
                  key={el.key}
                  isFocused={isFocused}
                  color={el.color}
                >
                  <button
                    type="button"
                    className={isFocused ? "focused" : null}
                    onClick={() => onSetCareer({ key: el.key })}
                  >
                    <h2>{el.label}</h2>
                    {/* <div>{el.description}</div> */}
                  </button>
                </ChoiceItem>
              );
            })}
          </ChoiceList>
          <Visible xs sm>
            <NavigationSelect onClick={onNextCareer}>
              <FontAwesomeIcon icon="chevron-right" />
            </NavigationSelect>
          </Visible>
        </Container>
      )}
    />
  );
};

ChoiceSelect.propTypes = {
  career: PropTypes.string,
  onNextCareer: PropTypes.func.isRequired,
  onPrevCareer: PropTypes.func.isRequired,
  onSetCareer: PropTypes.func.isRequired
};

ChoiceSelect.defaultProps = {
  career: "software"
};

export default connect(
  null,
  {
    onNextCareer: nextCareer,
    onPrevCareer: prevCareer,
    onSetCareer: setCareer
  }
)(ChoiceSelect);
