import React from "react";
import PropTypes from "prop-types";

import { SandpackConsumer } from "react-smooshpack/es/components";
import { FilesContainer } from "./styledElements";
import { ModuleList } from "./components";

class FileExplorer extends React.PureComponent {
  render() {
    const { createFile, deleteFile, ...props } = this.props;

    return (
      <SandpackConsumer>
        {sandpack => (
          <FilesContainer {...props}>
            <ModuleList
              selectFile={sandpack.openFile}
              files={sandpack.files}
              prefixedPath="/"
              openedPath={sandpack.openedPath}
              createFile={createFile}
              deleteFile={deleteFile}
            />
          </FilesContainer>
        )}
      </SandpackConsumer>
    );
  }
}

FileExplorer.propTypes = {
  createFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired
};

export default FileExplorer;
