import styled from "styled-components";
import Colors from "theme/colors";

export const Container = styled.div`
  padding: 0.5rem 0;
`;

export const ListContainer = styled.div`
  max-height: 150px;
  overflow-y: auto;
`;

export const Title = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
  font-weight: 700;
`;

export const Dependency = styled.div`
  font-size: 0.8rem;
  padding: 0.25rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Colors.primary};

  .name {
    width: 110px;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .version {
    color: rgba(255, 255, 255, 0.5);
  }

  .actions {
    display: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);

    .actions {
      display: block;
    }
  }
`;
