import gql from "graphql-tag";

export const CREATE_COURSE = gql`
  mutation CreateCourse(
    $title: String!
    $image: String
    $short: String
    $about: String
    $price: String
    $tags: String
    $duration: String
    $difficulty: String
    $language: String
    $author: ID!
    $layouts: [LayoutInput]
    $instructors: [Int]
    $start_date: String
    $session_count: Int
    $hours_per_session: Int
    $max_attendees: Int
    $iterations: [IterationInput]
    $type: CourseType!
  ) {
    createCourse(
      course: {
        title: $title
        image: $image
        short: $short
        about: $about
        price: $price
        tags: $tags
        duration: $duration
        difficulty: $difficulty
        language: $language
        author: $author
        layouts: $layouts
        instructors: $instructors
        start_date: $start_date
        session_count: $session_count
        hours_per_session: $hours_per_session
        max_attendees: $max_attendees
        iterations: $iterations
        type: $type
      }
    ) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const SAVE_COURSE = gql`
  mutation SaveCourse(
    $title: String!
    $image: String
    $short: String
    $about: String
    $price: String
    $tags: String
    $duration: String
    $difficulty: String
    $language: String
    $author: ID!
    $layouts: [LayoutInput]
    $instructors: [Int]
    $start_date: String
    $session_count: Int
    $hours_per_session: Int
    $max_attendees: Int
    $iterations: [IterationInput]
    $type: CourseType!
  ) {
    saveCourse(
      course: {
        title: $title
        image: $image
        short: $short
        about: $about
        price: $price
        tags: $tags
        duration: $duration
        difficulty: $difficulty
        language: $language
        author: $author
        layouts: $layouts
        instructors: $instructors
        start_date: $start_date
        session_count: $session_count
        hours_per_session: $hours_per_session
        max_attendees: $max_attendees
        iterations: $iterations
        type: $type
      }
    ) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourse(
    $id: ID!
    $title: String!
    $image: String
    $short: String
    $about: String
    $price: String
    $tags: String
    $duration: String
    $difficulty: String
    $language: String
    $author: ID!
    $instructors: [Int]
    $start_date: String
    $session_count: Int
    $hours_per_session: Int
    $max_attendees: Int
    $iterations: [IterationInput]
    $type: CourseType!
  ) {
    updateCourse(
      id: $id
      type: $type
      course: {
        title: $title
        image: $image
        short: $short
        about: $about
        price: $price
        tags: $tags
        duration: $duration
        difficulty: $difficulty
        language: $language
        author: $author
        instructors: $instructors
        start_date: $start_date
        session_count: $session_count
        hours_per_session: $hours_per_session
        max_attendees: $max_attendees
        iterations: $iterations
      }
    ) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const CREATE_USER_ITERATION = gql`
  mutation CreateUserIteration(
    $userId: ID!
    $iterationId: ID!
    $promoCoupon: String
  ) {
    createUserIteration(
      user_iteration: {
        user_id: $userId
        iteration_id: $iterationId
        promo_coupon: $promoCoupon
      }
    ) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const ENROLL_COURSE = gql`
  mutation EnrollCourse($userId: ID!, $courseId: ID!) {
    createUserCourses(user_course: { user_id: $userId, course_id: $courseId }) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourse($id: ID!, $type: CourseType!) {
    deleteCourse(id: $id, type: $type) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const PUBLISH_COURSE = gql`
  mutation PublishCourse($id: ID!, $published: Boolean!) {
    publishCourse(id: $id, published: $published) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;
