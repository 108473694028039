import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Colors from "theme/colors";

import { Wrapper, Label, Error, Warning } from "./common";

const inputStyle = css`
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid ${Colors.black};
  transition: border-color 275ms ease-out, box-shadow 275ms ease-in;
  box-shadow: none;
  width: calc(100% - 28px);

  &:hover,
  &:focus {
    transition: border-color 275ms ease-in, box-shadow 275ms ease-in;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;
const Input = styled.input`
  ${inputStyle}

  ${props =>
    props.isWarning &&
    `
    border: 1px solid rgba(100, 65, 0, 0.6);
  `}

  ${props =>
    props.isError &&
    `
    border: 1px solid rgba(200, 0, 0, 0.6);
  `}

  ${props =>
    props.customStyle &&
    `
    ${props.customStyle}
  `}
`;

const Textarea = styled.textarea`
  ${inputStyle};

  resize: vertical;
`;

const InputComponent = ({
  input = {},
  label,
  placeholder,
  type,
  multiline,
  autoFocus,
  meta: { touched, error, warning } = {},
  customStyle
}) => {
  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <div>
        {!multiline ? (
          <Input
            id={input.name}
            isWarning={touched && warning}
            isError={touched && error}
            autoFocus={autoFocus}
            {...input}
            placeholder={placeholder}
            type={type}
            customStyle={customStyle}
          />
        ) : (
          <Textarea
            id={input.name}
            rows={7}
            autoFocus={autoFocus}
            {...input}
            placeholder={placeholder}
            type={type}
          />
        )}
        {touched &&
          ((error && <Error>{error}</Error>) ||
            (warning && <Warning>{warning}</Warning>))}
      </div>
    </Wrapper>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  meta: PropTypes.instanceOf(Object),
  input: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array),
  defaultValue: PropTypes.string,
  change: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  customStyle: PropTypes.string
};

InputComponent.defaultProps = {
  input: {},
  label: null,
  autoFocus: false,
  meta: {},
  options: [],
  defaultValue: null,
  change: () => null,
  placeholder: null,
  type: null,
  multiline: false,
  customStyle: null
};

export default InputComponent;
