/* eslint import/prefer-default-export : off */
const wait = (callback, delay) => {
  const oldTime = new Date().getTime();
  const handler = {};

  const loop = () => {
    const newTime = new Date().getTime();
    const delta = newTime - oldTime;

    if (delta >= delay) {
      if (callback) {
        callback();
      }

      cancelAnimationFrame(handler.value);
      return;
    }

    handler.value = requestAnimationFrame(loop);
  };

  handler.value = requestAnimationFrame(loop);

  return handler;
};

export { wait };
