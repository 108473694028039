import auth0 from "auth0-js";
import decode from "jwt-decode";

const config = {
  DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  REALM: process.env.REACT_APP_AUTH0_DB
};

export const Auth = new auth0.WebAuth({
  domain: config.DOMAIN,
  clientID: config.CLIENT_ID
});

export const AuthLogin = (username, password, callback) =>
  Auth.client.login(
    {
      realm: config.REALM,
      username,
      password,
      scope: "openid"
    },
    callback
  );

export const AuthGetUserInfo = (accessToken, callback) =>
  Auth.client.userInfo(accessToken, callback);

export const AuthSetIdToken = idToken =>
  localStorage.setItem("idToken", idToken);

export const AuthClearIdToken = () => localStorage.removeItem("idToken");

export const getIdToken = () => {
  return localStorage.getItem("idToken");
};

const getTokenExpirationDate = idToken => {
  const token = decode(idToken);
  if (!token.exp) return null;

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
};

const isTokenExpired = token => {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate.getTime() < new Date().getTime();
};

export const isLoggedin = () => {
  const idToken = getIdToken();

  return !!idToken && !isTokenExpired(idToken);
};

export const AuthPasswordReset = (email, callback) => {
  Auth.changePassword({ connection: config.REALM, email }, callback);
};
