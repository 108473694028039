import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Embed from "runkit-embed-react";

import { BASIC_NODE } from "constants/codeSamples";
import { Container } from "./styledElements";

class NodeSandbox extends React.PureComponent {
  state = {
    containerHeight: 0,
    runkitLoaded: false,
    editorLoaded: false
  };

  componentDidMount() {
    this.setState({
      containerHeight: this.container.clientHeight
    });
  }

  onLoadEditor = () =>
    this.setState({
      editorLoaded: true
    });

  getSource = () => {
    const { editorLoaded } = this.state;

    return new Promise(resolve => {
      if (!this.runkit || !editorLoaded) {
        const { value } = this.props;
        resolve(value);
      } else {
        this.runkit.getSource(a => {
          resolve(a);
        });
      }
    });
  };

  getValue = async () => {
    const value = await this.getSource();

    return value;
  };

  handleScriptInject({ scriptTags }) {
    if (scriptTags) {
      const scriptTag = scriptTags[0];

      scriptTag.onload = () => {
        this.setState({
          runkitLoaded: true
        });
      };
    }
  }

  render() {
    const { containerHeight, runkitLoaded } = this.state;
    const { value } = this.props;

    return (
      <Container
        ref={instance => {
          this.container = instance;
        }}
      >
        <Helmet
          onChangeClientState={(newState, addedTags) =>
            this.handleScriptInject(addedTags)
          }
        >
          <script type="text/javascript" src="https://embed.runkit.com" />
        </Helmet>
        {containerHeight && runkitLoaded && (
          <Embed
            ref={instance => {
              this.runkit = instance;
            }}
            onLoad={this.onLoadEditor}
            source={value}
            minHeight={`${containerHeight / 2}px`}
            onEvaluate={() => {}}
          />
        )}
      </Container>
    );
  }
}

NodeSandbox.propTypes = {
  value: PropTypes.string
};

NodeSandbox.defaultProps = {
  value: BASIC_NODE
};

export default NodeSandbox;
