export default {
  base: "1rem",
  smallBase: "0.8rem",
  label: "0.95rem",
  title: "2.5rem",
  headerTitle: "1.15rem",
  pageTitle: "1.5rem",
  description: "1rem",
  button: "1rem",
  academy: "0.9rem",
  codeEditorHeader: "0.8rem"
};