import React from "react";
import PropTypes from "prop-types";

import { notify } from "react-notify-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPreview } from "utils/general/image";
import {
  DropzoneWrapper,
  Header,
  StyledDropzone,
  VideoPreview,
  Wrapper,
  TriggerWrapper
} from "./UploadersComponents";

class VideoUploader extends React.PureComponent {
  state = {
    value: null,
    preview: null
  };

  componentDidMount = () => {
    const { value } = this.props;

    if (value) {
      this.reset();
    }
  };

  componentDidUpdate = prevProps => {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.reset();
    }
  };

  setPreview = preview =>
    this.setState({
      preview
    });

  handleVideoDrop = async acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      if (acceptedFiles[0].size > 2000000) {
        notify.show("File size should not exceed 2MB. ", "error");
        return;
      }

      const preview = await getPreview(acceptedFiles[0]);
      this.setPreview(preview);

      this.setState({
        value: acceptedFiles[0]
      });
    }
  };

  getValue = () => {
    const { value } = this.state;
    return value;
  };

  reset = () => {
    const { value } = this.props;

    this.setState({
      value
    });
  };

  render() {
    const { value, preview } = this.state;
    let displayPreview = null;

    if (value) {
      displayPreview = typeof value === "string" ? value : value.preview;
    }

    return (
      <Wrapper>
        <Header>
          <FontAwesomeIcon icon="info-circle" />
          <span>Please drag or select the video you want to upload below.</span>
        </Header>
        <DropzoneWrapper>
          <StyledDropzone onDrop={this.handleVideoDrop} accept="video/*">
            {({ getRootProps, getInputProps }) => (
              <TriggerWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                {!value && (
                  <>
                    <FontAwesomeIcon icon="video" size="4x" />
                    <div>Drag your video or click here</div>
                  </>
                )}
                {value && (
                  <VideoPreview>
                    <source src={displayPreview || preview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </VideoPreview>
                )}
              </TriggerWrapper>
            )}
          </StyledDropzone>
        </DropzoneWrapper>
      </Wrapper>
    );
  }
}

VideoUploader.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string])
};

VideoUploader.defaultProps = {
  value: null
};

export default VideoUploader;
