import gql from "graphql-tag";

export const CREATE_USER = gql`
  mutation CreateUser(
    $id: String!
    $name: String!
    $email: String!
    $role: String = "student"
  ) {
    createUser(user: { id: $id, name: $name, email: $email, role: $role }) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;

export const UPDATE_USER_CARD_DETAILS = gql`
  mutation UpdateUserCardDetails($id: ID!, $details: CardDetailsInput) {
    updateUserCardDetails(id: $id, details: $details) {
      ... on MutateObjectSuccess {
        id
      }
      ... on MutateObjectError {
        message
      }
    }
  }
`;
