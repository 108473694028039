import React from "react";

import { Container } from "./styledElements";

const Header = () => (
  <Container>
    <div>Section Layout Templates</div>
    <div>
      <small>Select the layout you want for current section.</small>
    </div>
  </Container>
);

export default Header;
