import styled from "styled-components";
import { Container } from "react-grid-system";
import Colors from "theme/colors";

export const FooterContainer = styled(Container)`
  max-width: unset !important;
  padding: unset !important;
`;

export const FooterEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Colors.background};
  margin: unset !important;
  padding: 2rem !important;
  max-width: unset !important;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
`;

export const FooterByline = styled.div`
  font-family: 'Enhanced';
  font-size: 1rem;
  flex: 1;
  text-transform: uppercase;
  margin-top: 1.5rem;

  a {
    color: ${Colors.black};
    transition: all 0.2s ease;
  }

  a:hover {
    color: ${Colors.brandOrange};
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;

  svg {
    font-size: 1.5rem;
    margin: 0 1rem;
  }

  a {
    color: ${Colors.black};
  }

  a:hover {
    opacity: 0.7;
  }

  ${props =>
    props.screen &&
    ["sm", "xs"].includes(props.screen) &&
    ` 
      svg {
        font-size: 1rem;
      }
    `}
`;
