/* eslint import/prefer-default-export: off */
import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 108px) !important;
  max-height: calc(100vh - 108px) !important;
  overflow-y: auto;
  overflow-x: hidden;

  & > div {
    margin: -1px;
  }
`;
