import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import styled from "styled-components";

import "react-quill/dist/quill.snow.css";

const CustomQuill = styled(ReactQuill)`
  ${props => props.customstyles};
`;

class Markdown extends React.Component {
  editor = React.createRef();

  constructor(props) {
    super(props);
    const { value } = this.props;

    this.state = {
      editorValue: value
    };
  }

  componentDidUpdate = prevProps => {
    const { value } = this.props;

    if (prevProps.value !== value) {
      this.handleChange(value);
    }
  };

  handleChange = editorValue => {
    const { onChange } = this.props;

    this.setState({ editorValue });
    onChange(editorValue);
  };

  render() {
    const { editorValue } = this.state;
    const { placeholder, readOnly, customstyles } = this.props;

    if (readOnly) {
      return (
        <CustomQuill
          modules={{
            toolbar: false,
            clipboard: {
              matchVisual: false
            }
          }}
          readOnly
          value={editorValue}
          customstyles={customstyles}
        />
      );
    }

    return (
      <CustomQuill
        ref={instance => {
          this.editor = instance;
        }}
        value={editorValue}
        placeholder={placeholder}
        onChange={this.handleChange}
        modules={Markdown.modules}
        formats={Markdown.formats}
        customstyles={customstyles}
      />
    );
  }
}

Markdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  customstyles: PropTypes.instanceOf(Object)
};

Markdown.defaultProps = {
  onChange: () => {},
  value: "",
  placeholder: "",
  readOnly: false,
  customstyles: {}
};

Markdown.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "blockquote", "code-block"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"]
  ],
  clipboard: {
    matchVisual: false
  }
};

Markdown.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "color",
  "background",
  "script",
  "blockquote",
  "code-block",
  "list",
  "bullet",
  "indent",
  "link"
];

export default Markdown;
