/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { css } from "styled-components";
import { ScreenClassRender } from "react-grid-system";

import { Logo, Button, FadeIn } from "components";
import Header from "../Header";
import {
  BrandContainer,
  ContentContainer,
  Left,
  BrandName,
  Description,
  CTA,
  LogoContainer
} from "./styledElements";

const Hero = ({ career }) => {
  const glitchAnimInterval = useRef();
  const glitchColorsInterval = useRef();
  const miniGlitchInterval = useRef();

  const [isGlitching, setGlitching] = useState(true);

  useEffect(() => {
    if (!glitchColorsInterval.current) {
      const enableGlitchAnim = function glitchAnim() {
        // Wait 3.5s before re-enabling the glitch animation
        glitchAnimInterval.current = setTimeout(() => {
          setGlitching(true);
          clearTimeout(glitchAnimInterval.current);
        }, 3500);
      };

      const glitchColors = function glitchFunc() {
        // Set a small delay before toggling off the glitch animation
        miniGlitchInterval.current = setTimeout(() => {
          setGlitching(false);
          clearTimeout(miniGlitchInterval.current);
        }, 500);

        // Run timeout to re-enable the glitch animation
        enableGlitchAnim();

        // Recursively call glitch animation
        glitchColorsInterval.current = setTimeout(glitchColors, 6000);
      };

      // Initial run of glitch animation timeout
      glitchColorsInterval.current = setTimeout(glitchColors, 6000);
    }

    return function cleanup() {
      clearTimeout(glitchAnimInterval.current);
      clearTimeout(glitchColorsInterval.current);
      clearTimeout(miniGlitchInterval.current);
    };
  }, []);
  
  return (
    <ScreenClassRender
      render={screen => (
        <BrandContainer screen={screen}>
          <Header />
          <ContentContainer>
            <Left screen={screen}>
              <FadeIn delay={0.3}>
                <BrandName screen={screen}>
                  <h1>
                    Build Your <strong>Future</strong>
                  </h1>
                </BrandName>
              </FadeIn>
              <FadeIn delay={0.5}>
                <Description screen={screen}>
                  Learn modern, in-demand skills and get connected to great jobs
                  through our intensive courses taught by experts.
                </Description>
              </FadeIn>
              <CTA screen={screen}>
                <FadeIn delay={0.5}>
                  <Button
                    title="Choose a Course"
                    isExternal
                    to="#courses"
                    customstyles={css`
                      background: linear-gradient(
                        19deg,
                        rgb(33, 212, 253) 0%,
                        rgb(183, 33, 255) 100%
                      );
                      padding: 0.5rem 1.4rem;
                    `}
                  />
                </FadeIn>
                <FadeIn delay={0.7}>
                  <Button
                    title="Practice"
                    isExternal
                    to="/catalog"
                    customstyles={css`
                      width: fit-content;
                      background: none;
                      color: #3baaf0;
                      box-shadow: none !important;
                      margin: 0.5rem;

                      &:hover {
                        opacity: 0.7;
                      }
                    `}
                  />
                </FadeIn>
              </CTA>
            </Left>
            <LogoContainer screen={screen}>
              <Logo
                career={career}
                size="image"
                className={isGlitching ? "logo glitching" : "logo"}
              >
                <Logo size="image" className="glitch" />
                <Logo size="image" className="glitch" />
                <Logo size="image" className="glitch" />
                <Logo size="image" className="glitch" />
                <Logo size="image" className="glitch" />
              </Logo>
            </LogoContainer>
          </ContentContainer>
          {/* <FadeIn delay={0.9}>
            <ChoiceSection>
              <ChoiceSelect career={career} />
            </ChoiceSection>
          </FadeIn> */}
        </BrandContainer>
      )}
    />
  );
};

Hero.propTypes = {
  career: PropTypes.string
};

Hero.defaultProps = {
  career: ""
};

export default Hero;
