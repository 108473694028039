/**
 * Creates a new course and publish it
 * for public viewing.
 */

import React from "react";
import PropTypes from "prop-types";

import { Mutation, withApollo } from "react-apollo";
import { CREATE_COURSE } from "api/mutations/course";

import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { notify } from "react-notify-toast";
import { getGraphqlError, getUploadError } from "utils/general/errors";
import { flatSyllabus } from "utils/course/syllabus";

import { COURSE_TYPES } from "constants/course";

import { uploadMedia } from "api/upload";

import { GET_UPLOAD_TOKEN } from "api/queries/tokens";

import Button from "../../../Button";

class CreateCourse extends React.PureComponent {
  state = {
    isUploading: false
  };

  toggleUploading = () => {
    const { isUploading } = this.state;

    this.setState({
      isUploading: !isUploading
    });
  };

  onError = error => {
    notify.show(getGraphqlError(error), "error");
  };

  onCompleted = () => {
    const { reset } = this.props;

    notify.show(
      "Successfully created and published your new course.",
      "success"
    );

    reset();
  };

  onSave = ({ createCourse } = {}) => async () => {
    const { userId, image, syllabus, formValues, client } = this.props;

    const {
      title,
      short,
      about,
      price,
      tags,
      duration,
      /* eslint-disable camelcase */
      hours_per_session,
      max_attendees,
      iterations,
      type,
      difficulty,
      language
    } = formValues;

    let imageURL = image;

    if (image && typeof image === "object") {
      this.toggleUploading();

      try {
        const { data: uploadData } = await client.query({
          query: GET_UPLOAD_TOKEN
        });

        const [uploadError, newImage] = await uploadMedia({
          media: image,
          token: uploadData.uploadToken
        });

        if (uploadError) {
          throw new Error(uploadError);
        }

        imageURL = newImage;

        this.toggleUploading();
      } catch (err) {
        notify.show(getUploadError(err), "error");

        this.toggleUploading();
        return;
      }
    }

    let variables = {
      title,
      short,
      about,
      tags,
      difficulty,
      language,
      type,
      image: imageURL,
      author: userId,
      layouts: flatSyllabus({ syllabus })
    };

    if (type === COURSE_TYPES.SELF_STUDY) {
      variables = {
        ...variables,
        duration
      };
    } else if (type === COURSE_TYPES.GUIDED_LEARNING) {
      variables = {
        ...variables,
        price,
        hours_per_session,
        max_attendees,
        iterations
      };
    }

    createCourse({
      variables
    });
  };

  render() {
    const { isUploading } = this.state;
    const { isValidForm } = this.props;

    return (
      <Mutation
        mutation={CREATE_COURSE}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {(createCourse, { loading }) => (
          <Button
            size="large"
            title="Create &amp; Publish Course"
            loadingText="Creating Course..."
            loading={loading || isUploading}
            disabled={loading || isUploading || !isValidForm}
            onClick={this.onSave({ createCourse })}
          />
        )}
      </Mutation>
    );
  }
}

CreateCourse.propTypes = {
  image: PropTypes.string,
  isValidForm: PropTypes.bool,
  iterations: PropTypes.instanceOf(Array),
  syllabus: PropTypes.instanceOf(Array),
  reset: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  formValues: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired
};

CreateCourse.defaultProps = {
  image: null,
  isValidForm: false,
  iterations: [],
  syllabus: []
};

export default connect(state => ({
  userId: state.user.id,
  formValues: formValueSelector("courseDetails")(
    state,
    ...[
      "title",
      "about",
      "price",
      "tags",
      "duration",
      "hours_per_session",
      "max_attendees",
      "difficulty",
      "language",
      "syllabus",
      "iterations",
      "type"
    ]
  )
}))(withApollo(CreateCourse));
