import React from "react";
import styled from "styled-components";
import { Link as UnstyledLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Colors from "theme/colors";
import links from "./linkList";

import { Popover } from "../../Popover";

export default () => (
  <Popover
    style={{
      zIndex: 5,
      marginTop: "15px",
      width: "95%"
    }}
    preferPlace="below"
    body={
      /* eslint-disable-next-line */
      <PopoverContent>
        {links.map(link => (
          <Link to={link.to} key={`${link.to}-link`}>
            {link.title}
          </Link>
        ))}
      </PopoverContent>
    }
  >
    <Button>
      <FontAwesomeIcon icon="bars" />
    </Button>
  </Popover>
);

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  background-color: ${Colors.background};
`;

const Link = styled(UnstyledLink)`
  margin: 10px 0;
  display: block;
  width: 100%;
  color: ${Colors.onBackground};
  text-align: center;
  text-decoration: none;
`;

const Button = styled.button`
  border: 0;
  padding: 10px 7px;
  background: none;
  cursor: pointer;
  outline: none;
`;
