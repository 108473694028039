import styled from "styled-components";

import { Container, Row, Col } from "react-grid-system";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Colors from "theme/colors";

export const HeaderContainer = styled.header`
  width: 100%;
  top: 0;
  left: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in 0.1s;

  & > div {
    margin: unset !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    max-width: unset !important;
  }

  ${props => props.customStyle}
`;

export const MenuExpandButton = styled.button`
  border: none;
  outline: none;
  background: none;
  color: inherit;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const MobileContainer = styled.div`
  display: ${props =>
    ["xs", "sm", "md"].includes(props.screen) ? "flex" : "none"};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 1rem 0;
  width: 100%;

  & > * {
    display: flex;
    flex-grow: 0;
    flex-basis: 30%;
    justify-content: center;
  }

  a > div {
    width: unset;
  }

  a {
    color: inherit;
  }
`;

export const MenuContainer = styled(Container)`
  display: ${props =>
    ["xs", "sm", "md"].includes(props.screen) ? "none" : "block"};
  overflow: hidden;
  padding: 1.5rem 0
  width: 90%;

  ${props =>
    ["xs", "sm"].includes(props.screen) &&
    `
    z-index: 5;
    position: absolute !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0;
    background: ${Colors.background};
  `}
`;

export const BrandRow = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: space-between !important;
`;

export const HeaderRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: space-between;
  }
`;

export const LinksContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: unset !important;
  letter-spacing: 0.1rem;
  margin-bottom: ${props =>
    ["sm", "xs"].includes(props.screen) ? "10px" : "0"};

  @media screen and (max-width: 500px) {
    flex-direction: column;
    min-height: unset !important;
  }

  a {
    text-transform: capitalize;
    text-decoration: none;
    color: inherit;
    margin: 10px 20px;
    font-weight: 500;
    opacity: 1;
    transition: opacity 275ms ease-out;
    font-size: 1rem;

    &:hover {
      opacity: 0.75;
      transition: opacity 275ms ease-in;
    }
  }
`;

export const Link = styled.a`
  &:hover {
    text-decoration: underline ${props => props.color};
  }
`;

export const StyledNavLink = styled(NavLink)`
  &:hover {
    text-decoration: underline ${props => props.color};
  }
`;

export const LoginLink = styled(NavLink).attrs({
  className: "login"
})`
  text-decoration: none;
  font-weight: bold;
  opacity: 1;
  font-size: 1rem;
  text-transform: capitalize;

  &:hover {
    opacity: 0.75;
    transition: opacity 275ms ease-in;
  }

  svg {
    margin-right: 10px;
  }
`;

export const LogoWrapper = styled(NavLink).attrs({
  to: "/"
})`
  transition: transform 200ms ease-out;
  width: fit-content;
  &:hover {
    transform: scale(1.2);
  }

  & > * {
    width: fit-content;
  }
`;

export const QuickLinksContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  svg {
    padding: 10px;
    margin-right: -10px;
    cursor: pointer;
  }
`;

export const AngleDown = styled(FontAwesomeIcon).attrs({
  icon: "angle-down"
})`
  box-sizing: initial;
`;
