import React from "react";
import PropTypes from "prop-types";
import FontSizes from "theme/fontsizes";

import { Field } from "redux-form";

import {
  COURSE_TYPES,
  COURSE_TYPE_LABEL,
  DIFFICULTY_LEVELS,
  LANGUAGE_OPTIONS,
  COURSE_TAGS
} from "constants/course";

import { Col } from "react-grid-system";
import { Container, Value } from "./styledElements";

import { EditableText, EditableDropdown } from "../../../EditableInputs";
import DetailLabel from "../DetailLabel";

const AdditionalDetails = ({ type, onEdit, isNew, editMode }) => {
  const isSelfPaced = type === COURSE_TYPES.SELF_STUDY;

  return (
    <Container>
      <Col xl={2.4} md={4} sm={6} xs={6}>
        <DetailLabel>TYPE</DetailLabel>
        <Value>
          <Field
            name="type"
            component={EditableText}
            centerItems
            type="span"
            format={value =>
              value ? COURSE_TYPE_LABEL[value] : COURSE_TYPE_LABEL["self-study"]
            }
            textStyle={{
              textTransform: "capitalize",
              fontSize: FontSizes.smallBase,
              color: "rgb(51, 51, 51)",
              fontWeight: "600",
              minHeight: 15
            }}
            fallbackDisplayValue={COURSE_TYPES.SELF_STUDY}
          />
        </Value>
      </Col>
      {!isSelfPaced && (
        <>
          <Col xl={2.4} md={4} sm={6} xs={6}>
            <DetailLabel>PRICE</DetailLabel>
            <Value>
              <Field
                name="price"
                component={EditableText}
                centerItems
                onEdit={({ type } = {}) => {
                  onEdit({ type, field: "price" });
                }}
                showEditAlways={isNew}
                enableInlineEdit={isNew}
                editing={editMode}
                allowEdit={editMode}
                type="span"
                inputType="price"
                textStyle={{
                  fontSize: FontSizes.smallBase,
                  color: "rgb(51, 51, 51)",
                  fontWeight: "600",
                  minHeight: 15
                }}
                fallbackDisplayValue="Free Course"
              />
            </Value>
          </Col>
          <Col xl={2.4} md={4} sm={6} xs={6}>
            <DetailLabel>MAX ATTENDEES</DetailLabel>
            <Value>
              <Field
                name="max_attendees"
                component={EditableText}
                centerItems
                onEdit={({ type } = {}) => {
                  onEdit({ type, field: "max_attendees" });
                }}
                showEditAlways={isNew}
                enableInlineEdit={isNew}
                editing={editMode}
                allowEdit={editMode}
                type="span"
                inputType="number"
                textStyle={{
                  fontSize: FontSizes.smallBase,
                  color: "rgb(51, 51, 51)",
                  fontWeight: "600",
                  minHeight: 15
                }}
                fallbackDisplayValue="10"
              />
            </Value>
          </Col>
        </>
      )}
      <Col xl={2.4} md={4} sm={6} xs={6}>
        <DetailLabel>
          {isSelfPaced ? "DURATION" : "HOURS PER SESSION"}
        </DetailLabel>
        <Value>
          <Field
            name={isSelfPaced ? "duration" : "hours_per_session"}
            component={EditableText}
            centerItems
            onEdit={({ type } = {}) => {
              onEdit({
                type,
                field: isSelfPaced ? "duration" : "hours_per_session"
              });
            }}
            showEditAlways={isNew}
            enableInlineEdit={isNew}
            editing={editMode}
            allowEdit={editMode}
            type="span"
            inputType={!isSelfPaced && "number"}
            textStyle={{
              fontSize: FontSizes.smallBase,
              color: "rgb(51, 51, 51)",
              fontWeight: "600",
              minHeight: 15
            }}
            fallbackDisplayValue="No Time Limit"
          />
        </Value>
      </Col>
      <Col xl={2.4} md={4} sm={6} xs={6}>
        <DetailLabel>DIFFICULTY</DetailLabel>
        <Value>
          <Field
            name="difficulty"
            component={EditableDropdown}
            centerItems
            onEdit={({ type } = {}) => {
              onEdit({ type, field: "difficulty" });
            }}
            showEditAlways={isNew}
            enableInlineEdit={isNew}
            editing={editMode}
            allowEdit={editMode}
            type="span"
            options={DIFFICULTY_LEVELS}
            textStyle={{
              fontSize: FontSizes.smallBase,
              color: "rgb(51, 51, 51)",
              fontWeight: "600",
              minHeight: 15
            }}
          />
        </Value>
      </Col>
      {isSelfPaced && (
        <Col xl={2.4} md={4} sm={6} xs={6}>
          <DetailLabel>LANGUAGE</DetailLabel>
          <Value>
            <Field
              name="language"
              component={EditableDropdown}
              centerItems
              onEdit={({ type } = {}) => {
                onEdit({ type, field: "language" });
              }}
              showEditAlways={isNew}
              enableInlineEdit={isNew}
              editing={editMode}
              allowEdit={editMode}
              type="span"
              options={LANGUAGE_OPTIONS}
              textStyle={{
                fontSize: FontSizes.smallBase,
                color: "rgb(51, 51, 51)",
                fontWeight: "600",
                minHeight: 15
              }}
            />
          </Value>
        </Col>
      )}
      {}
      <Col xl={2.4} md={4} sm={6} xs={6}>
        <DetailLabel>TAG</DetailLabel>
        <Value>
          <Field
            name="tags"
            component={EditableDropdown}
            centerItems
            onEdit={({ type } = {}) => {
              onEdit({ type, field: "tags" });
            }}
            showEditAlways={isNew}
            enableInlineEdit={isNew}
            editing={editMode}
            allowEdit={editMode}
            type="span"
            options={COURSE_TAGS}
            textStyle={{
              fontSize: FontSizes.smallBase,
              color: "rgb(51, 51, 51)",
              fontWeight: "600",
              minHeight: 15
            }}
          />
        </Value>
      </Col>
    </Container>
  );
};

AdditionalDetails.propTypes = {
  type: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  isNew: PropTypes.bool,
  editMode: PropTypes.bool
};

AdditionalDetails.defaultProps = {
  type: undefined,
  isNew: false,
  editMode: false
};

export default AdditionalDetails;
