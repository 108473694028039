import styled from "styled-components";
import Colors from "theme/colors";

export const PopoverContainer = styled.div`
  font-size: 0.8rem;
  background-color: ${Colors.background};
  min-width: 200px;
  box-shadow: 6px 6px 20px rgb(217, 210, 200),
    -6px -6px 20px rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.32);
`;

export const PopoverImage = styled.img.attrs({
  alt: "User Avatar"
})`
  border-radius: 4px;
  height: 60px;
  width: 60px;
  margin: 10px;
`;

export const PopoverUser = styled.div`
  margin: 10px;
  margin-left: 0;
  margin-bottom: 5px;
  font-weight: 700;
`;

export const PopoverUserShort = styled.div`
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.5);
`;

export const PopoverLogoutButton = styled.div`
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: bold;
  text-align: center;
  color: #fb4949;
  cursor: pointer;
`;

export const PopoverItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  cursor: pointer;
  font-size: 0.9rem;

  a {
    color: ${Colors.text};
    margin-bottom: 0.3rem;

    &:hover {
      color: ${Colors.primaryDark};
    }
  }
`;
