import styled from "styled-components";
import Colors from "theme/colors";
import FontSizes from "theme/fontsizes";

export const Wrapper = styled.div.attrs({
  className: "wrapper"
})`
  margin-bottom: 24px;
`;
export const Label = styled.label`
  font-size: ${FontSizes.smallBase};
  color: ${Colors.onBackgroundSecondary};
  text-align: left;
  font-weight: 700;
  display: block;
  margin-bottom: 6px;
`;

export const Error = styled.div`
  color: ${Colors.error};
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: left;
  margin-left: 15px;
`;
export const Warning = styled.div`
  color: yellow;
  font-weight: 600;
  margin-top: 6px;
  text-align: left;
  margin-left: 15px;
`;
