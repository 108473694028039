export default {
  primary: "#3BAAF0",
  primaryDark: "#0088bc",
  primaryDarker: "#00668d",
  primaryVariant: "#0D2C54",
  primaryLowOpacity: "rgba(0, 168, 235, 0.4)",
  primaryDarkerLowOpacity: "rgba(0, 102, 141, 0.8)",
  primaryVariantLowOpacity: "rgba(13, 44, 84, 0.8)",
  accent: "#ac8400",
  accent2: "#ac8400",
  accentLighter: "#cdb566",
  accentLight: "#bc9c32",
  accentDark: "#896900",
  accentDarker: "#674f00",
  accentLowOpactiy: "rgba(173, 133, 0, 0.8)",
  blue: "#21d4fd",
  teal: "#22bad2",
  green: "#89ee49",
  purple: "#9E60FF",
  pink: "#f00e83",
  background: "#FFFFFF",
  secondaryBackground: "#f6f6f6",
  error: "#D32F2F",
  onPrimary: "#FFFFFF",
  onPrimaryLowOpacity: "rgba(255, 255, 255, 0.9)",
  onBackground: "rgb(51, 51, 51)",
  onBackgroundLowOpacity: "#21212190",
  onBackgroundSecondary: "#757575",
  onError: "#FFFFFF",
  divider: "#bdbdbd",
  greyBackground: "#303036",
  black: "#212121",
  white: "#FFFFFF",
  text: "#212121",
  codeEditorHeaderSelected: "#1f1f23",
  codeEditorHeader: "#29292f",
  codeEditorBackground: "#091627",
  contentSectionBackground: "#ececec",
  facebookBlue: "#3b5998",
  meetupRed: "#e0393e",
  twitterBlue: "#0084b4",
  instagramRed: "#e95950",
  brandOrange: "#fe9156"
};

export const GRADIENTS = {
  blue: `background: #00dcfb;
  background: linear-gradient(45deg, #5f9eff, #00dcfb);
  `,
  purple: `background: rgb(141, 106, 255);
  background: linear-gradient(
    45deg,
    rgb(141, 106, 255) 0%,
    rgba(153, 163, 255, 1) 100%
  );`,
  orange: `
  background: rgb(255, 71, 136);
  background: linear-gradient(
    -45deg,
    rgba(255, 71, 136, 1) 0%,
    rgba(255, 154, 73, 1) 100%
  );`,
  green: `background: rgb(24, 181, 108);
  background: linear-gradient(
    45deg,
    rgba(24, 181, 108, 1) 0%,
    rgba(131, 229, 233, 1) 100% 
  );
  `,
  blueLowOpacity: `background: rgba(27, 243, 254, 0.8);
  background: linear-gradient(
    45deg,
    rgba(27, 243, 254, 0.8) 0%,
    rgba(51, 205, 246, 0.8) 75%
  );`,
  purpleLowOpacity: `background: rgba(225, 0, 248, 0.8);
  background: linear-gradient(
    45deg,
    rgba(130, 0, 251, 0.8) 0%,
    rgba(225, 0, 248, 0.8) 100%
  );`,
  orangeLowOpacity: `
  background: rgba(255, 71, 136, 0.8);
  background: linear-gradient(
    -45deg,
    rgba(255, 71, 136, 0.8) 0%,
    rgba(255, 154, 73, 0.8) 100%
  );`,
  data: `background: linear-gradient(19deg, #9E60FF 0%, #f00e83 100%);`,
  software: `background: linear-gradient( 19deg, rgb(33,212,253) 0%, rgb(183,33,255) 100% );`,
  devops: `background: linear-gradient( 19deg, #89ee49 0%, #22bad2 100% );`
};
