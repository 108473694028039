import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Row, Col } from "react-grid-system";
import { CardElement } from "@stripe/react-stripe-js";
import countryList from "react-select-country-list";

import COLORS from "theme/colors";
import Button from "../Button";
import { Input, Select } from "../FormElements";
import {
  Container,
  SectionSubheading,
  FieldGroup,
  CardInput,
  CardNumber,
  CouponGroup,
  Label,
  FilledField
} from "./styledElements";

const PaymentForm = ({ data, isPayment, handleCouponApply }) => {
  const card = data && data.userCardDetails && data.userCardDetails.card;
  const billing =
    data && data.userCardDetails && data.userCardDetails.billing_details;

  return (
    <Container>
      <form>
        <Row>
          <Col xl={12}>
            <SectionSubheading>Payment Information</SectionSubheading>
          </Col>
          <Col xl={12}>
            <Row>
              {!card && (
                <Col xl={6}>
                  <FieldGroup>
                    <Label>Card Number</Label>
                    <CardInput>
                      <CardElement
                        options={{
                          iconStyle: "solid",
                          style: {
                            base: {
                              fontFamily: '"Futura PT", sans-serif',
                              iconColor: COLORS.black,
                              color: COLORS.text,
                              "::placeholder": {
                                color: "#aab7c4"
                              }
                            }
                          }
                        }}
                      />
                    </CardInput>
                  </FieldGroup>
                </Col>
              )}
              {card && (
                <Col xl={6}>
                  <FieldGroup>
                    <Label>Card Number</Label>
                    <CardNumber>
                      {/* eslint-disable-next-line */}
                      &bull;&bull;&bull;&bull; {card.last4}
                    </CardNumber>
                  </FieldGroup>
                </Col>
              )}
              {isPayment && (
                <Col xl={6}>
                  <FieldGroup>
                    <Label>Promo</Label>
                    <CouponGroup>
                      <Field
                        name="promo"
                        component={Input}
                        placeholder="Enter an optional promo code"
                      />
                      <Button title="Apply" onClick={handleCouponApply} />
                    </CouponGroup>
                  </FieldGroup>
                </Col>
              )}
            </Row>
          </Col>
          <Col xl={12}>
            <SectionSubheading>Billing Information</SectionSubheading>
          </Col>
          <Col xl={6}>
            <FieldGroup>
              <Label>Full Name</Label>
              {billing ? (
                <FilledField>{billing.name}</FilledField>
              ) : (
                <Field name="name" placeholder="Full name" component={Input} />
              )}
            </FieldGroup>
          </Col>
          <Col xl={6}>
            <FieldGroup>
              <Label>Address Line 1</Label>
              {billing ? (
                <FilledField>{billing.address.line1}</FilledField>
              ) : (
                <Field
                  name="address.line1"
                  placeholder="Address line 1"
                  component={Input}
                />
              )}
            </FieldGroup>
          </Col>
          <Col xl={6}>
            <FieldGroup>
              <Label>Address Line 2</Label>
              {billing ? (
                <FilledField>{billing.address.line2}</FilledField>
              ) : (
                <Field
                  name="address.line2"
                  placeholder="Address line 2"
                  component={Input}
                />
              )}
            </FieldGroup>
          </Col>
          <Col xl={6}>
            <FieldGroup>
              <Label>City</Label>
              {billing ? (
                <FilledField>{billing.address.city}</FilledField>
              ) : (
                <Field
                  name="address.city"
                  placeholder="City"
                  component={Input}
                />
              )}
            </FieldGroup>
          </Col>
          <Col xl={6}>
            <FieldGroup>
              <Label>State</Label>
              {billing ? (
                <FilledField>{billing.address.state}</FilledField>
              ) : (
                <Field
                  name="address.state"
                  placeholder="State, county, province, or region"
                  component={Input}
                />
              )}
            </FieldGroup>
          </Col>
          <Col xl={6}>
            <FieldGroup>
              <Label>Country</Label>
              {billing ? (
                <FilledField>{billing.address.country}</FilledField>
              ) : (
                <Field
                  name="address.country"
                  placeholder="Country"
                  component={Select}
                  options={countryList().getData()}
                  menuPlacement="auto"
                />
              )}
            </FieldGroup>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

PaymentForm.propTypes = {
  data: PropTypes.instanceOf(Object),
  isPayment: PropTypes.bool,
  handleCouponApply: PropTypes.func
};

PaymentForm.defaultProps = {
  data: {},
  isPayment: false,
  handleCouponApply: () => {}
};

export default reduxForm({
  form: "payment"
})(PaymentForm);
