import decode from "jwt-decode";

import { getIdToken } from "api/auth";

const decodedUser = getIdToken() && decode(getIdToken());

const initialState = {
  id: decodedUser ? decodedUser.sub : null,
  name: decodedUser ? decodedUser.name : null,
  email: decodedUser ? decodedUser.email : null,
  picture: decodedUser ? decodedUser.picture : null,
  role: decodedUser
    ? decodedUser["https://amagiacademy.com/claims"].role
    : null,
  certifications: null, // certifications completed
  payments: null, // history of payments
  courses: null // list of courses and student's progress
};

const emptyState = {
  id: null,
  name: null,
  email: null,
  picture: null,
  role: null,
  certifications: null, // certifications completed
  payments: null, // history of payments
  courses: null // list of courses and student's progress
};

export const setUserDetails = ({ user = {} } = {}) => ({
  type: "user.SET_USER_DETAILS",
  user
});

export const logoutUser = () => ({
  type: "user.LOGOUT_USER"
});

export default (state = initialState, action) => {
  switch (action.type) {
    case "user.SET_USER_DETAILS": {
      const { user } = action;

      return {
        ...state,
        ...user
      };
    }
    case "user.LOGOUT_USER":
      return emptyState;
    default:
      return state;
  }
};
