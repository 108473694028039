import React from "react";
import Colors from "theme/colors";
import { ScreenClassRender } from "react-grid-system";

import { css } from "styled-components";
import {
    Section,
    SectionHeadline,
    SectionBody,
    StepContainer,
    StepHeadline,
    StepBody
} from "./styledElements";

const Steps = () => (
    <ScreenClassRender
      render={screen => (
        <Section screen={screen} id="steps">
            <SectionHeadline>
                The best way to begin your <br /><span>career in tech</span></SectionHeadline>
            <SectionBody screen={screen}>
                <StepContainer screen={screen}>
                    <StepHeadline customStyles={css`
                            color: ${Colors.purple};
                        `}>Step 1</StepHeadline>
                    <StepBody>
                        Apply to your preferred course, complete an interview 
                        with one of our experienced instructors, and choose a 
                        payment option once you've been accepted.
                    </StepBody>
                </StepContainer>
                <StepContainer screen={screen}>
                    <StepHeadline customStyles={css`
                            color: ${Colors.green};
                        `}>Step 2</StepHeadline>
                    <StepBody>
                        Learn modern skills at a professional level, starting 
                        with the fundamentals. You'll be working on real world 
                        projects with instructors and students. 
                    </StepBody>
                </StepContainer>
                <StepContainer screen={screen}>
                    <StepHeadline customStyles={css`
                            color: ${Colors.pink};
                        `}>Step 3</StepHeadline>
                    <StepBody>
                        Get hired the right way by getting help with your resume, 
                        practicing for interviews, and getting 
                        connected to our partner companies around the world.
                    </StepBody>
                </StepContainer>
            </SectionBody>
        </Section>
      )}
      />
  );
  
  export default Steps;