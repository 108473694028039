import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  SectionContainer,
  // SectionName,
  // SectionHeadline,
  // SectionBody,
  ProgressBar,
  StepsContainer,
  // StepContainer,
  StepLink,
  StepName,
  StepTime,
  StepBody,
  Barline,
  Bar,
  WeekText
} from "./styledElements";

import stepsData from "./stepsData";

const Curriculum = ({ color, gradient }) => {
  const [currentStep, setStep] = useState(stepsData[0]);
  const [progress, setProgress] = useState(0);

  const { progressSteps } = stepsData;

  const weeks = progressSteps[progressSteps.length - 1].week;

  return (
    <SectionContainer id="curriculum">
      {/* <SectionName>Curriculum</SectionName> */}
      {/* <SectionHeadline>Learn in Stages</SectionHeadline> */}
      {/* <SectionBody> */}
      {/*   Amagi Academy uses traditional class instruction, individual and group */}
      {/*   projects, classwork and homework with mentor feedback, and 24/7 Q&A to */}
      {/*   prepare you for your new career. */}
      {/*   {progressSteps.map(step => { */}
      {/*     return ( */}
      {/*       <StepContainer color={color}> */}
      {/*         <StepName>{step.name}</StepName> */}
      {/*         <StepTime>{step.time}</StepTime> */}
      {/*         {step.component} */}
      {/*         <hr /> */}
      {/*       </StepContainer> */}
      {/*     ); */}
      {/*   })} */}
      {/* </SectionBody> */}
      <ProgressBar>
        <StepsContainer>
          {progressSteps.map((step, idx) => {
            return (
              <StepLink
                key={step.name}
                onClick={() => {
                  const fraction = step.week / weeks;
                  const percent = Math.min(Math.round(fraction * 100), 100);

                  setStep(step);
                  setProgress(percent);
                }}
              >
                <StepName>{step.name}</StepName>
                <StepTime>{step.time}</StepTime>
              </StepLink>
            );
          })}
        </StepsContainer>
        <Barline color={color}>
          <Bar gradient={gradient} progress={progress} />
        </Barline>
        <WeekText>
          {Array(weeks + 1)
            .fill(0)
            .map((curr, ind) => (
              <div>{ind}</div>
            ))}
        </WeekText>
      </ProgressBar>
      <StepBody>{currentStep && currentStep.component}</StepBody>
    </SectionContainer>
  );
};

Curriculum.propTypes = {
  color: PropTypes.string,
  gradient: PropTypes.instanceOf(Array)
};

Curriculum.defaultProps = {
  color: "#3BAAF0",
  gradient: ["rgb(33,212,253)", "rgb(183,33,255)"]
};

export default Curriculum;
