/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { md } from "theme/breakpoints";

import AppLink from "../AppLink";
import appLinks from "./linkList";
import socialLinks from "./socialList";

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: ${md}px) {
    flex-direction: row;
  }
`;

const Links = ({ color, withSocial }) => {
  let links = appLinks;

  if (withSocial) {
    links = [...appLinks, ...socialLinks];
  }

  return (
    <Container>
      {links.map(link => (
        <AppLink
          smooth
          isExternal={link.isExternal}
          to={link.to}
          key={`${link.to}-link`}
          color={color}
          customstyles={css`
            padding: 20px;
            margin-bottom: 0;
          `}
        >
          {link.title}
        </AppLink>
      ))}
    </Container>
  );
};

Links.propTypes = {
  color: PropTypes.string,
  withSocial: PropTypes.bool
};

Links.defaultProps = {
  color: null,
  withSocial: false
};

export default Links;
