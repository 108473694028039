import React from "react";
import PropTypes from "prop-types";

import { TextBounce, TextColumn, TextContainer } from "./styledElements";

const Text = ({ children, bounceLeft, bounceRight, noBackground }) => (
  <TextColumn>
    <TextBounce left={bounceLeft} right={bounceRight}>
      <TextContainer noBackground={noBackground}>{children}</TextContainer>
    </TextBounce>
  </TextColumn>
);

Text.propTypes = {
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  bounceLeft: PropTypes.bool,
  bounceRight: PropTypes.bool,
  noBackground: PropTypes.bool
};

Text.defaultProps = {
  bounceLeft: false,
  bounceRight: false,
  noBackground: false
};

export default Text;
